/* Screen Max 576px */
@media only screen and (max-width: 576px) {
	.logo {
		margin-top: 0;
	}

	.logo .text,
	.footer-logo .text {
		font-size: 15px;
	}
}

/* Screen Max 767px */
@media only screen and (max-width: 767px) {
	.header-right-wrap,
	.stick .header-right-wrap {
		margin-top: 18px;
		margin-right: 0;
	}
}
