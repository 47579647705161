/* Root */
:root {
	--BLACK: #000000;
	--WHITE: #ffffff;
	--CHARCOAL_GRAY: #333333;
	--DARK_GRAY: #555252;
	--LIGHT_GRAY: #f1f1f1;
	--ELECTRIC_PURPLE: #a749ff;
	--HOT_PINK: #fa6bff;
	--swiper-navigation-size: 30px !important;
}

/* Body Scroll */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: var(--LIGHT_GRAY);
}

::-webkit-scrollbar-thumb {
	background: var(--CHARCOAL_GRAY);
}

::-webkit-scrollbar-thumb:hover {
	background: var(--BLACK);
}

/* Utilities */
.text_light_white {
	color: rgba(255, 255, 255, 0.5) !important;
}

.light_white_border {
	border: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.text_left {
	text-align: left;
}

.text_center {
	text-align: center;
}

.text_right {
	text-align: right;
}

.text_justify {
	text-align: justify;
}

.text_uppercase {
	text-transform: uppercase;
}

.overflow_hidden {
	overflow: hidden;
}

.font_weight_normal {
	font-weight: normal;
}

.font_weight_medium {
	font-weight: medium;
}

.font_weight_bold {
	font-weight: bold;
}

.cursor_pointer {
	cursor: pointer;
}

.active_link {
	font-weight: bolder !important;
	color: var(--ELECTRIC_PURPLE) !important;
}

.display_block {
	display: block;
}

.no_resize {
	resize: none;
}

/* Select */
.select_field {
	background: transparent;
	border: 1px solid #c1c1c1;
	height: 40px;
	padding: 2px 14px;
	margin-bottom: 30px;
	color: var(--CHARCOAL_GRAY);
}

/* Default Modal */
.modal_header_title {
	color: var(--ELECTRIC_PURPLE);
	font-weight: bolder;
	margin-bottom: 0;
}

.modal_header_close {
	cursor: pointer;
	background: transparent;
	font-size: 20px;
	font-weight: bolder;
	color: var(--DARK_GRAY);
	transition: all 0.5s ease-in-out;
}

.modal_header_close:hover,
.modal_header_close:focus {
	color: var(--ELECTRIC_PURPLE);
}

.product_modal .modal-dialog {
	margin: 0 auto;
	padding: 0 auto;
}

.product_modal .modal-dialog .modal-content {
	border-radius: 15px;
	height: 493px !important;
	overflow-y: auto;
}

.product_modal .modal-dialog .modal-content::-webkit-scrollbar-track {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

.product_modal .modal-dialog .modal-content::-webkit-scrollbar-thumb {
	border-radius: 30px;
}

/* Header */
.logo {
	margin-top: 8px;
}

.stick .logo {
	margin-top: 0;
}

.logo img {
	width: 185px;
	height: 70px;
}

.sticky-bar.stick {
	padding: 10px 0;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 0 0 20px 20px;
}

.mobile_menu_icon {
	color: #333;
	cursor: pointer;
	height: 30px;
	padding: 2px 5px;
	text-decoration: none;
	width: 30px;
	border-radius: 30px;
	border: 2px solid #333;
	margin-left: 10px;
}

.mobile-menu-area {
	display: none;
}

.mobile_nav {
	float: left;
	width: 100%;
	background: transparent;
}

.mobile_nav ul.mobile_nav_overflow_menu {
	height: 215px;
	margin-top: 10;
	overflow-y: auto;
	width: 100%;
	list-style-type: none;
}

.mobile_nav_overflow_menu::-webkit-scrollbar {
	width: 3px;
}

.mobile_nav ul.mobile_nav_overflow_menu li {
	background: #f8f8f8 none repeat scroll 0 0;
	float: left;
	position: relative;
	width: 100%;
	border-top: 1px solid#ddd;
}

.mobile_nav ul.mobile_nav_overflow_menu li a {
	background: #f8f8f8 none repeat scroll 0 0;
	color: #333;
	display: block;
	float: left;
	font-size: 13px;
	margin: 0;
	padding: 10px 5%;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	width: 90%;
	font-weight: 500;
}

ul.mobile_nav_sub_menu {
	display: none;
}

ul.mobile_nav_sub_menu li a {
	border-top: 1px solid rgba(255, 255, 255, 0.25);
	opacity: 1;
	padding: 1em 10% !important;
	text-shadow: none !important;
	visibility: visible;
	width: 80%;
	font-weight: normal;
	text-transform: capitalize !important;
	color: #444 !important;
}

.mobile_menu_sub_icon {
	cursor: pointer;
	width: 26px;
	height: 15px;
	margin-top: 5px;
	color: var(--ELECTRIC_PURPLE);
	text-align: center;
	position: absolute;
	right: 10px;
	top: 6px;
	z-index: 2;
	font-weight: bold !important;
	background: rgba(255, 255, 255, 0.1);
	border: 0 !important;
	background: #f8f8f8;
}

/* Home Slider */
.swiper-button-prev,
.swiper-button-next {
	font-size: var(--swiper-navigation-size);
	font-weight: bolder;
}

.swiper-button-prev::after,
.swiper-button-next::after {
	color: #999;
}

.slider-content-13 {
	padding-left: 30px;
}

.home_slider_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	pointer-events: none;
}

/* Products Tab */
.nav-tabs .nav-item .nav-link {
	border: none;
	background-color: transparent;
}

.product_card {
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.product_card .product-img {
	border-bottom: 1px solid var(--LIGHT_GRAY);
}

.product_card .product-img img {
	height: 300px;
	border-radius: 10px 10px 0 0;
}

.product_card .product-content-2 {
	padding: 0 20px 20px 20px;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span {
	margin-left: 17px;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span::before {
	display: none;
}

.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span.old {
	position: relative;
	margin-left: 0;
}

.product-wrap-2
	.product-content-2
	.title-price-wrap-2
	.price-2
	span.old::after {
	position: absolute;
	content: "";
	right: -13px;
	top: 10px;
	width: 6px;
	height: 2px;
	background-color: #a5a5a5;
}

.view_more_button {
	font-size: 16px;
	color: var(--WHITE) !important;
	display: inline-block;
	border: none;
	box-shadow: none !important;
	border-radius: 30px;
	line-height: 1;
	padding: 15px 50px;
	background-color: var(--ELECTRIC_PURPLE);
	transition: all 0.3s ease-in-out;
}

.view_more_button:hover,
.view_more_button:focus {
	background-color: var(--CHARCOAL_GRAY) !important;
}

/* About Us */
.about_banner_image,
.about_banner_image img {
	/* width: 370px; */
	height: 207px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.home_banner_image,
.home_banner_image img {
	/* width: 570px; */
	height: 347px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Contact Us */
.contact-info-wrap {
	height: 695px;
}

/* Media */
.media_image img {
	height: 270px !important;
}

/* Footer */
.copyright .footer-logo {
	margin-top: 0;
}

.copyright .footer-logo img {
	width: 185px;
	height: 70px;
}

.footer_website_link {
	color: var(--ELECTRIC_PURPLE);
	font-weight: bolder;
}

.footer_website_link:hover {
	text-decoration: underline;
}
