/*-----------------------------------------------------------------------------------

    Template Name: Flone - Minimalist eCommerce Bootstrap 4 Template
    Version: 2.0.2

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Support style
    5. Section title style
    6. Product style
    7. Blog style
    8. Footer style
    9. Banner style
    10. Suscribe style
    11. About style
    12. instagram style
    13. Testimonial style
    14. Brand logo style
    15. Timer style
    16. Breadcrumb style
    17. Sidebar style
    18. Shop page
    19. Product details page
    20. Cart page
    21. Checkout page
    22. contact Page
    23. Blog Details Page
    24. login register Page
    25. My account Page
    26. 404 Page
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,400,500,600,700|Poppins:300,400,500,600,700,800,900|Abril+Fatface&display=swap");
/*-- Common Style --*/
*,
*::after,
*::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	background-color: #ffffff;
	line-height: 24px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	font-family: "Poppins", sans-serif;
	color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000000;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	margin-top: 0;
}

p {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #333;
	margin-bottom: 15px;
	font-family: "Poppins", sans-serif;
}

h1 {
	font-size: 36px;
	line-height: 42px;
}

h2 {
	font-size: 30px;
	line-height: 36px;
}

h3 {
	font-size: 24px;
	line-height: 30px;
}

h4 {
	font-size: 18px;
	line-height: 24px;
}

h5 {
	font-size: 14px;
	line-height: 18px;
}

h6 {
	font-size: 12px;
	line-height: 14px;
}

p:last-child {
	margin-bottom: 0;
}

a,
button {
	color: inherit;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	color: #000;
}

a,
button,
img,
input,
span {
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

*:focus {
	outline: none !important;
}

a:focus {
	color: inherit;
	outline: none;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

button,
input[type="submit"] {
	cursor: pointer;
}

ul {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
	overflow: hidden;
}

.hidden {
	display: none;
}

.clear {
	clear: both;
}

.section {
	float: left;
	width: 100%;
}

.capitalize {
	text-transform: capitalize;
}

.uppercase {
	text-transform: uppercase;
}

.bg-img {
	background-position: center center;
	background-size: cover;
}

.border-bottom-1 {
	border-bottom: 1px solid #d5d5d5;
}

a:hover {
	color: #a749ff;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 0;
	margin-left: 0;
}

.p-0 {
	padding-top: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
	margin-top: 0;
}

.mt-10 {
	margin-top: 10px;
}

.mt-12 {
	margin-top: 12px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-35 {
	margin-top: 35px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-45 {
	margin-top: 45px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-55 {
	margin-top: 55px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-70 {
	margin-top: 70px;
}

.mt-80 {
	margin-top: 80px;
}
@media only screen and (max-width: 767px) {
	.mt-80 {
		margin-top: 60px;
	}
}

.mt-90 {
	margin-top: 90px;
}

.mt-100 {
	margin-top: 100px;
}

.mt-110 {
	margin-top: 110px;
}

.mt-120 {
	margin-top: 120px;
}

.mt-130 {
	margin-top: 130px;
}

.mt-140 {
	margin-top: 140px;
}

.mt-150 {
	margin-top: 150px;
}

.mt-195 {
	margin-top: 195px;
}

.mt-200 {
	margin-top: 200px;
}

/*************************
      Margin right
*************************/
.mr-0 {
	margin-right: 0px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-12 {
	margin-right: 12px;
}

.mr-15 {
	margin-right: 15px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-30 {
	margin-right: 30px;
}

.mr-35 {
	margin-right: 35px;
}

.mr-40 {
	margin-right: 40px;
}

.mr-50 {
	margin-right: 50px;
}

.mr-60 {
	margin-right: 60px;
}

.mr-70 {
	margin-right: 70px;
}

.mr-80 {
	margin-right: 80px;
}

.mr-90 {
	margin-right: 90px;
}

.mr-100 {
	margin-right: 100px;
}

.mr-110 {
	margin-right: 110px;
}

.mr-120 {
	margin-right: 120px;
}

.mr-130 {
	margin-right: 130px;
}

.mr-140 {
	margin-right: 140px;
}

.mr-150 {
	margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
	margin-bottom: 0;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-12 {
	margin-bottom: 12px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-35 {
	margin-bottom: 35px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-45 {
	margin-bottom: 45px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-55 {
	margin-bottom: 55px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-65 {
	margin-bottom: 65px;
}

.mb-70 {
	margin-bottom: 70px;
}

.mb-75 {
	margin-bottom: 75px;
}

.mb-80 {
	margin-bottom: 80px;
}
@media only screen and (max-width: 767px) {
	.mb-80 {
		margin-bottom: 60px;
	}
}

.mb-90 {
	margin-bottom: 90px;
}

.mb-95 {
	margin-bottom: 95px;
}

.mb-100 {
	margin-bottom: 100px;
}
@media only screen and (max-width: 767px) {
	.mb-100 {
		margin-bottom: 50px;
	}
}

.mb-110 {
	margin-bottom: 110px;
}

.mb-120 {
	margin-bottom: 120px;
}

.mb-130 {
	margin-bottom: 130px;
}

.mb-140 {
	margin-bottom: 140px;
}

.mb-150 {
	margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
	margin-left: 0;
}

.ml-10 {
	margin-left: 10px;
}

.ml-12 {
	margin-left: 12px;
}

.ml-15 {
	margin-left: 15px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-30 {
	margin-left: 30px;
}

.ml-40 {
	margin-left: 40px;
}

.ml-50 {
	margin-left: 50px;
}

.ml-55 {
	margin-left: 55px;
}

.ml-60 {
	margin-left: 60px;
}

.ml-70 {
	margin-left: 70px;
}

.ml-75 {
	margin-left: 75px;
}

.ml-80 {
	margin-left: 80px;
}

.ml-90 {
	margin-left: 90px;
}

.ml-95 {
	margin-left: 95px;
}

.ml-100 {
	margin-left: 100px;
}

.ml-110 {
	margin-left: 110px;
}

.ml-120 {
	margin-left: 120px;
}

.ml-130 {
	margin-left: 130px;
}

.ml-140 {
	margin-left: 140px;
}

.ml-145 {
	margin-left: 145px;
}

.ml-150 {
	margin-left: 150px;
}

.ml-ntv5 {
	margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
	padding-top: 0;
}

.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-25 {
	padding-top: 25px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-35 {
	padding-top: 35px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-45 {
	padding-top: 45px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-55 {
	padding-top: 55px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-70 {
	padding-top: 70px;
}
@media only screen and (max-width: 767px) {
	.pt-70 {
		padding-top: 60px;
	}
}

.pt-75 {
	padding-top: 75px;
}

.pt-80 {
	padding-top: 80px;
}
@media only screen and (max-width: 767px) {
	.pt-80 {
		padding-top: 60px;
	}
}

.pt-95 {
	padding-top: 95px;
}
@media only screen and (max-width: 767px) {
	.pt-95 {
		padding-top: 55px;
	}
}

.pt-90 {
	padding-top: 90px;
}
@media only screen and (max-width: 767px) {
	.pt-90 {
		padding-top: 50px;
	}
}

.pt-100 {
	padding-top: 100px;
}
@media only screen and (max-width: 767px) {
	.pt-100 {
		padding-top: 60px;
	}
}

.pt-105 {
	padding-top: 105px;
}

.pt-110 {
	padding-top: 110px;
}
@media only screen and (max-width: 767px) {
	.pt-110 {
		padding-top: 40px;
	}
}

.pt-115 {
	padding-top: 115px;
}
@media only screen and (max-width: 767px) {
	.pt-115 {
		padding-top: 60px;
	}
}

.pt-120 {
	padding-top: 120px;
}
@media only screen and (max-width: 767px) {
	.pt-120 {
		padding-top: 50px;
	}
}

.pt-125 {
	padding-top: 125px;
}
@media only screen and (max-width: 767px) {
	.pt-125 {
		padding-top: 55px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pt-125 {
		padding-top: 75px;
	}
}

.pt-128 {
	padding-top: 128px;
}

.pt-130 {
	padding-top: 130px;
}
@media only screen and (max-width: 767px) {
	.pt-130 {
		padding-top: 60px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pt-130 {
		padding-top: 80px;
	}
}

.pt-135 {
	padding-top: 135px;
}

.pt-140 {
	padding-top: 140px;
}

.pt-150 {
	padding-top: 150px;
}

.pt-155 {
	padding-top: 155px;
}

.pt-170 {
	padding-top: 170px;
}

.pt-180 {
	padding-top: 180px;
}
@media only screen and (max-width: 767px) {
	.pt-180 {
		padding-top: 60px;
	}
}

.pt-205 {
	padding-top: 205px;
}

.pt-230 {
	padding-top: 230px;
}

.pt-270 {
	padding-top: 270px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pt-270 {
		padding-top: 170px;
	}
}
@media only screen and (max-width: 767px) {
	.pt-270 {
		padding-top: 100px;
	}
}

.pt-240 {
	padding-top: 240px;
}

.pt-365 {
	padding-top: 365px;
}

.pt-370 {
	padding-top: 370px;
}

.pt-380 {
	padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
	padding-right: 0;
}

.pr-10 {
	padding-right: 10px;
}

.pr-15 {
	padding-right: 15px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-30 {
	padding-right: 30px;
}

.pr-40 {
	padding-right: 40px;
}

.pr-50 {
	padding-right: 50px;
}

.pr-60 {
	padding-right: 60px;
}

.pr-70 {
	padding-right: 70px;
}

.pr-80 {
	padding-right: 80px;
}

.pr-90 {
	padding-right: 90px;
}

.pr-100 {
	padding-right: 100px;
}

.pr-110 {
	padding-right: 110px;
}

.pr-120 {
	padding-right: 120px;
}

.pr-130 {
	padding-right: 130px;
}

.pr-140 {
	padding-right: 140px;
}

.pr-155 {
	padding-right: 155px;
}

.pr-270 {
	padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
	padding-bottom: 0;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-25 {
	padding-bottom: 25px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-35 {
	padding-bottom: 35px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-55 {
	padding-bottom: 55px;
}
@media only screen and (max-width: 767px) {
	.pb-55 {
		padding-bottom: 15px;
	}
}

.pb-60 {
	padding-bottom: 60px;
}
@media only screen and (max-width: 767px) {
	.pb-60 {
		padding-bottom: 20px;
	}
}

.pb-65 {
	padding-bottom: 65px;
}

.pb-70 {
	padding-bottom: 70px;
}
@media only screen and (max-width: 767px) {
	.pb-70 {
		padding-bottom: 30px;
	}
}

.pb-80 {
	padding-bottom: 80px;
}
@media only screen and (max-width: 767px) {
	.pb-80 {
		padding-bottom: 40px;
	}
}

.pb-85 {
	padding-bottom: 85px;
}

.pb-90 {
	padding-bottom: 90px;
}
@media only screen and (max-width: 767px) {
	.pb-90 {
		padding-bottom: 50px;
	}
}

.pb-95 {
	padding-bottom: 95px;
}
@media only screen and (max-width: 767px) {
	.pb-95 {
		padding-bottom: 55px;
	}
}

.pb-100 {
	padding-bottom: 100px;
}
@media only screen and (max-width: 767px) {
	.pb-100 {
		padding-bottom: 30px;
	}
}

.pb-105 {
	padding-bottom: 105px;
}

.pb-110 {
	padding-bottom: 110px;
}

.pb-115 {
	padding-bottom: 115px;
}
@media only screen and (max-width: 767px) {
	.pb-115 {
		padding-bottom: 60px;
	}
}

.pb-120 {
	padding-bottom: 120px;
}
@media only screen and (max-width: 767px) {
	.pb-120 {
		padding-bottom: 50px;
	}
}

.pb-125 {
	padding-bottom: 125px;
}

.pb-130 {
	padding-bottom: 130px;
}
@media only screen and (max-width: 767px) {
	.pb-130 {
		padding-bottom: 60px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pb-130 {
		padding-bottom: 80px;
	}
}

.pb-140 {
	padding-bottom: 140px;
}
@media only screen and (max-width: 767px) {
	.pb-140 {
		padding-bottom: 60px;
	}
}

.pb-145 {
	padding-bottom: 145px;
}

.pb-150 {
	padding-bottom: 150px;
}

.pb-170 {
	padding-bottom: 170px;
}

.pb-180 {
	padding-bottom: 180px;
}

.pb-280 {
	padding-bottom: 280px;
}

.pb-210 {
	padding-bottom: 210px;
}

.pb-285 {
	padding-bottom: 285px;
}

.pb-270 {
	padding-bottom: 270px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.pb-270 {
		padding-bottom: 170px;
	}
}
@media only screen and (max-width: 767px) {
	.pb-270 {
		padding-bottom: 100px;
	}
}

.pb-290 {
	padding-bottom: 290px;
}

/*************************
        Padding left
*************************/
.pl-0 {
	padding-left: 0;
}

.pl-10 {
	padding-left: 10px;
}

.pl-15 {
	padding-left: 15px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-30 {
	padding-left: 30px;
}

.pl-40 {
	padding-left: 40px;
}

.pl-50 {
	padding-left: 50px;
}

.pl-60 {
	padding-left: 60px;
}

.pl-70 {
	padding-left: 70px;
}

.pl-80 {
	padding-left: 80px;
}

.pl-90 {
	padding-left: 90px;
}

.pl-100 {
	padding-left: 100px;
}

.pl-110 {
	padding-left: 110px;
}

.pl-120 {
	padding-left: 120px;
}

.pl-130 {
	padding-left: 130px;
}

.pl-140 {
	padding-left: 140px;
}

.pl-150 {
	padding-left: 150px;
}

.pl-155 {
	padding-left: 155px;
}

.pl-190 {
	padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
	padding: 0;
}

.ptb-10 {
	padding: 10px 0;
}

.ptb-20 {
	padding: 20px 0;
}

.ptb-30 {
	padding: 30px 0;
}

.ptb-35 {
	padding: 35px 0;
}

.ptb-32 {
	padding: 32px 0;
}

.ptb-40 {
	padding: 40px 0;
}

.ptb-50 {
	padding: 50px 0;
}

.ptb-60 {
	padding: 60px 0;
}

.ptb-70 {
	padding: 70px 0;
}

.ptb-80 {
	padding: 80px 0;
}

.ptb-90 {
	padding: 90px 0;
}

.ptb-100 {
	padding: 100px 0;
}

.ptb-110 {
	padding: 110px 0;
}

.ptb-120 {
	padding: 120px 0;
}

.ptb-130 {
	padding: 130px 0;
}

.ptb-140 {
	padding: 140px 0;
}

.ptb-150 {
	padding: 150px 0;
}

.ptb-160 {
	padding: 160px 0;
}

.ptb-170 {
	padding: 170px 0;
}

.ptb-177 {
	padding: 177px 0;
}

.ptb-180 {
	padding: 180px 0;
}

.ptb-190 {
	padding: 190px 0;
}

.ptb-200 {
	padding: 200px 0;
}

.ptb-210 {
	padding: 210px 0;
}

.ptb-220 {
	padding: 220px 0;
}

.ptb-290 {
	padding: 290px 0;
}

.ptb-310 {
	padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
	margin: 0;
}

.mtb-10 {
	margin: 10px 0;
}

.mtb-15 {
	margin: 15px 0;
}

.mtb-20 {
	margin: 20px 0;
}

.mtb-30 {
	margin: 30px 0;
}

.mtb-40 {
	margin: 40px 0;
}

.mtb-50 {
	margin: 50px 0;
}

.mtb-60 {
	margin: 60px 0;
}

.mtb-70 {
	margin: 70px 0;
}

.mtb-80 {
	margin: 80px 0;
}

.mtb-90 {
	margin: 90px 0;
}

.mtb-100 {
	margin: 100px 0;
}

.mtb-110 {
	margin: 110px 0;
}

.mtb-120 {
	margin: 120px 0;
}

.mtb-130 {
	margin: 130px 0;
}

.mtb-140 {
	margin: 140px 0;
}

.mtb-150 {
	margin: 150px 0;
}

.mtb-290 {
	margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
	background: #eceff8;
	border: 2px solid #eceff8;
	height: 45px;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-left: 10px;
	font-size: 14px;
	color: #333;
	width: 100%;
}

select {
	width: 100%;
	background: transparent;
	border: 0px solid #eceff8;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-size: 14px;
	color: #333;
}

option {
	background: #fff;
	border: 0px solid #626262;
	padding-left: 10px;
	font-size: 14px;
}

input:focus {
	background: transparent;
}

textarea {
	resize: vertical;
	background: #eceff8;
	border: 2px solid #eceff8;
	padding: 10px;
	width: 100%;
	font-size: 14px;
}

textarea:focus {
	background: transparent;
	border: 2px solid #4fc1f0;
	outline: none;
}

.input-group.divcenter.input-group .form-control {
	padding-left: 0px;
}

.custom-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.custom-row {
		margin-right: -15px;
		margin-left: -15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.custom-row {
		margin-right: -15px;
		margin-left: -15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.custom-row {
		margin-right: -15px;
		margin-left: -15px;
	}
}
@media only screen and (max-width: 767px) {
	.custom-row {
		margin-right: -15px;
		margin-left: -15px;
	}
}

.custom-row-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}
.custom-row-2 div[class^="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.custom-row-2 div[class^="col-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media only screen and (max-width: 767px) {
	.custom-row-2 div[class^="col-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.custom-row-2 {
		margin-right: -5px;
		margin-left: -5px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.custom-row-2 {
		margin-right: -5px;
		margin-left: -5px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.custom-row-2 {
		margin-right: -15px;
		margin-left: -15px;
	}
}
@media only screen and (max-width: 767px) {
	.custom-row-2 {
		margin-right: -15px;
		margin-left: -15px;
	}
}

.custom-row-3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -13.6px;
	margin-left: -13.6px;
}
.custom-row-3 div[class^="col-"] {
	padding-left: 13.6px;
	padding-right: 13.6px;
}
@media only screen and (max-width: 767px) {
	.custom-row-3 div[class^="col-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.custom-row-3 {
		margin-right: -13.6px;
		margin-left: -13.6px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.custom-row-3 {
		margin-right: -13.6px;
		margin-left: -13.6px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.custom-row-3 {
		margin-right: -13.6px;
		margin-left: -13.6px;
	}
}
@media only screen and (max-width: 767px) {
	.custom-row-3 {
		margin-right: -15px;
		margin-left: -15px;
	}
}

.custom-col-5 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%;
	padding-right: 15px;
	padding-left: 15px;
	position: relative;
	width: 100%;
	min-height: 1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.custom-col-5 {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.custom-col-5 {
		padding-right: 15px;
		padding-left: 15px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333%;
		flex: 0 0 33.333%;
		max-width: 33.333%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.custom-col-5 {
		padding-right: 15px;
		padding-left: 15px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}
@media only screen and (max-width: 767px) {
	.custom-col-5 {
		padding-right: 15px;
		padding-left: 15px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.custom-col-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}

.mobile-menu-area {
	display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.mobile-menu-area {
		display: block;
	}
}
@media only screen and (max-width: 767px) {
	.mobile-menu-area {
		display: block;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.mobile-menu-area {
		display: block;
	}
}
.mobile-menu-area.white-mobile-menu a.meanmenu-reveal {
	color: #fff;
	border: 1px solid #fff;
}
.mobile-menu-area.white-mobile-menu a.meanmenu-reveal span {
	background-color: #fff;
}

.stick .mobile-menu-area.white-mobile-menu a.meanmenu-reveal {
	color: #333;
	border: 1px solid #333;
}
.stick .mobile-menu-area.white-mobile-menu a.meanmenu-reveal span {
	background-color: #333;
}

.mean-container a.meanmenu-reveal {
	top: -30px;
}

#mobile-menu-active {
	display: none;
}

.mobile-menu .mean-nav ul.menu-overflow {
	height: 215px;
	margin-top: 10;
	overflow-y: auto;
}

.mobile-menu.mean-container .mean-nav ul {
	margin: 14px 0 0;
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
	width: 50px;
	height: 50px;
	background-color: #a749ff;
	color: #fff;
	right: 20px;
	bottom: 60px;
	text-align: center;
	overflow: hidden;
	border-radius: 50px;
	z-index: 9811 !important;
}
@media only screen and (max-width: 767px) {
	#scrollUp {
		width: 40px;
		height: 40px;
	}
}
#scrollUp i {
	display: block;
	line-height: 50px;
	font-size: 22px;
}
@media only screen and (max-width: 767px) {
	#scrollUp i {
		line-height: 40px;
		font-size: 16px;
	}
}
#scrollUp:hover i {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.height-100 {
	height: 100vh;
}

/*--
    - Background Color
------------------------------------------*/
.bg-purple {
	background-color: #f0e0ff;
}

.bg-purple-2 {
	background-color: #cea1f8;
}

.bg-gray {
	background-color: #f6f6f8;
}

.bg-gray-2 {
	background-color: #f6f6f6;
}

.bg-gray-3 {
	background-color: #f7f7f7;
}

.bg-gray-4 {
	background-color: #f1f1f1;
}

.bg-gray-5 {
	background-color: #f9f9f9;
}

.bg-gray-6 {
	background-color: #e9e6e9;
}

.bg-black {
	background-color: #000000;
}

.bg-glaucous {
	background-color: #daedff;
}

.bg-aqua {
	background-color: #dbfffb;
}

.bg-white {
	background-color: #fff;
}

.default-overlay {
	position: relative;
}
.default-overlay:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	content: "";
	right: 0;
	bottom: 0;
}

.btn-hover a {
	position: relative;
	-webkit-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
}
.btn-hover a:hover {
	border: 1px solid #a749ff;
	color: #fff;
}
.btn-hover a::before,
.btn-hover a::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	-o-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
	z-index: -1;
}
.btn-hover a::after {
	width: 0;
	left: auto;
	right: 0;
	background: #a749ff;
}
.btn-hover a:hover::after {
	width: 100%;
	left: 0;
	right: auto;
}

a.default-btn {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	border: 1px solid #333;
	text-transform: uppercase;
	line-height: 1;
	padding: 19px 50px 21px;
}

/* slider img bounce */
@-webkit-keyframes bounce-img {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
	50% {
		opacity: 1;
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@keyframes bounce-img {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
	50% {
		opacity: 1;
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@-webkit-keyframes snow {
	0% {
		background-position: 0 0, 0 0, 0 0;
	}
	100% {
		background-position: 500px 1000px, 400px 400px, 300px 300px;
	}
}

@keyframes snow {
	0% {
		background-position: 0 0, 0 0, 0 0;
	}
	100% {
		background-position: 500px 1000px, 400px 400px, 300px 300px;
	}
}

.effect-snow {
	background-image: url("../img/bg/snow1.png");
	-webkit-animation: snow 20s linear infinite;
	animation: snow 20s linear infinite;
}

.body-effect {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;
	background-color: rgba(0, 0, 0, 0);
}

.padding-10-row-col .row {
	margin-right: -5px;
	margin-left: -5px;
}
.padding-10-row-col .row div[class^="col-"] {
	padding-left: 5px;
	padding-right: 5px;
}

.padding-20-row-col .row {
	margin-right: -10px;
	margin-left: -10px;
}
.padding-20-row-col .row div[class^="col-"] {
	padding-left: 10px;
	padding-right: 10px;
}

/*----- 2. Header style ----*/
.header-area {
	position: relative;
}

.sticky-bar.stick {
	-webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
	animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
	background-color: #fff;
	border-bottom: 0 solid #4a90e2;
	-webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	left: 0;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.header-padding-1 .container-fluid {
	padding: 0 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.header-padding-1 .container-fluid {
		padding: 0 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header-padding-1 .container-fluid {
		padding: 0 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-padding-1 .container-fluid {
		padding: 0 40px;
	}
}
@media only screen and (max-width: 767px) {
	.header-padding-1 .container-fluid {
		padding: 0 15px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-res-padding {
		padding: 20px 0 15px;
	}
}

@media only screen and (max-width: 767px) {
	.header-res-padding {
		padding: 20px 0 15px;
	}
}

.logo {
	margin-top: 32px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.logo {
		margin-top: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.logo {
		margin-top: 0px;
	}
}
.logo.logo-hm5 {
	margin-top: 41px;
}
.logo.logo-hm5 a.sticky-block {
	display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.logo.logo-hm5 {
		margin-top: 0px;
		text-align: left !important;
	}
}
@media only screen and (max-width: 767px) {
	.logo.logo-hm5 {
		margin-top: 0px;
		text-align: left !important;
	}
}

.stick .logo-hm5 a.sticky-block {
	display: block;
}

.stick .logo-hm5 a.sticky-none {
	display: none;
}

.main-menu nav ul {
	display: block;
	text-align: center;
	margin-top: -5px;
}
.main-menu nav ul li {
	display: inline-block;
	padding: 0 15px;
	position: relative;
}
.main-menu nav ul li ul.submenu {
	background: #fff none repeat scroll 0 0;
	-webkit-box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
	display: block;
	left: 0;
	padding: 20px 0px 22px;
	position: absolute;
	top: 100%;
	-webkit-transform: rotateX(90deg);
	transform: rotateX(90deg);
	-webkit-transform-origin: center top 0;
	-ms-transform-origin: center top 0;
	transform-origin: center top 0;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	visibility: hidden;
	width: 190px;
	z-index: 999;
}
.main-menu nav ul li ul.submenu li {
	display: block;
	padding: 0 20px;
	margin-left: 0px;
}
.main-menu nav ul li ul.submenu li a {
	color: #333;
	display: block;
	font-size: 13px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;
	text-transform: capitalize;
	padding-top: 0px;
}
.main-menu nav ul li ul.submenu li a:hover {
	color: #a749ff;
}
.main-menu nav ul li ul.submenu li a::before {
	background: #a749ff none repeat scroll 0 0;
	border-radius: 50%;
	content: "";
	height: 7px;
	left: 15px;
	opacity: 0;
	position: absolute;
	top: 13px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	width: 7px;
	z-index: 999;
}
.main-menu nav ul li ul.submenu li:hover a::before {
	opacity: 1;
}
.main-menu nav ul li ul.submenu li:hover a {
	padding-left: 12px;
}
.main-menu nav ul li ul.mega-menu {
	background-color: #fff;
	display: block;
	left: 0;
	padding: 40px 40px 0px 40px;
	position: absolute;
	text-align: left;
	top: 100%;
	-webkit-transform: rotateX(90deg);
	transform: rotateX(90deg);
	-webkit-transform-origin: center top 0;
	-ms-transform-origin: center top 0;
	transform-origin: center top 0;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	visibility: hidden;
	width: 700px;
	z-index: 999;
	-webkit-box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-menu nav ul li ul.mega-menu {
		left: -75px;
	}
}
.main-menu nav ul li ul.mega-menu > li {
	display: inline-block;
	float: left;
	margin-left: 0;
	padding: 0;
	width: 33.33%;
}
.main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title {
	float: inherit;
	width: 100%;
}
.main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a {
	color: #242424;
	margin: 0 0 10px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}
.main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a::before {
	display: none;
}
.main-menu nav ul li ul.mega-menu > li ul li.mega-menu-title:hover a {
	padding-left: 0px;
}
.main-menu nav ul li ul.mega-menu > li ul li {
	display: block;
	float: inherit;
	padding: 0px;
}
.main-menu nav ul li ul.mega-menu > li ul li a {
	color: #333;
	display: block;
	font-size: 13px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;
	text-transform: capitalize;
	padding-top: 0px;
}
.main-menu nav ul li ul.mega-menu > li ul li a:hover {
	color: #a749ff;
}
.main-menu nav ul li ul.mega-menu > li ul li a::before {
	background: #a749ff none repeat scroll 0 0;
	border-radius: 50%;
	content: "";
	height: 7px;
	left: 0px;
	opacity: 0;
	position: absolute;
	top: 13px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	width: 7px;
	z-index: 999;
}
.main-menu nav ul li ul.mega-menu > li ul li.mega-menu-img:hover a {
	padding-left: 0px;
}
.main-menu nav ul li ul.mega-menu > li ul li.mega-menu-img a::before {
	display: none;
}
.main-menu nav ul li ul.mega-menu > li ul li:hover a::before {
	opacity: 1;
}
.main-menu nav ul li ul.mega-menu > li ul li:hover a {
	padding-left: 12px;
}
.main-menu nav ul li ul.mega-menu.mega-menu-padding {
	padding: 40px 40px 36px 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-menu nav ul li ul.mega-menu.mega-menu-padding {
		left: 0px;
	}
}
.main-menu nav ul li:hover > ul.submenu {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	visibility: visible;
}
.main-menu nav ul li:hover > ul.mega-menu {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	visibility: visible;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-menu nav ul li {
		padding: 0 7px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.main-menu nav ul li {
		padding: 0 10px;
	}
}
.main-menu nav ul li > a,
.main-menu nav ul li > span {
	cursor: pointer;
	font-size: 15px;
	color: #555252;
	font-weight: 500;
	letter-spacing: 0.8px;
	display: inline-block;
	line-height: 94px;
}
.main-menu nav ul li > a,
.main-menu nav ul li > span i {
	font-size: 15px;
	margin-left: 1px;
	position: relative;
	top: 0px;
}
.main-menu nav ul li:hover a,
.main-menu nav ul li:hover span {
	color: #a749ff;
}

.main-menu.menu-white nav ul {
	text-align: left;
}
.main-menu.menu-white nav ul li {
	padding: 0 30px 0 0;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.main-menu.menu-white nav ul li {
		padding: 0 22px 0 0;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.main-menu.menu-white nav ul li {
		padding: 0 14px 0 0;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-menu.menu-white nav ul li {
		padding: 0 14px 0 0;
	}
}
.main-menu.menu-white nav ul li > a {
	color: #ffffff;
	line-height: 112px;
}

.stick .main-menu.menu-white nav ul li a {
	color: #555252;
}
.stick .main-menu.menu-white nav ul li a:hover {
	color: #a749ff;
}

.stick .main-menu nav ul li a {
	line-height: 75px;
}

.stick .logo,
.stick .header-right-wrap {
	margin-top: 22px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.stick .logo,
	.stick .header-right-wrap {
		margin-top: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.stick .logo,
	.stick .header-right-wrap {
		margin-top: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.stick .logo,
	.stick .header-right-wrap {
		margin-top: 0px;
	}
}

.stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
.stick .header-right-wrap .same-style .account-dropdown {
	top: 160%;
}
@media only screen and (max-width: 767px) {
	.stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
	.stick .header-right-wrap .same-style .account-dropdown {
		top: 100%;
		margin-top: 14px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.stick .header-right-wrap .same-style.cart-wrap .shopping-cart-content,
	.stick .header-right-wrap .same-style .account-dropdown {
		top: 100%;
		margin-top: 14px;
	}
}

.header-right-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-top: 32px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-right-wrap {
		margin-top: 0px;
		margin-right: 50px;
	}
}
@media only screen and (max-width: 767px) {
	.header-right-wrap {
		margin-top: 2px;
		margin-right: 50px;
	}
}
.header-right-wrap .same-style {
	margin-left: 14px;
	position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header-right-wrap .same-style {
		margin-left: 10px;
	}
}
@media only screen and (max-width: 767px) {
	.header-right-wrap .same-style {
		margin-left: 5px;
	}
}
.header-right-wrap .same-style > a {
	color: #000;
	font-size: 23px;
}
.header-right-wrap .same-style:first-child {
	margin-left: 0px;
}
.header-right-wrap .same-style:hover > a {
	color: #a749ff;
}
.header-right-wrap .same-style .account-dropdown {
	background: #fff none repeat scroll 0 0;
	-webkit-box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
	left: 0;
	padding: 8px 12px 10px;
	position: absolute;
	text-align: left;
	top: 190%;
	width: 125px;
	z-index: 220;
	display: none;
}
@media only screen and (max-width: 767px) {
	.header-right-wrap .same-style .account-dropdown {
		top: 100%;
		margin-top: 14px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-right-wrap .same-style .account-dropdown {
		top: 100%;
		margin-top: 14px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header-right-wrap .same-style .account-dropdown {
		left: -40px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.header-right-wrap .same-style .account-dropdown {
		left: -34px;
	}
}
.header-right-wrap .same-style .account-dropdown ul li a {
	display: block;
	font-size: 13px;
	line-height: 30px;
	padding: 0;
	text-transform: capitalize;
	color: #000;
}
.header-right-wrap .same-style .account-dropdown ul li a:hover {
	padding-left: 5px;
	color: #a749ff;
}
.header-right-wrap .same-style.account-satting > a {
	font-size: 24px;
}
.header-right-wrap .same-style.cart-wrap {
	margin-left: 12px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header-right-wrap .same-style.cart-wrap {
		margin-left: 8px;
	}
}
@media only screen and (max-width: 767px) {
	.header-right-wrap .same-style.cart-wrap {
		margin-left: 3px;
	}
}
.header-right-wrap .same-style.cart-wrap button {
	background: transparent;
	border: none;
	padding: 0;
	color: #000;
	font-size: 24px;
	position: relative;
}
.header-right-wrap .same-style.cart-wrap button span.count-style {
	position: absolute;
	top: -9px;
	right: -14px;
	background-color: #000;
	color: #fff;
	display: inline-block;
	width: 21px;
	height: 21px;
	border-radius: 100%;
	line-height: 21px;
	font-size: 12px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.header-right-wrap .same-style.cart-wrap button span.count-style {
		right: -5px;
	}
}
.header-right-wrap .same-style.cart-wrap:hover > button {
	color: #a749ff;
}
.header-right-wrap .same-style.cart-wrap .shopping-cart-content {
	background: #fff none repeat scroll 0 0;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	opacity: 0;
	visibility: hidden;
	padding: 31px 35px 10px;
	position: absolute;
	right: 0;
	top: 190%;
	width: 340px;
	z-index: 9999;
	-webkit-transform: rotateX(90deg);
	transform: rotateX(90deg);
	-webkit-transform-origin: center top 0;
	-ms-transform-origin: center top 0;
	transform-origin: center top 0;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}
.header-right-wrap .same-style.cart-wrap .shopping-cart-content.cart-visible {
	opacity: 1;
	visibility: visible;
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
}
@media only screen and (max-width: 767px) {
	.header-right-wrap .same-style.cart-wrap .shopping-cart-content {
		width: 295px;
		right: -52px;
		padding: 31px 15px 10px;
		overflow-y: auto;
		height: 300px;
		top: 100%;
		margin-top: 14px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-right-wrap .same-style.cart-wrap .shopping-cart-content {
		overflow-y: auto;
		height: 300px;
		margin-top: 18px;
		top: 100%;
		margin-top: 14px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header-right-wrap .same-style.cart-wrap .shopping-cart-content {
		overflow-y: auto;
		height: 400px;
	}
}
.header-right-wrap .same-style.cart-wrap .shopping-cart-content ul li {
	border-bottom: 1px solid #ebebeb;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 20px;
	padding-bottom: 15px;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-img {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 82px;
	flex: 0 0 82px;
	margin-right: 15px;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-title
	h4 {
	font-size: 15px;
	line-height: 16px;
	margin: 0;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-title
	h4
	a {
	color: #000;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-title
	h4
	a:hover {
	color: #a749ff;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-title
	h6 {
	font-size: 13px;
	margin: 5px 0 8px;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-title
	span {
	font-size: 14px;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-delete {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 100;
	-ms-flex-positive: 100;
	flex-grow: 100;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-delete
	a {
	font-size: 16px;
	color: #a749ff;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	ul
	li
	.shopping-cart-delete
	a:hover {
	color: #333;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	.shopping-cart-total
	h4 {
	font-size: 14px;
	margin-bottom: 17px;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	.shopping-cart-total
	h4
	span {
	float: right;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	.shopping-cart-btn {
	margin-top: 23px;
}
.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content
	.shopping-cart-btn
	a {
	margin-bottom: 20px;
	padding: 16px 40px 17px;
	font-weight: 500;
	font-size: 14px;
	display: block;
}
.header-right-wrap.header-right-wrap-white {
	margin-top: 40px;
}
.header-right-wrap.header-right-wrap-white .same-style > a {
	color: #fff;
}
.header-right-wrap.header-right-wrap-white .same-style.cart-wrap > button {
	color: #fff;
}
.header-right-wrap.header-right-wrap-white
	.same-style.cart-wrap
	> button
	span.count-style {
	background-color: #fff;
	color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-right-wrap.header-right-wrap-white {
		margin-top: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.header-right-wrap.header-right-wrap-white {
		margin-top: 3px;
	}
}
.header-right-wrap .same-style.header-search .search-content {
	background: #ffffff none repeat scroll 0 0;
	-webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
	min-width: 300px;
	padding: 15px;
	position: absolute;
	right: 0;
	top: 193%;
	z-index: 99;
	display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-right-wrap .same-style.header-search .search-content {
		top: 154%;
	}
}
@media only screen and (max-width: 767px) {
	.header-right-wrap .same-style.header-search .search-content {
		right: -105px;
		top: 154%;
		min-width: 260px;
	}
}
.header-right-wrap .same-style.header-search .search-content form {
	position: relative;
}
.header-right-wrap .same-style.header-search .search-content form input {
	border: 1px solid #d3d3d3;
	outline: medium none;
	padding: 5px 72px 5px 15px;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 100%;
	color: #000;
	background: transparent;
}
.header-right-wrap
	.same-style.header-search
	.search-content
	form
	.button-search {
	background: #a749ff;
	border-color: #ce9634;
	color: #ffffff;
	position: absolute;
	right: 0;
	text-transform: uppercase;
	top: 0;
	border: none;
	height: 100%;
	padding: 5px 20px 2px;
	font-size: 20px;
	line-height: 1;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.header-right-wrap
	.same-style.header-search
	.search-content
	form
	.button-search:hover {
	background-color: #000;
}

.stick .same-style.header-search .search-content {
	top: 160%;
}

.stick .header-right-wrap.header-right-wrap-white {
	margin-top: 22px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.stick .header-right-wrap.header-right-wrap-white {
		margin-top: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.stick .header-right-wrap.header-right-wrap-white {
		margin-top: 0px;
	}
}
.stick .header-right-wrap.header-right-wrap-white .same-style > a {
	color: #000;
}
.stick .header-right-wrap.header-right-wrap-white .same-style > a:hover {
	color: #a749ff;
}
.stick
	.header-right-wrap.header-right-wrap-white
	.same-style.cart-wrap
	> button {
	color: #000;
}
.stick
	.header-right-wrap.header-right-wrap-white
	.same-style.cart-wrap
	> button:hover {
	color: #a749ff;
}
.stick
	.header-right-wrap.header-right-wrap-white
	.same-style.cart-wrap
	> button
	span.count-style {
	background-color: #000;
	color: #fff;
}

/* header 3 */
.header-padding-2 .container-fluid {
	padding: 0 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.header-padding-2 .container-fluid {
		padding: 0 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header-padding-2 .container-fluid {
		padding: 0 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-padding-2 .container-fluid {
		padding: 0 40px;
	}
}
@media only screen and (max-width: 767px) {
	.header-padding-2 .container-fluid {
		padding: 0 15px;
	}
}

.header-top-area {
	border-bottom: 1px solid #d5d4d4;
}
.header-top-area .header-top-wap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media only screen and (max-width: 767px) {
	.header-top-area .header-top-wap {
		display: block;
		padding-bottom: 15px;
		margin-top: 8px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.header-top-area .header-top-wap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0px;
		margin-top: 0px;
	}
}
.header-top-area.border-none {
	border: none;
}

.header-offer p {
	color: #555252;
	letter-spacing: 0.8px;
	margin: 0;
}
.header-offer p span {
	color: #ff1c1c;
}
@media only screen and (max-width: 767px) {
	.header-offer p {
		text-align: center;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.header-offer p {
		text-align: right;
	}
}

.language-currency-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media only screen and (max-width: 767px) {
	.language-currency-wrap {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.language-currency-wrap .same-language-currency {
	position: relative;
	margin-right: 54px;
}
@media only screen and (max-width: 767px) {
	.language-currency-wrap .same-language-currency {
		margin-right: 15px;
	}
}
.language-currency-wrap .same-language-currency:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 1px;
	background-color: #d5d4d4;
	right: -27px;
	top: 47%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
	.language-currency-wrap .same-language-currency:before {
		right: -10px;
	}
}
.language-currency-wrap .same-language-currency:last-child:before {
	display: none;
}
.language-currency-wrap .same-language-currency:last-child {
	margin-right: 0;
}
.language-currency-wrap .same-language-currency a {
	letter-spacing: 0.8px;
	color: #555252;
	line-height: 54px;
	display: inline-block;
	margin-top: -3px;
}
@media only screen and (max-width: 767px) {
	.language-currency-wrap .same-language-currency a {
		line-height: 35px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.language-currency-wrap .same-language-currency a {
		line-height: 54px;
	}
}
.language-currency-wrap .same-language-currency a i {
	font-size: 16px;
	color: #555252;
	position: relative;
	top: 2px;
}
.language-currency-wrap .same-language-currency .lang-car-dropdown {
	background: #fff none repeat scroll 0 0;
	-webkit-box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
	left: 0;
	padding: 8px 12px 10px;
	position: absolute;
	text-align: left;
	top: 100%;
	width: 125px;
	z-index: 220;
	display: none;
}
.language-currency-wrap .same-language-currency .lang-car-dropdown ul li a {
	line-height: 27px;
	font-size: 13px;
}
.language-currency-wrap
	.same-language-currency
	.lang-car-dropdown
	ul
	li
	a:hover {
	color: #a749ff;
}
.language-currency-wrap .same-language-currency p {
	letter-spacing: 0.8px;
	color: #555252;
	line-height: 1;
	margin-top: -1px;
}
.language-currency-wrap .same-language-currency:hover > a {
	color: #a749ff;
}

/* home 4 */
.home-sidebar-left {
	height: 100%;
	position: fixed;
	top: 0;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	width: 375px;
	z-index: 7;
	padding: 72px 0 40px 0px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.home-sidebar-left {
		width: 300px;
		padding: 50px 0 30px 0px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.home-sidebar-left {
		width: 300px;
		padding: 50px 0 30px 0px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.home-sidebar-left {
		width: 280px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.home-sidebar-left {
		width: 250px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.home-sidebar-left {
		display: none;
	}
}
@media only screen and (max-width: 767px) {
	.home-sidebar-left {
		display: none;
	}
}
.home-sidebar-left .logo {
	margin-top: 0;
	padding: 0px 0 0px 108px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.home-sidebar-left .logo {
		padding: 0px 0 0px 50px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.home-sidebar-left .logo {
		padding: 0px 0 0px 30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.home-sidebar-left .logo {
		padding: 0px 0 0px 15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.home-sidebar-left .logo {
		padding: 0px 0 0px 30px;
	}
}
.home-sidebar-left .header-right-wrap {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin-top: 46px;
	margin-bottom: 67px;
	padding: 0px 0 0px 108px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.home-sidebar-left .header-right-wrap {
		padding: 0px 0 0px 50px;
		margin-top: 26px;
		margin-bottom: 27px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.home-sidebar-left .header-right-wrap {
		padding: 0px 0 0px 30px;
		margin-top: 26px;
		margin-bottom: 27px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.home-sidebar-left .header-right-wrap {
		padding: 0px 0 0px 15px;
		margin-top: 30px;
		margin-bottom: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.home-sidebar-left .header-right-wrap {
		padding: 0px 0 0px 30px;
		margin-top: 30px;
		margin-bottom: 30px;
	}
}
.home-sidebar-left
	.header-right-wrap
	.same-style.cart-wrap
	.shopping-cart-content {
	right: auto;
	left: 0;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.home-sidebar-left
		.header-right-wrap
		.same-style.cart-wrap
		.shopping-cart-content {
		overflow-y: auto;
		height: 400px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.home-sidebar-left
		.header-right-wrap
		.same-style.cart-wrap
		.shopping-cart-content {
		overflow-y: auto;
		height: 300px;
	}
}
.home-sidebar-left
	.header-right-wrap
	.same-style.header-search
	.search-content {
	left: 0;
	right: auto;
}
.home-sidebar-left .sidebar-copyright {
	padding: 120px 63px 50px 63px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.home-sidebar-left .sidebar-copyright {
		padding: 40px 63px 50px 63px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.home-sidebar-left .sidebar-copyright {
		padding: 40px 63px 50px 63px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.home-sidebar-left .sidebar-copyright {
		padding: 40px 15px 50px 15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.home-sidebar-left .sidebar-copyright {
		padding: 50px 15px 50px 30px;
	}
}
.home-sidebar-left .sidebar-copyright p {
	letter-spacing: 0.9px;
	color: #313131;
	margin: 0;
	text-align: center;
}

.home-sidebar-right {
	border-left: 1px solid #efefef;
	margin-left: 375px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.home-sidebar-right {
		margin-left: 300px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.home-sidebar-right {
		margin-left: 280px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.home-sidebar-right {
		margin-left: 250px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.home-sidebar-right {
		margin-left: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.home-sidebar-right {
		margin-left: 0px;
	}
}

.sidebar-menu {
	padding: 0px 0 0px 108px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.sidebar-menu {
		padding: 0px 0 0px 50px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.sidebar-menu {
		padding: 0px 0 0px 30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-menu {
		padding: 0px 0 0px 15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.sidebar-menu {
		padding: 0px 0 0px 30px;
	}
}
.sidebar-menu nav ul li {
	padding-bottom: 33px;
	position: relative;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.sidebar-menu nav ul li {
		padding-bottom: 20px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.sidebar-menu nav ul li {
		padding-bottom: 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-menu nav ul li {
		padding-bottom: 10px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.sidebar-menu nav ul li {
		padding-bottom: 12px;
	}
}
.sidebar-menu nav ul li:last-child {
	padding-bottom: 0;
}
.sidebar-menu nav ul li ul.submenu {
	background: #fff none repeat scroll 0 0;
	-webkit-box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
	display: block;
	left: 100%;
	padding: 20px 0px 22px;
	position: absolute;
	top: 0;
	-webkit-transform: rotateX(90deg);
	transform: rotateX(90deg);
	-webkit-transform-origin: center top 0;
	-ms-transform-origin: center top 0;
	transform-origin: center top 0;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	visibility: hidden;
	width: 190px;
	z-index: 999;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-menu nav ul li ul.submenu {
		overflow-y: auto;
		max-height: 250px;
	}
}
.sidebar-menu nav ul li ul.submenu li {
	display: block;
	padding: 0 20px;
	margin-left: 0px;
}
.sidebar-menu nav ul li ul.submenu li a {
	color: #333;
	display: block;
	font-size: 13px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;
	text-transform: capitalize;
	padding-top: 0px;
}
.sidebar-menu nav ul li ul.submenu li a:hover {
	color: #a749ff;
}
.sidebar-menu nav ul li ul.submenu li a::before {
	background: #a749ff none repeat scroll 0 0;
	border-radius: 50%;
	content: "";
	height: 7px;
	left: 15px;
	opacity: 0;
	position: absolute;
	top: 13px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	width: 7px;
	z-index: 999;
}
.sidebar-menu nav ul li ul.submenu li:hover a::before {
	opacity: 1;
}
.sidebar-menu nav ul li ul.submenu li:hover a {
	padding-left: 12px;
}
.sidebar-menu nav ul li:hover > ul.submenu {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	visibility: visible;
}
.sidebar-menu nav ul li ul.mega-menu {
	background-color: #fff;
	display: block;
	left: 100%;
	padding: 40px 40px 0px 40px;
	position: absolute;
	text-align: left;
	top: 0;
	-webkit-transform: rotateX(90deg);
	transform: rotateX(90deg);
	-webkit-transform-origin: center top 0;
	-ms-transform-origin: center top 0;
	transform-origin: center top 0;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	visibility: hidden;
	width: 700px;
	z-index: 999;
	-webkit-box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
	box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.sidebar-menu nav ul li ul.mega-menu {
		top: -50px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.sidebar-menu nav ul li ul.mega-menu {
		top: -50px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-menu nav ul li ul.mega-menu {
		top: -80px;
	}
}
.sidebar-menu nav ul li ul.mega-menu > li {
	display: inline-block;
	float: left;
	margin-left: 0;
	padding: 0;
	width: 33.33%;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title {
	float: inherit;
	width: 100%;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a {
	color: #242424;
	margin: 0 0 10px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title a::before {
	display: none;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-title:hover a {
	padding-left: 0px;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li {
	display: block;
	float: inherit;
	padding: 0px;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li a {
	color: #333;
	display: block;
	font-size: 13px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;
	text-transform: capitalize;
	padding-top: 0px;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li a:hover {
	color: #a749ff;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li a::before {
	background: #a749ff none repeat scroll 0 0;
	border-radius: 50%;
	content: "";
	height: 7px;
	left: 0px;
	opacity: 0;
	position: absolute;
	top: 13px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	width: 7px;
	z-index: 999;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-img:hover a {
	padding-left: 0px;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li.mega-menu-img a::before {
	display: none;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li:hover a::before {
	opacity: 1;
}
.sidebar-menu nav ul li ul.mega-menu > li ul li:hover a {
	padding-left: 12px;
}
.sidebar-menu nav ul li ul.mega-menu.mega-menu-padding {
	padding: 40px 40px 36px 40px;
}
.sidebar-menu nav ul li:hover > ul.mega-menu {
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	visibility: visible;
}
.sidebar-menu nav ul li a {
	color: #000000;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.8px;
	text-transform: capitalize;
	display: block;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.sidebar-menu nav ul li a {
		font-size: 16px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-menu nav ul li a {
		font-size: 16px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.sidebar-menu nav ul li a {
		font-size: 16px;
	}
}
.sidebar-menu nav ul li a span {
	float: right;
	padding-right: 20px;
}
.sidebar-menu nav ul li:hover a {
	color: #a749ff;
}

.header-hm4-none {
	display: none;
}
@media only screen and (max-width: 767px) {
	.header-hm4-none {
		display: block;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm4-none {
		display: block;
	}
}

/* home 5 */
.wrapper {
	margin: 20px 20px;
}
@media only screen and (max-width: 767px) {
	.wrapper {
		margin: 0px 0px;
	}
}

.transparent-bar {
	left: 0;
	right: 0;
	top: 0;
	position: absolute;
	width: 100%;
	z-index: 999;
}

.header-padding-3 .container-fluid {
	padding: 0 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.header-padding-3 .container-fluid {
		padding: 0 40px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.header-padding-3 .container-fluid {
		padding: 0 40px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-padding-3 .container-fluid {
		padding: 0 40px;
	}
}
@media only screen and (max-width: 767px) {
	.header-padding-3 .container-fluid {
		padding: 0 15px;
	}
}

/* home 7 */
.clickable-menu {
	margin-top: 35px;
}
.clickable-menu a {
	color: #fff;
	font-size: 30px;
	line-height: 1;
	display: inline-block;
}
.clickable-menu a:hover {
	color: #a749ff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.clickable-menu {
		margin-top: -3px;
	}
}
@media only screen and (max-width: 767px) {
	.clickable-menu {
		margin-top: -3px;
	}
}

.clickable-mainmenu {
	background: white none repeat scroll 0 0;
	color: #353535;
	height: 100vh;
	overflow-y: auto;
	padding: 130px 35px 40px;
	position: fixed;
	left: 0;
	top: 0;
	-webkit-transform: translateX(-110%);
	-ms-transform: translateX(-110%);
	transform: translateX(-110%);
	-webkit-transition: -webkit-transform 0.5s ease-in-out 0s;
	transition: -webkit-transform 0.5s ease-in-out 0s;
	-o-transition: transform 0.5s ease-in-out 0s;
	transition: transform 0.5s ease-in-out 0s;
	transition: transform 0.5s ease-in-out 0s,
		-webkit-transform 0.5s ease-in-out 0s;
	width: 300px;
	z-index: 9999;
}
.clickable-mainmenu.inside {
	-webkit-transform: translateX(0px);
	-ms-transform: translateX(0px);
	transform: translateX(0px);
	z-index: 9999;
}
@media only screen and (max-width: 767px) {
	.clickable-mainmenu {
		padding: 100px 35px 40px;
	}
}
.clickable-mainmenu .clickable-mainmenu-icon button {
	background: transparent none repeat scroll 0 0;
	border: medium none;
	color: #000;
	cursor: pointer;
	font-size: 50px;
	padding: 0;
	position: absolute;
	left: 20px;
	top: 20px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.clickable-mainmenu .clickable-mainmenu-icon button:hover {
	color: #a749ff;
}
.clickable-mainmenu .side-logo {
	margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
	.clickable-mainmenu .side-logo {
		margin-bottom: 40px;
	}
}
.clickable-mainmenu .clickable-menu-style {
	background: transparent;
}
.clickable-mainmenu .clickable-menu-style ul li a {
	color: #000;
	font-size: 16px;
	text-transform: capitalize;
	padding: 0 0 20px;
}
.clickable-mainmenu .clickable-menu-style ul li a:hover {
	background: transparent;
	color: #a749ff;
}
.clickable-mainmenu .side-social {
	margin-top: 50px;
}
.clickable-mainmenu .side-social ul li {
	display: inline-block;
	margin: 0 30px 0 0;
	position: relative;
}
.clickable-mainmenu .side-social ul li:last-child {
	margin-right: 0;
}
.clickable-mainmenu .side-social ul li:before {
	position: absolute;
	right: -20px;
	top: 50%;
	background: #999;
	height: 1px;
	width: 10px;
	content: "";
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.clickable-mainmenu .side-social ul li:last-child:before {
	display: none;
}
.clickable-mainmenu .side-social ul li a {
	font-size: 18px;
}
.clickable-mainmenu .side-social ul li a.facebook {
	color: #3b5999;
}
.clickable-mainmenu .side-social ul li a.facebook:hover {
	color: #333;
}
.clickable-mainmenu .side-social ul li a.dribbble {
	color: #ea4c89;
}
.clickable-mainmenu .side-social ul li a.dribbble:hover {
	color: #333;
}
.clickable-mainmenu .side-social ul li a.pinterest {
	color: #bd081c;
}
.clickable-mainmenu .side-social ul li a.pinterest:hover {
	color: #333;
}
.clickable-mainmenu .side-social ul li a.twitter {
	color: #55acee;
}
.clickable-mainmenu .side-social ul li a.twitter:hover {
	color: #333;
}
.clickable-mainmenu .side-social ul li a.linkedin {
	color: #0077b5;
}
.clickable-mainmenu .side-social ul li a.linkedin:hover {
	color: #333;
}

.header-hm-7.stick .clickable-menu {
	margin-top: 19px;
}
.header-hm-7.stick .clickable-menu a {
	color: #282828;
}
.header-hm-7.stick .clickable-menu a:hover {
	color: #a749ff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm-7.stick .clickable-menu {
		margin-top: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.header-hm-7.stick .clickable-menu {
		margin-top: 0px;
	}
}

.header-hm-7.stick .logo {
	margin-top: 22px;
	margin-bottom: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm-7.stick .logo {
		margin-top: 2px;
		margin-bottom: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.header-hm-7.stick .logo {
		margin-top: 2px;
		margin-bottom: 0px;
	}
}

.header-hm-7.stick .header-right-wrap {
	margin-top: 22px;
	margin-bottom: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm-7.stick .header-right-wrap {
		margin-top: 2px;
		margin-bottom: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.header-hm-7.stick .header-right-wrap {
		margin-top: 2px;
		margin-bottom: 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm-7 .header-right-wrap {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.header-hm-7 .header-right-wrap {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.header-hm-7
		.header-right-wrap
		.same-style.cart-wrap
		.shopping-cart-content {
		right: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.header-hm-7 .header-right-wrap .same-style.header-search .search-content {
		right: -80px;
	}
}

/* home 8 */
.header-hm8 .header-right-wrap {
	margin-top: 6px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm8 .header-right-wrap {
		margin-right: 0;
	}
}
@media only screen and (max-width: 767px) {
	.header-hm8 .header-right-wrap {
		margin-right: 0;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.header-hm8 .header-right-wrap .same-style.cart-wrap .shopping-cart-content {
	top: 125%;
	margin-top: 0;
}
@media only screen and (max-width: 767px) {
	.header-hm8
		.header-right-wrap
		.same-style.cart-wrap
		.shopping-cart-content {
		right: -99px;
	}
}
.header-hm8 .header-right-wrap .same-style .account-dropdown {
	top: 125%;
	margin-top: 0;
	left: -32px;
}
.header-hm8 .header-right-wrap .same-style.header-search .search-content {
	top: 125%;
}
@media only screen and (max-width: 767px) {
	.header-hm8 .header-right-wrap .same-style.header-search .search-content {
		right: -150px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.header-hm8 .header-right-wrap .same-style.header-search .search-content {
		right: 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm8 .header-res-padding {
		padding: 20px 0 20px;
	}
	.header-hm8 .mean-container a.meanmenu-reveal {
		top: -26px;
	}
}

@media only screen and (max-width: 767px) {
	.header-hm8 .header-res-padding {
		padding: 20px 0 20px;
	}
	.header-hm8 .mean-container a.meanmenu-reveal {
		top: -26px;
	}
}

.header-hm8 .stick .logo {
	display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm8 .stick .logo {
		display: block;
	}
}
@media only screen and (max-width: 767px) {
	.header-hm8 .stick .logo {
		display: block;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm8 .logo {
		text-align: left !important;
	}
}

@media only screen and (max-width: 767px) {
	.header-hm8 .logo {
		text-align: left !important;
	}
}

.header-hm8 .main-menu {
	padding-bottom: 8px;
}
.header-hm8 .main-menu nav ul li > a {
	line-height: 60px;
}

/* home 9 */
.header-hm9 .main-menu nav ul li a {
	line-height: 72px;
}

.header-hm9 .header-top-area {
	padding: 22px 0;
	border-bottom: 1px solid #cecece;
}
.header-hm9 .header-top-area.header-top-border17 {
	border-bottom: 1px solid #9f9f9f;
}

.header-hm9 .header-right-wrap {
	margin-top: 13px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm9 .header-right-wrap {
		margin-right: 0;
	}
}
@media only screen and (max-width: 767px) {
	.header-hm9 .header-right-wrap {
		margin-right: 0;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
@media only screen and (max-width: 767px) {
	.header-hm9
		.header-right-wrap
		.same-style.cart-wrap
		.shopping-cart-content {
		right: -97px;
	}
}

.header-hm9 .logo-hm9 {
	margin-top: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-hm9 .mean-container a.meanmenu-reveal {
		top: -26px;
	}
	.header-hm9 .header-res-padding {
		padding: 20px 0 20px;
	}
}

@media only screen and (max-width: 767px) {
	.header-hm9 .mean-container a.meanmenu-reveal {
		top: -26px;
	}
	.header-hm9 .header-res-padding {
		padding: 20px 0 20px;
	}
}

/* header hm10 */
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.header-in-container .header-top-wap {
		display: block;
		margin-bottom: 15px;
		margin-top: 8px;
	}
	.header-in-container .header-top-wap .language-currency-wrap {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.header-in-container .header-top-wap .header-offer p {
		text-align: center;
	}
}

@media only screen and (max-width: 767px) {
	.header-hm9 .header-right-wrap .same-style.header-search .search-content {
		right: -142px;
	}
}

/*----- 3. Slider style  ------*/
.slider-height-1 {
	height: 750px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-height-1 {
		height: 650px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-1 {
		height: 520px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-1 {
		height: 450px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-1 {
		height: auto;
		padding: 50px 0 0;
	}
	.slider-height-1.slider-height-res15 {
		padding: 0px 0 0;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-height-1 {
		padding: 38px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-1.slider-height-res {
		height: 400px;
		position: relative;
	}
	.slider-height-1.slider-height-res:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: #fff;
		opacity: 0.5;
	}
}

.single-slider {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}
.single-slider .slider-content {
	padding: 183px 0 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-slider .slider-content {
		padding: 80px 0 0;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-slider .slider-content {
		padding: 45px 0 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-slider .slider-content {
		padding: 0px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.single-slider .slider-content {
		padding: 0px 0 40px;
		text-align: center;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-slider .slider-content {
		padding: 0px 0 0px;
		text-align: left;
	}
}
.single-slider .slider-content h3 {
	font-size: 24px;
	font-weight: 500;
	margin: 0;
}
.single-slider .slider-content h1 {
	line-height: 97px;
	font-size: 72px;
	color: #010101;
	margin: 6px 0 37px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-slider .slider-content h1 {
		line-height: 65px;
		font-size: 52px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-slider .slider-content h1 {
		line-height: 52px;
		font-size: 40px;
		margin: 6px 0 20px;
	}
}
@media only screen and (max-width: 767px) {
	.single-slider .slider-content h1 {
		line-height: 48px;
		font-size: 33px;
		margin: 6px 0 20px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-slider .slider-content h1 {
		font-size: 30px;
		margin: 6px 0 10px;
	}
}
.single-slider .slider-content .slider-btn a {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	border: 1px solid #333;
	text-transform: uppercase;
	line-height: 1;
	padding: 19px 50px 21px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-slider .slider-content .slider-btn a {
		padding: 15px 35px 17px;
	}
}
@media only screen and (max-width: 767px) {
	.single-slider .slider-content .slider-btn a {
		padding: 15px 35px 17px;
	}
}
.single-slider .slider-content .slider-btn a:hover {
	border: 1px solid #a749ff;
	color: #fff;
}
.single-slider .slider-content.slider-content-10 {
	padding-top: 0;
}
@media only screen and (max-width: 767px) {
	.single-slider .slider-content.slider-content-10 {
		text-align: center;
		display: block;
		width: 100%;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-slider .slider-content.slider-content-10 {
		text-align: left;
	}
}
.single-slider .slider-single-img {
	margin: 0 -80px 0 -80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-slider .slider-single-img {
		margin: 0 0px 0 0px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-slider .slider-single-img {
		margin: 0 0px 0 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-slider .slider-single-img {
		margin: 0 0px 0 0px;
	}
}
@media only screen and (max-width: 767px) {
	.single-slider .slider-single-img {
		margin: 0 0px 0 0px;
	}
}
.single-slider.single-slider-10 {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.owl-item .slider-content * {
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-item.active .slider-animated-1 h3 {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 h1 {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 h5 {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 p {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1 a {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1.slider-single-img img {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1.single-slider-img4 img {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1.slider-singleimg-hm9 img {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-1.slider-singleimg-hm11 img {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.owl-item.active .slider-animated-2 h3 {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-2 h1 {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-2 a {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

.owl-item.active .slider-animated-2.slider-singleimg-hm10 img {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

.nav-style-1.owl-carousel .owl-nav div {
	color: #999;
	display: inline-block;
	font-size: 40px;
	left: 80px;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	text-align: center;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.nav-style-1.owl-carousel .owl-nav div {
		left: 30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.nav-style-1.owl-carousel .owl-nav div {
		left: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-1.owl-carousel .owl-nav div {
		left: 15px;
		font-size: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.nav-style-1.owl-carousel .owl-nav div {
		left: 15px;
		font-size: 30px;
	}
}
.nav-style-1.owl-carousel .owl-nav div:hover {
	color: #a749ff;
}
.nav-style-1.owl-carousel .owl-nav div.owl-next {
	left: auto;
	right: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.nav-style-1.owl-carousel .owl-nav div.owl-next {
		right: 30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.nav-style-1.owl-carousel .owl-nav div.owl-next {
		right: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-1.owl-carousel .owl-nav div.owl-next {
		right: 15px;
	}
}
@media only screen and (max-width: 767px) {
	.nav-style-1.owl-carousel .owl-nav div.owl-next {
		right: 15px;
	}
}

.nav-style-1.owl-carousel.nav-testi-style .owl-nav div {
	font-size: 50px;
	left: -155px;
	color: #8d8c8c;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.nav-style-1.owl-carousel.nav-testi-style .owl-nav div {
		left: -80px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.nav-style-1.owl-carousel.nav-testi-style .owl-nav div {
		left: -80px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-1.owl-carousel.nav-testi-style .owl-nav div {
		left: -30px;
	}
}
@media only screen and (max-width: 767px) {
	.nav-style-1.owl-carousel.nav-testi-style .owl-nav div {
		display: none;
	}
}
.nav-style-1.owl-carousel.nav-testi-style .owl-nav div:hover {
	color: #3d6882;
}
.nav-style-1.owl-carousel.nav-testi-style .owl-nav div.owl-next {
	right: -155px;
	left: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.nav-style-1.owl-carousel.nav-testi-style .owl-nav div.owl-next {
		right: -80px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.nav-style-1.owl-carousel.nav-testi-style .owl-nav div.owl-next {
		right: -80px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-1.owl-carousel.nav-testi-style .owl-nav div.owl-next {
		right: -30px;
	}
}

.nav-style-1.owl-carousel.nav-testi-style.nav-testi-mrg .owl-nav div {
	left: -80px;
	color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-1.owl-carousel.nav-testi-style.nav-testi-mrg .owl-nav div {
		left: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.nav-style-1.owl-carousel.nav-testi-style.nav-testi-mrg .owl-nav div {
		display: none;
	}
}
.nav-style-1.owl-carousel.nav-testi-style.nav-testi-mrg .owl-nav div.owl-next {
	right: -80px;
	left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-1.owl-carousel.nav-testi-style.nav-testi-mrg
		.owl-nav
		div.owl-next {
		right: 0px;
		left: auto;
	}
}
@media only screen and (max-width: 767px) {
	.nav-style-1.owl-carousel.nav-testi-style.nav-testi-mrg
		.owl-nav
		div.owl-next {
		display: none;
	}
}

.slider-area:hover .nav-style-1.owl-carousel > .owl-nav div {
	opacity: 1;
	visibility: visible;
}

.slider-area.ml-10 {
	margin-left: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-area.ml-70 {
		margin-left: 15px;
	}
	.slider-area.mr-70 {
		margin-right: 15px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-area.ml-70 {
		margin-left: 30px;
	}
	.slider-area.mr-70 {
		margin-right: 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-area.ml-70 {
		margin-left: 40px;
	}
	.slider-area.mr-70 {
		margin-right: 40px;
	}
}

@media only screen and (max-width: 767px) {
	.slider-area.ml-70 {
		margin-left: 15px;
	}
	.slider-area.mr-70 {
		margin-right: 15px;
	}
}

/* slider style-2 */
.slider-content-2 {
	margin-top: -6px;
}
@media only screen and (max-width: 767px) {
	.slider-content-2 {
		text-align: center;
	}
}
.slider-content-2 h3 {
	color: #000000;
	font-size: 24px;
	font-weight: 500;
	position: relative;
	margin-left: 135px;
	margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
	.slider-content-2 h3 {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-2 h3 {
		margin-left: 60px;
	}
}
.slider-content-2 h3::before {
	position: absolute;
	left: -135px;
	top: 14px;
	content: "";
	width: 120px;
	height: 2px;
	background-color: #000000;
}
@media only screen and (max-width: 767px) {
	.slider-content-2 h3::before {
		display: none;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-2 h3::before {
		display: block;
		left: 80px;
		width: 60px;
	}
}
.slider-content-2 h1 {
	font-size: 72px;
	line-height: 97px;
	color: #010101;
	margin: 6px 0 37px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-content-2 h1 {
		font-size: 60px;
		line-height: 80px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-2 h1 {
		font-size: 56px;
		line-height: 70px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-2 h1 {
		font-size: 45px;
		line-height: 60px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-2 h1 {
		font-size: 35px;
		line-height: 45px;
	}
}
.slider-content-2 .slider-btn a {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	border: 1px solid #333;
	text-transform: uppercase;
	line-height: 1;
	padding: 19px 50px 21px;
	background-color: #eeeff1;
}
@media only screen and (max-width: 767px) {
	.slider-content-2 .slider-btn a {
		padding: 14px 30px 16px;
	}
}
.slider-content-2 .slider-btn a:hover {
	border: 1px solid #a749ff;
	color: #fff;
}
.slider-content-2.slider-content-fruits h3 {
	color: #6eab49;
}
.slider-content-2.slider-content-fruits h3::before {
	background-color: #6eab49;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-2.slider-content-fruits h3::before {
		left: 45px;
	}
}
.slider-content-2.slider-content-fruits h1 {
	font-size: 71px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-content-2.slider-content-fruits h1 {
		font-size: 55px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-2.slider-content-fruits h1 {
		font-size: 50px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-2.slider-content-fruits h1 {
		font-size: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-2.slider-content-fruits h1 {
		font-size: 30px;
		margin: 6px 0 20px;
	}
}
.slider-content-2.slider-content-fruits .slider-btn a {
	font-size: 16px;
	color: #fff;
	border: 1px solid #6eab49;
	background-color: #6eab49;
}
.slider-content-2.slider-content-fruits .slider-btn a:hover {
	border: 1px solid #a749ff;
	color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-slider-2 {
		background-position: 50%;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-slider-2 {
		background-position: 42%;
	}
}

@media only screen and (max-width: 767px) {
	.single-slider-2 {
		background-position: 30%;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-slider-2 {
		background-position: 22%;
	}
}

/* home 3 */
.slider-height-2 {
	height: 800px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-height-2 {
		height: 570px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-2 {
		height: 500px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-2 {
		height: 500px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-2 {
		height: 400px;
	}
}
.slider-height-2.slider-content-center {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.slider-content-3 h3 {
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	position: relative;
	display: inline-block;
}
.slider-content-3 h3::before {
	position: absolute;
	left: -110px;
	top: 16px;
	content: "";
	width: 80px;
	height: 2px;
	background-color: #000000;
}
.slider-content-3 h3::after {
	position: absolute;
	right: -110px;
	top: 16px;
	content: "";
	width: 80px;
	height: 2px;
	background-color: #000000;
}

.slider-content-3 h1 {
	font-size: 72px;
	line-height: 56px;
	margin: 28px 0 23px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-3 h1 {
		font-size: 52px;
		line-height: 40px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-3 h1 {
		font-size: 52px;
		line-height: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-3 h1 {
		font-size: 35px;
		line-height: 30px;
		margin: 18px 0 13px;
	}
}

.slider-content-3 p {
	font-size: 18px;
	color: #010101;
	margin: 0 0 44px;
}

.slider-content-3 .slider-btn a {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	border: 1px solid #333;
	text-transform: uppercase;
	line-height: 1;
	padding: 19px 50px 21px;
}
.slider-content-3 .slider-btn a:hover {
	border: 1px solid #a749ff;
	color: #fff;
}

.slider-content-3.slider-content-4 {
	margin-left: 112px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.slider-content-3.slider-content-4 {
		margin-left: 30px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-content-3.slider-content-4 {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-3.slider-content-4 {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-3.slider-content-4 {
		margin-left: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-3.slider-content-4 {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-3.slider-content-4 h1 {
		font-size: 34px;
		line-height: 35px;
		margin: 15px 0 15px;
	}
}
.slider-content-3.slider-content-4 h3:before {
	display: none;
}
.slider-content-3.slider-content-4 h3:after {
	display: none;
}

/* slider hm4 */
.slider-height-3 {
	height: 710px;
}
.slider-height-3 .container {
	margin-right: inherit;
	margin-left: inherit;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-height-3 {
		height: 570px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-3 {
		height: 500px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-3 {
		height: 500px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-3 {
		height: 400px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-3.slider-height-res-hm4 {
		height: 680px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-height-3.slider-height-res-hm4 {
		height: 400px;
	}
}

.single-slider-img4 {
	position: relative;
	margin: 0 -77px 0 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-slider-img4 {
		margin: 0 0px 0 0px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-slider-img4 {
		margin: 0 0px 0 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-slider-img4 {
		margin: 0 0px 0 0px;
	}
}
@media only screen and (max-width: 767px) {
	.single-slider-img4 {
		margin: 30px 0 0;
	}
}
.single-slider-img4 .single-price-wrap {
	position: absolute;
	top: 0;
	right: -24px;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-name: bounce-img;
	animation-name: bounce-img;
	-webkit-animation-duration: 2.9s;
	animation-duration: 2.9s;
	width: 130px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-slider-img4 .single-price-wrap {
		right: 0px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-slider-img4 .single-price-wrap {
		right: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-slider-img4 .single-price-wrap {
		right: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.single-slider-img4 .single-price-wrap {
		right: 0px;
	}
}
.single-slider-img4 .single-price-wrap img {
	width: 100%;
}
.single-slider-img4 .single-price-wrap .single-price {
	position: absolute;
	top: 0;
	left: 50%;
	margin-top: 26px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	text-align: center;
}
.single-slider-img4 .single-price-wrap .single-price span {
	display: block;
	line-height: 1;
	font-size: 24px;
	color: #915342;
	font-weight: 600;
}
.single-slider-img4 .single-price-wrap .single-price span.dollar {
	margin-top: 2px;
}

/* slider 5 */
.slider-height-4 {
	height: 750px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.slider-height-4 {
		height: 600px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-height-4 {
		height: 600px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-4 {
		height: 550px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-4 {
		height: 530px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-4 {
		height: 500px;
	}
}

.slider-content-5 {
	padding-top: 66px;
}
.slider-content-5 h3 {
	font-weight: 500;
	letter-spacing: 0.5px;
	font-size: 30px;
	margin: 0;
	color: #fff;
}
.slider-content-5 h1 {
	font-weight: 500;
	font-size: 72px;
	margin: 27px 0 22px;
	letter-spacing: 0.5px;
	color: #fff;
	line-height: 56px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-5 h1 {
		font-size: 58px;
		margin: 17px 0 20px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-5 h1 {
		font-size: 45px;
		margin: 15px 0 15px;
		line-height: 38px;
	}
}
.slider-content-5 p {
	font-size: 18px;
	color: #fff;
	margin: 0 0 45px;
}

.slider-btn-5 a {
	font-size: 16px;
	color: #010101;
	background-color: #fff;
	display: inline-block;
	line-height: 1;
	padding: 17px 40px 18px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}
.slider-btn-5 a:hover {
	border: none;
	color: #fff;
}

.nav-style-2.owl-carousel > .owl-nav div {
	color: #000;
	display: inline-block;
	font-size: 60px;
	left: 0px;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	text-align: center;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	width: 65px;
	height: 84px;
	line-height: 94px;
	background-color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.nav-style-2.owl-carousel > .owl-nav div {
		width: 45px;
		height: 64px;
		line-height: 74px;
		font-size: 45px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-2.owl-carousel > .owl-nav div {
		width: 45px;
		height: 64px;
		line-height: 74px;
		font-size: 45px;
	}
}
@media only screen and (max-width: 767px) {
	.nav-style-2.owl-carousel > .owl-nav div {
		width: 35px;
		height: 50px;
		line-height: 54px;
		font-size: 30px;
	}
}
.nav-style-2.owl-carousel > .owl-nav div:hover {
	color: #fff;
	background-color: #a749ff;
}
.nav-style-2.owl-carousel > .owl-nav div.owl-next {
	left: auto;
	right: 0px;
}

.slider-area:hover .nav-style-2.owl-carousel > .owl-nav div {
	opacity: 1;
	visibility: visible;
}

.slider-area.ml-10 {
	margin-left: 0;
}

/* slider 6 hm7 */
.slider-height-5 {
	height: 850px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.slider-height-5 {
		height: 600px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-height-5 {
		height: 600px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-5 {
		height: 550px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-5 {
		height: 530px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-5 {
		height: 500px;
	}
}

.slider-content-6 h1 {
	font-weight: 500;
	font-size: 60px;
	letter-spacing: 8px;
	color: #fff;
	margin: 0px;
	line-height: 48px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-6 h1 {
		font-size: 45px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-6 h1 {
		font-size: 45px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-6 h1 {
		font-size: 30px;
		line-height: 38px;
	}
}

.slider-content-6 p {
	margin: 32px 0 35px;
	font-size: 18px;
	color: #ffffff;
	letter-spacing: 1px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-6 p {
		margin: 22px 0 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-6 p {
		margin: 22px 0 30px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-6 p {
		margin: 18px 0 25px;
	}
}

.nav-style-3.owl-carousel > .owl-nav {
	position: absolute;
	right: 0;
	bottom: 0;
}
.nav-style-3.owl-carousel > .owl-nav div {
	color: #000;
	display: inline-block;
	font-size: 60px;
	left: 0px;
	text-align: center;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	width: 65px;
	height: 84px;
	line-height: 94px;
	background-color: #fff;
}
@media only screen and (max-width: 767px) {
	.nav-style-3.owl-carousel > .owl-nav div {
		width: 50px;
		height: 60px;
		font-size: 45px;
		line-height: 67px;
	}
}
.nav-style-3.owl-carousel > .owl-nav div:hover {
	background-color: #a749ff;
	color: #fff;
}

/* slider hm8 */
.slider-height-6 {
	height: 543px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-6 {
		height: 500px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-6 {
		height: 400px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-6 {
		height: 400px;
	}
}

.slider-hm8 .slider-content-5 {
	padding-top: 0px;
}

.slider-hm8 p {
	margin: 0 0 0px;
}

.owl-dot-style .owl-dots {
	bottom: 26px;
	left: 0px;
	position: absolute;
	right: 0;
	text-align: center;
}
.owl-dot-style .owl-dots .owl-dot {
	background: #fff none repeat scroll 0 0;
	border-radius: 100%;
	display: inline-block;
	height: 14px;
	margin: 0 8px;
	width: 14px;
	position: relative;
}
.owl-dot-style .owl-dots .owl-dot:before {
	position: absolute;
	content: "";
	left: -3px;
	right: -3px;
	top: -3px;
	bottom: -3px;
	border: 1px solid transparent;
	border-radius: 100%;
}
.owl-dot-style .owl-dots .owl-dot.active:before {
	border: 1px solid #fff;
}

/* slider hm9 */
.slider-height-7 {
	height: 950px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-7 {
		height: 800px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-7 {
		height: 600px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-7 {
		height: auto;
		padding: 230px 0 100px;
	}
}

.slider-h9-mrg {
	margin-top: -35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-h9-mrg {
		margin-top: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-h9-mrg {
		margin-top: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-h9-mrg {
		margin-top: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.slider-content-7 {
		text-align: center;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-7 {
		text-align: left;
	}
}

.slider-content-7 h3 {
	font-size: 16px;
	font-weight: 500;
	color: #313131;
	margin: 0;
	letter-spacing: 0.3px;
	line-height: 1;
}

.slider-content-7 h1 {
	font-size: 48px;
	font-weight: 500;
	color: #313131;
	margin: 17px 0 37px;
	line-height: 65px;
	letter-spacing: 1px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-7 h1 {
		font-size: 42px;
		line-height: 60px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-7 h1 {
		font-size: 32px;
		margin: 6px 0 22px;
		line-height: 50px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-7 h1 {
		font-size: 22px;
		margin: 6px 0 22px;
		line-height: 38px;
	}
}

.slider-content-7 .slider-btn-9 a {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	border: 1px solid #333;
	text-transform: uppercase;
	line-height: 1;
	padding: 19px 50px 19px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-7 .slider-btn-9 a {
		padding: 14px 30px 14px;
		font-size: 15px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-7 .slider-btn-9 a {
		padding: 15px 30px 15px;
		font-size: 15px;
	}
}
.slider-content-7 .slider-btn-9 a:hover {
	color: #fff;
	border: 1px solid #a749ff;
}

.slider-content-7.slider-content-mrg-hm17 {
	margin-top: 127px;
}
@media only screen and (max-width: 767px) {
	.slider-content-7.slider-content-mrg-hm17 {
		margin-top: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.slider-singleimg-hm9 {
		margin-top: 30px;
	}
	.slider-singleimg-hm9.ml-100 {
		margin-left: 20px;
	}
	.slider-singleimg-hm9.mr-100 {
		margin-right: 20px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-singleimg-hm9 {
		margin-top: 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-singleimg-hm9.ml-100 {
		margin-left: 40px;
	}
	.slider-singleimg-hm9.mr-100 {
		margin-right: 40px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-singleimg-hm9.ml-100 {
		margin-left: 70px;
	}
	.slider-singleimg-hm9.mr-100 {
		margin-right: 70px;
	}
}

/* slider hm10 */
.slider-height-8 {
	height: 700px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-height-8 {
		height: 650px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-8 {
		height: 520px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-8 {
		height: 450px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-8 {
		height: auto;
		padding: 60px 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-singleimg-hm10.ml-40 {
		margin-left: 0;
	}
	.slider-singleimg-hm10.mr-40 {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.slider-singleimg-hm10.ml-40 {
		margin-left: 0;
	}
	.slider-singleimg-hm10.mr-40 {
		margin-right: 0;
	}
}

/* home 11 */
.slider-height-9 {
	height: 780px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-height-9 {
		height: 700px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-9 {
		height: 630px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-9 {
		height: 500px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-9 {
		height: auto;
		padding: 230px 0 70px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-height-9 {
		padding: 230px 0 70px;
	}
}

.slider-content-11 h3 {
	font-size: 24px;
	font-weight: 500;
	margin: 0;
	letter-spacing: 1px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-11 h3 {
		font-size: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-11 h3 {
		font-size: 20px;
	}
}

.slider-content-11 h1 {
	font-size: 72px;
	margin: 0;
	line-height: 97px;
	color: #010101;
	margin: 15px 0 38px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-11 h1 {
		margin: 5px 0 27px;
		font-size: 52px;
		line-height: 65px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-11 h1 {
		margin: 3px 0 20px;
		font-size: 45px;
		line-height: 55px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-11 h1 {
		margin: 3px 0 20px;
		font-size: 40px;
		line-height: 50px;
	}
}

.slider-content-11 .slider-btn-11 a {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	border: 1px solid #333;
	text-transform: uppercase;
	line-height: 1;
	padding: 19px 50px 19px;
}
.slider-content-11 .slider-btn-11 a:hover {
	border: 1px solid #a749ff;
	color: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-11 .slider-btn-11 a {
		padding: 15px 35px 15px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-11 .slider-btn-11 a {
		padding: 12px 30px 12px;
		font-size: 14px;
	}
}

@media only screen and (max-width: 767px) {
	.slider-content-11 {
		text-align: center;
	}
}

@media only screen and (max-width: 767px) {
	.slider-content-11.fullpage-content {
		text-align: center;
		width: 100%;
	}
	.slider-content-11.fullpage-content h3 {
		font-size: 16px;
		letter-spacing: 0;
	}
	.slider-content-11.fullpage-content h1 {
		font-size: 30px;
		line-height: 40px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-11.fullpage-content h3 {
		font-size: 17px;
		letter-spacing: 0;
	}
	.slider-content-11.fullpage-content h1 {
		font-size: 35px;
	}
}

.slider-singleimg-hm11 {
	margin: 0 22px 0 28px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-singleimg-hm11 {
		margin: 0 50px 0 60px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-singleimg-hm11 {
		margin: 30px 0px 0 0px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-singleimg-hm11 {
		margin: 0px 0px 0 0px;
	}
}

.slider-h11-mrg {
	margin-bottom: -140px;
}
@media only screen and (max-width: 767px) {
	.slider-h11-mrg {
		margin-bottom: 0px;
	}
}

/* home 12 */
#section2 .fp-tableCell,
#section4 .fp-tableCell {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

#section1 .fp-tableCell,
#section3 .fp-tableCell {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

#section5 .fp-tableCell {
	height: auto !important;
}

#section5 {
	height: auto !important;
}

#fp-nav {
	top: 55%;
}
#fp-nav ul li {
	margin: 7px 7px 10px;
}
#fp-nav ul li a span {
	background: #000;
}
#fp-nav.right {
	right: 64px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	#fp-nav.right {
		right: 10px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	#fp-nav.right {
		right: 25px;
	}
}

.fullpage-slider-mrg {
	margin-top: -34px;
}
@media only screen and (max-width: 767px) {
	.fullpage-slider-mrg {
		margin-top: 0;
		margin-bottom: 30px;
	}
}

.fullpage-slider-mrg-2 {
	margin-bottom: -88px;
}
@media only screen and (max-width: 767px) {
	.fullpage-slider-mrg-2 {
		margin-bottom: 30px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.fullpage-slider-mrg-2 {
		margin-bottom: -50px;
	}
}

.slider-section.active .slider-animated-1 h3 {
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.slider-section.active .slider-animated-1 h1 {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.slider-section.active .slider-animated-1 a {
	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.slider-section.active .slider-animated-1 img {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

@media only screen and (max-width: 767px) {
	.slider12-img-2 {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

.slider12-img-2 img {
	width: 100%;
}

.slider12-img-1 {
	margin: 0 4px 0 6px;
}
.slider12-img-1 img {
	width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.slider12-img-1 {
		text-align: center;
	}
	.slider12-img-1 img {
		width: 350px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider12-img-1 {
		text-align: center;
	}
	.slider12-img-1 img {
		width: 350px;
	}
	.slider12-img-2 {
		text-align: center;
	}
	.slider12-img-2 img {
		width: 420px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider12-img-1 {
		text-align: center;
	}
	.slider12-img-1 img {
		width: 330px;
	}
}

@media only screen and (max-width: 767px) {
	.fullpage-wrapper {
		padding-top: 130px;
	}
}
@media only screen and (max-width: 767px) and (min-width: 576px) and (max-width: 767px) {
	.fullpage-wrapper {
		padding-top: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.fullpage-slider-wrap-mrg {
		margin-bottom: 30px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.fullpage-slider-wrap-mrg {
		margin-bottom: 0px;
	}
}

/* home 13 */
.slider-content-13 h5 {
	letter-spacing: 1px;
	font-size: 24px;
	font-weight: 500;
	margin: 0;
}
@media only screen and (max-width: 767px) {
	.slider-content-13 h5 {
		font-size: 22px;
	}
}

.slider-content-13 h1 {
	font-size: 120px;
	font-weight: 600;
	line-height: 120px;
	margin: 34px 0 55px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-13 h1 {
		font-size: 90px;
		line-height: 90px;
		margin: 24px 0 35px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-13 h1 {
		font-size: 80px;
		line-height: 90px;
		margin: 20px 0 30px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-13 h1 {
		font-size: 40px;
		line-height: 50px;
		margin: 15px 0 20px;
	}
}

.slider-content-13 .slider-btn a {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	border: 1px solid #333;
	text-transform: uppercase;
	line-height: 1;
	padding: 19px 50px 19px;
}
.slider-content-13 .slider-btn a:hover {
	color: #fff;
	border: 1px solid #a749ff;
}

@media only screen and (max-width: 767px) {
	.hm-13-slider {
		background-position: 72%;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.hm-13-slider {
		background-position: 55%;
	}
}

.slider-single-img-14 {
	padding-bottom: 72px;
	margin: 0 23px;
	position: relative;
	z-index: 9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-single-img-14 {
		padding-bottom: 60px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-single-img-14 {
		padding-bottom: 44px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-single-img-14 {
		padding-bottom: 0px;
		margin: 0 0px;
		text-align: center;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-single-img-14 {
		padding-bottom: 38px;
		text-align: inherit;
	}
}
.slider-single-img-14 img {
	width: 100%;
}

.slider-height-14 {
	height: 850px;
	padding: 89px 0 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-height-14 {
		height: 715px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-height-14 {
		height: 525px;
		padding: 70px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-14 {
		height: auto;
		padding: 120px 0 0;
	}
}

.slider-content-14 {
	margin: -90px 0 0;
}
@media only screen and (max-width: 767px) {
	.slider-content-14 {
		margin: 0px 0 30px;
		text-align: center;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-14 {
		margin: 0px 0 30px;
		text-align: left;
	}
}
.slider-content-14 h3 {
	color: #fff;
	font-weight: 500;
	font-size: 24px;
	margin: 0 0 0 150px;
	position: relative;
	letter-spacing: 0.8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-14 h3 {
		font-size: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-14 h3 {
		font-size: 20px;
		margin: 0 0 0 100px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-14 h3 {
		margin: 0 0 0 60px;
	}
}
.slider-content-14 h3::before {
	position: absolute;
	left: -135px;
	top: 14px;
	content: "";
	width: 120px;
	height: 2px;
	background-color: #fff;
}
@media only screen and (max-width: 767px) {
	.slider-content-14 h3::before {
		left: -100px;
		width: 80px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-14 h3::before {
		left: -60px;
		width: 50px;
	}
}
.slider-content-14 h1 {
	color: #fff;
	font-size: 72px;
	line-height: 97px;
	margin: 14px 0 38px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-14 h1 {
		font-size: 55px;
		line-height: 75px;
		margin: 10px 0 25px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-14 h1 {
		font-size: 42px;
		line-height: 60px;
		margin: 10px 0 25px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-14 h1 {
		font-size: 33px;
		line-height: 50px;
		margin: 5px 0 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-14 h1 {
		font-size: 30px;
		line-height: 40px;
	}
}
.slider-content-14 .slider-btn a {
	font-size: 16px;
	color: #010101;
	display: inline-block;
	text-transform: uppercase;
	line-height: 1;
	padding: 20px 50px 19px;
	border: none;
	background-color: #eeeff1;
	-webkit-transition: all 0.4s ease-in-out 0s;
	-o-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
	z-index: 1;
}
@media only screen and (max-width: 767px) {
	.slider-content-14 .slider-btn a {
		padding: 16px 30px 15px;
		font-size: 15px;
	}
}
.slider-content-14 .slider-btn a:hover {
	color: #fff;
}

.slider-content-15 h1 {
	color: #fff;
	font-size: 90px;
	font-family: "Abril Fatface", cursive;
	line-height: 115px;
	letter-spacing: 1px;
	margin: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.slider-content-15 h1 {
		font-size: 75px;
		line-height: 100px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-15 h1 {
		font-size: 70px;
		line-height: 85px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-15 h1 {
		font-size: 60px;
		line-height: 85px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-15 h1 {
		font-size: 38px;
		line-height: 55px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.slider-content-15 h1 {
		font-size: 42px;
		line-height: 65px;
	}
}

.slider-content-15.slider15-mrg-nrg h1 {
	margin-top: -26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-15.slider15-mrg-nrg h1 {
		margin-top: -18px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-15.slider15-mrg-nrg h1 {
		margin-top: -20px;
	}
}

.slider-content-15 .dealy-style-2 {
	margin: 49px 0 43px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-15 .dealy-style-2 {
		margin: 40px 0 40px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-15 .dealy-style-2 {
		margin: 30px 0 35px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-15 .dealy-style-2 {
		margin: 20px 0 5px;
	}
}
.slider-content-15 .dealy-style-2 span {
	display: inline-block;
	font-size: 48px;
	font-weight: 300;
	color: #fff;
	margin: 0 34px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.slider-content-15 .dealy-style-2 span {
		margin: 0 25px 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-15 .dealy-style-2 span {
		margin: 0 20px 0;
		font-size: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-15 .dealy-style-2 span {
		margin: 0 20px 20px;
		font-size: 30px;
	}
}
.slider-content-15 .dealy-style-2 span p {
	font-size: 18px;
	font-weight: 500;
	margin: 33px 0 0;
	color: #fff;
	text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-content-15 .dealy-style-2 span p {
		margin: 20px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.slider-content-15 .dealy-style-2 span p {
		margin: 10px 0 0;
	}
}

.slider-content-15 .slider-btn-12 a {
	display: inline-block;
	background-color: #e90042;
	border: 1px solid #e90042;
	color: #fff;
	text-transform: uppercase;
	line-height: 1;
	font-size: 16px;
	padding: 17px 47px 19px;
	z-index: 1;
}
@media only screen and (max-width: 767px) {
	.slider-content-15 .slider-btn-12 a {
		padding: 13px 30px 15px;
	}
}
.slider-content-15 .slider-btn-12 a:hover {
	border: 1px solid #a749ff;
}

.slider-height-18 {
	height: 460px;
}
@media only screen and (max-width: 767px) {
	.slider-height-18 {
		background-position: 85%;
	}
}
@media only screen and (max-width: 767px) {
	.slider-height-18.slider-overly-res {
		height: 400px;
		position: relative;
	}
	.slider-height-18.slider-overly-res:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: #fff;
		opacity: 0.5;
	}
}

.slider-banner-area {
	margin-top: 163px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.slider-banner-area {
		margin-top: 162px;
	}
}
@media only screen and (max-width: 767px) {
	.slider-banner-area {
		margin-top: 186px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.res-mrg-md-mb {
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.res-mrg-md-mb {
		margin-bottom: 30px;
	}
}

/*-------- 4. Support style  -------*/
.support-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.support-wrap .support-icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50px;
	flex: 0 0 50px;
	margin-right: 20px;
}
.support-wrap .support-icon img {
	width: 100%;
}
.support-wrap .support-content h5 {
	font-size: 18px;
	margin: 0 0 5px;
}
.support-wrap .support-content p {
	color: #666;
	margin: 0;
}
.support-wrap.support-2 {
	margin-left: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.support-wrap.support-2 {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.support-wrap.support-2 {
		margin-left: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.support-wrap.support-2 {
		margin-left: 0px;
	}
}
.support-wrap.support-2 .support-icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 41px;
	flex: 0 0 41px;
}
.support-wrap.support-3 {
	margin-left: 9px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.support-wrap.support-3 {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.support-wrap.support-3 {
		margin-left: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.support-wrap.support-3 {
		margin-left: 0px;
	}
}
.support-wrap.support-3 .support-icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 41px;
	flex: 0 0 41px;
}
.support-wrap.support-4 {
	margin-left: 23px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.support-wrap.support-4 {
		margin-left: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.support-wrap.support-4 {
		margin-left: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.support-wrap.support-4 {
		margin-left: 0px;
	}
}
.support-wrap.support-4 .support-icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 35px;
	flex: 0 0 35px;
}
.support-wrap:hover .support-icon img {
	-webkit-animation: 500ms ease-in-out 0s normal none 1 running tada;
	animation: 500ms ease-in-out 0s normal none 1 running tada;
}

/* support hm3 */
.support-wrap-2 .support-content-2 h5 {
	font-size: 18px;
	color: #494949;
	margin: 15px 0 15px;
	line-height: 24px;
}

.support-wrap-2 .support-content-2 p {
	font-size: 14px;
	color: #6a6a6a;
	margin: 0 auto;
	width: 80%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.support-wrap-2 .support-content-2 p {
		width: 100%;
	}
}

.support-wrap-2:hover .support-content-2 img {
	-webkit-animation: 500ms ease-in-out 0s normal none 1 running tada;
	animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.support-wrap-2.support-shape {
	position: relative;
}
.support-wrap-2.support-shape:before {
	position: absolute;
	right: -14px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #d5d4d4;
	height: 61px;
	width: 1px;
	content: "";
}

.support-wrap-2.support-shape-2 {
	border: 4px solid #fff;
}

.support-wrap-2.support-shape-3 {
	border: 4px solid #f6f6f6;
}

.support-wrap-2.support-padding-2 {
	padding: 36px 10px 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.support-wrap-2.support-padding-2 {
		padding: 36px 5px 30px;
	}
}
@media only screen and (max-width: 767px) {
	.support-wrap-2.support-padding-2 {
		padding: 36px 6px 30px;
	}
}

.support-bg-color-1 {
	background-color: #ccfbe9;
}

.support-bg-color-2 {
	background-color: #f2fbcc;
}

.support-bg-color-3 {
	background-color: #ddfbcc;
}

.support-wrap-3 {
	position: relative;
	padding: 30px 10px 24px;
	overflow: hidden;
}
.support-wrap-3 .support-icon-2 {
	position: absolute;
	right: 0;
	bottom: 0;
}
.support-wrap-3 .support-content-3 p {
	color: #000000;
	margin: 4px 0 0;
	font-size: 16px;
	text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
	.support-wrap-3 .support-content-3 p {
		font-size: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.support-wrap-3 .support-content-3 p {
		font-size: 14px;
	}
}
.support-wrap-3:hover .support-icon-2 img {
	-webkit-animation: 500ms ease-in-out 0s normal none 1 running tada;
	animation: 500ms ease-in-out 0s normal none 1 running tada;
}

@media only screen and (max-width: 767px) {
	.support-area.pt-80 {
		padding-top: 30px;
	}
}

/*------- 5. Section title style  -------*/
@media only screen and (max-width: 767px) {
	.section-title.mb-50 {
		margin-bottom: 30px;
	}
}

.section-title h2 {
	font-size: 30px;
	font-weight: 600;
	margin: 0 0 0;
	position: relative;
	display: inline-block;
}
@media only screen and (max-width: 767px) {
	.section-title h2 {
		font-size: 24px;
		line-height: 30px;
	}
	.section-title h2.mb-50 {
		margin-bottom: 30px;
	}
}
.section-title h2::before {
	position: absolute;
	content: "";
	left: -100px;
	background-color: #000;
	width: 80px;
	height: 2px;
	top: 17px;
}
@media only screen and (max-width: 767px) {
	.section-title h2::before {
		left: -40px;
		width: 30px;
	}
}
.section-title h2::after {
	position: absolute;
	content: "";
	right: -100px;
	background-color: #000;
	width: 80px;
	height: 2px;
	top: 17px;
}
@media only screen and (max-width: 767px) {
	.section-title h2::after {
		right: -40px;
		width: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.section-title.mb-55 {
		margin-bottom: 30px;
	}
}

.section-title-2 h2 {
	font-size: 30px;
	color: #000000;
	font-weight: 600;
	margin: 0 0 15px;
	padding: 0 0 15px;
	position: relative;
	display: inline-block;
}
.section-title-2 h2:before {
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	margin: 0 auto;
	bottom: 0;
	width: 80px;
	height: 2px;
	background-color: #000000;
}

.section-title-2 p {
	color: #333;
	margin: 0;
}

@media only screen and (max-width: 767px) {
	.section-title-2.mb-60 {
		margin-bottom: 30px;
	}
}

.section-title-3 h4 {
	font-weight: 500;
	color: #000000;
	font-size: 24px;
}

@media only screen and (max-width: 767px) {
	.section-title-3.mb-40 {
		margin-bottom: 20px;
	}
}

.section-border {
	margin-right: 94px;
	overflow: hidden;
	position: relative;
}
@media only screen and (max-width: 767px) {
	.section-border {
		margin-right: 0px;
	}
}
.section-border::before {
	background-color: #d2d2d2;
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	top: 11px;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 100%;
	z-index: 1;
}
@media only screen and (max-width: 767px) {
	.section-border::before {
		display: none;
	}
}

.section-title-4 {
	position: relative;
	z-index: 999;
}
.section-title-4 h3 {
	font-weight: 600;
	color: #242424;
	font-size: 20px;
	letter-spacing: -0.25px;
	margin: 0;
	line-height: 1;
	padding-right: 45px;
}
@media only screen and (max-width: 767px) {
	.section-title-4 h3 {
		padding-right: 0px;
	}
}

.section-title-5 h2 {
	color: #010101;
	font-size: 48px;
	margin: 0;
	line-height: 38px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-title-5 h2 {
		font-size: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.section-title-5 h2 {
		font-size: 30px;
		line-height: 27px;
	}
}

@media only screen and (max-width: 767px) {
	.section-title-5.mb-60 {
		margin-bottom: 30px;
	}
}

/*------- 6. Product style  --------*/
.product-area {
	position: relative;
}

.product-tab-list {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media only screen and (max-width: 767px) {
	.product-tab-list.pt-30,
	.product-tab-list.pt-55 {
		padding: 10px 0 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-tab-list.pt-35,
	.product-tab-list.pt-60 {
		padding: 20px 0 30px;
	}
}
@media only screen and (max-width: 767px) {
	.product-tab-list.pt-35,
	.product-tab-list.pt-60 {
		padding: 10px 0 10px;
	}
}
.product-tab-list a {
	margin: 0 11px;
}
@media only screen and (max-width: 767px) {
	.product-tab-list a {
		margin: 0 11px 5px;
	}
}
.product-tab-list a h4 {
	font-weight: 500;
	font-size: 18px;
	color: #555;
	margin: 0;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.product-tab-list a h4:hover {
	color: #000;
}
.product-tab-list a.active h4 {
	color: #000;
}
.product-tab-list.product-tab-fruits a h4 {
	color: #000000;
	border-bottom: 2px solid transparent;
}
.product-tab-list.product-tab-fruits a h4:hover {
	color: #6eab49;
}
.product-tab-list.product-tab-fruits a.active h4 {
	color: #6eab49;
	border-bottom: 2px solid #6eab49;
}
.product-tab-list.product-tab-pink a h4 {
	color: #000000;
	border-bottom: 2px solid transparent;
}
.product-tab-list.product-tab-pink a h4:hover {
	color: #e90042;
}
.product-tab-list.product-tab-pink a.active h4 {
	color: #e90042;
	border-bottom: 2px solid #e90042;
}
.product-tab-list.product-tab-pink2 a h4 {
	color: #000000;
	border-bottom: 2px solid transparent;
}
.product-tab-list.product-tab-pink2 a h4:hover {
	color: #ed59a0;
}
.product-tab-list.product-tab-pink2 a.active h4 {
	color: #ed59a0;
	border-bottom: 2px solid ed59a0e90042;
}

.product-wrap {
	position: relative;
}
.product-wrap .product-img {
	position: relative;
	overflow: hidden;
}
.product-wrap .product-img img {
	width: 100%;
}
.product-wrap .product-img span {
	position: absolute;
	top: 12px;
	right: 12px;
	font-size: 13px;
	color: #fff;
	display: inline-block;
	line-height: 1;
	padding: 3px 11px;
	border-radius: 3px;
	font-weight: 500;
}
.product-wrap .product-img span.pink {
	background-color: #fa6bff;
}
.product-wrap .product-img span.purple {
	background-color: #a749ff;
}
.product-wrap .product-img .hover-img {
	position: absolute;
	top: 0;
	left: 50%;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translate(-50%, 20px);
	-ms-transform: translate(-50%, 20px);
	transform: translate(-50%, 20px);
	-webkit-transition-duration: 0.7s;
	-o-transition-duration: 0.7s;
	transition-duration: 0.7s;
}
.product-wrap .product-img .product-action {
	position: absolute;
	left: 50%;
	bottom: 0;
	width: 100%;
	z-index: 9;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.product-wrap .product-img .product-action > div {
	opacity: 0;
	visibility: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 48px;
	-webkit-transform: translateY(20px);
	-ms-transform: translateY(20px);
	transform: translateY(20px);
	background-color: #a749ff;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.product-wrap .product-img .product-action > div:hover {
	background-color: #000;
}
.product-wrap .product-img .product-action .pro-wishlist {
	width: 48px;
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.product-wrap .product-img .product-action .pro-cart {
	font-size: 14px;
	width: calc(100% - 48px - 48px);
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.product-wrap .product-img .product-action .pro-quickview {
	width: 48px;
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	margin: 0;
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.product-wrap .product-img .product-action .pro-same-action a {
	color: #fff;
	font-size: 16px;
	height: 48px;
	line-height: 48px;
	display: block;
	width: 100%;
	text-align: center;
	text-transform: capitalize;
}
.product-wrap .product-img .product-action .pro-same-action.pro-wishlist a,
.product-wrap .product-img .product-action .pro-same-action.pro-quickview a {
	margin-top: 2px;
}
.product-wrap .product-content {
	margin: 20px 0 0;
}
.product-wrap .product-content h3 {
	font-size: 16px;
	margin: 0;
}
.product-wrap .product-content .product-rating {
	margin: 3px 0 3px;
}
.product-wrap .product-content .product-rating i {
	font-size: 17px;
	color: #5f5d5d;
	margin: 0 3px;
}
.product-wrap .product-content .product-rating i.yellow {
	color: #ffa900;
}
.product-wrap .product-content .product-price span {
	font-size: 15px;
	color: #000;
	font-weight: 500;
	margin: 0 9px;
	position: relative;
}
.product-wrap .product-content .product-price span::before {
	position: absolute;
	content: "";
	right: -13px;
	top: 10px;
	width: 7px;
	height: 2px;
	background-color: #000;
}
.product-wrap .product-content .product-price span:last-child:before {
	display: none;
}
.product-wrap .product-content .product-price span.old {
	color: #8e8e8e;
	text-decoration: line-through;
}
.product-wrap:hover .hover-img {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
.product-wrap:hover .product-action > div {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.modal-dialog {
	margin: 8% auto;
	max-width: 960px;
	width: 960px;
	padding: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.modal-dialog {
		width: 720px;
		max-width: 720px;
	}
}
@media only screen and (max-width: 767px) {
	.modal-dialog {
		width: 100%;
		max-width: 100%;
		padding: 35px 0;
	}
}
.modal-dialog .modal-header .close {
	color: #333;
	cursor: pointer;
	opacity: 1;
}
.modal-dialog .modal-header .close:hover {
	color: #a749ff;
}
.modal-dialog .modal-body {
	padding: 35px 15px;
}
.modal-dialog .quickview-big-img img {
	width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-details-content.ml-70 {
		margin-left: 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-content.ml-70 {
		margin-left: 0;
	}
}

@media only screen and (max-width: 767px) {
	.product-details-content.ml-70 {
		margin-left: 0;
		margin-top: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.product-details-content.quickview-content {
		margin-top: 30px;
	}
}

.product-details-content h2 {
	color: #010101;
	font-size: 24px;
	margin: 0 0 0px;
	line-height: 1;
}

.product-details-content .product-details-price {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 15px 0 26px;
}
.product-details-content .product-details-price span {
	font-size: 24px;
	color: #fe5252;
}
.product-details-content .product-details-price span.old {
	color: #333;
	font-size: 18px;
	text-decoration: line-through;
	margin-left: 20px;
}

.product-details-content .pro-details-rating-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 0 17px;
}
.product-details-content .pro-details-rating-wrap .pro-details-rating {
	position: relative;
	margin-right: 39px;
}
.product-details-content .pro-details-rating-wrap .pro-details-rating:before {
	position: absolute;
	right: -19px;
	top: 4px;
	content: "";
	height: 15px;
	width: 2px;
	background-color: #d1d1d1;
}
.product-details-content .pro-details-rating-wrap .pro-details-rating i {
	color: #5f5d5d;
	font-size: 17px;
	margin-right: 5px;
}
.product-details-content .pro-details-rating-wrap .pro-details-rating i.yellow {
	color: #ffa900;
}
.product-details-content .pro-details-rating-wrap span a {
	font-size: 15px;
	color: #a749ff;
}
.product-details-content .pro-details-rating-wrap span a:hover {
	color: #000;
}

.product-details-content p {
	font-size: 15px;
	line-height: 28px;
	color: #333;
	margin: 0;
}

.product-details-content .pro-details-list {
	margin: 20px 0 34px;
	border-bottom: 1px solid #e5e5e5;
	padding: 0 0 37px;
}
.product-details-content .pro-details-list ul li {
	color: #333;
	margin: 0 0 5px;
}
.product-details-content .pro-details-list ul li:last-child {
	margin: 0;
}

.product-details-content .pro-details-size-color {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media only screen and (max-width: 767px) {
	.product-details-content .pro-details-size-color {
		display: block;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.product-details-content .pro-details-size-color {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
.product-details-content .pro-details-size-color .pro-details-color-wrap {
	margin-right: 20px;
	overflow: hidden;
}
@media only screen and (max-width: 767px) {
	.product-details-content .pro-details-size-color .pro-details-color-wrap {
		margin-bottom: 20px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.product-details-content .pro-details-size-color .pro-details-color-wrap {
		margin-bottom: 0px;
	}
}
.product-details-content .pro-details-size-color .pro-details-color-wrap span {
	display: block;
	font-weight: 500;
	margin: 0 0 15px;
	font-size: 15px;
}
.product-details-content
	.pro-details-size-color
	.pro-details-color-wrap
	.pro-details-color-content
	ul
	li {
	border-radius: 50px;
	cursor: pointer;
	display: block;
	float: left;
	height: 14px;
	margin-right: 15px;
	position: relative;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 14px;
}
.product-details-content
	.pro-details-size-color
	.pro-details-color-wrap
	.pro-details-color-content
	ul
	li:last-child {
	margin-right: 0px;
}
.product-details-content
	.pro-details-size-color
	.pro-details-color-wrap
	.pro-details-color-content
	ul
	li.blue {
	background-color: #4798f3;
	border: 1px solid #4798f3;
}
.product-details-content
	.pro-details-size-color
	.pro-details-color-wrap
	.pro-details-color-content
	ul
	li.maroon {
	background-color: #736751;
	border: 1px solid #736751;
}
.product-details-content
	.pro-details-size-color
	.pro-details-color-wrap
	.pro-details-color-content
	ul
	li.gray {
	background-color: #c0c0c0;
	border: 1px solid #c0c0c0;
}
.product-details-content
	.pro-details-size-color
	.pro-details-color-wrap
	.pro-details-color-content
	ul
	li.green {
	background-color: #139c57;
	border: 1px solid #139c57;
}
.product-details-content
	.pro-details-size-color
	.pro-details-color-wrap
	.pro-details-color-content
	ul
	li.yellow {
	background-color: #e28b37;
	border: 1px solid #e28b37;
}
.product-details-content .pro-details-size-color .pro-details-size span {
	display: block;
	font-weight: 500;
	margin: 0 0 10px;
	font-size: 15px;
}
.product-details-content
	.pro-details-size-color
	.pro-details-size
	.pro-details-size-content
	ul
	li {
	display: inline-block;
	list-style: outside none none;
	margin: 0 2px 0 0;
}
.product-details-content
	.pro-details-size-color
	.pro-details-size
	.pro-details-size-content
	ul
	li
	a {
	background-color: #f1f2f6;
	color: #000;
	display: inline-block;
	font-size: 12px;
	line-height: 1;
	padding: 6px 9px 7px;
	text-transform: uppercase;
}
.product-details-content
	.pro-details-size-color
	.pro-details-size
	.pro-details-size-content
	ul
	li
	a:hover {
	background-color: #a749ff;
	color: #fff;
}

.product-details-content .pro-details-quality {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 34px;
}
.product-details-content .pro-details-quality .cart-plus-minus {
	border: 1px solid #e8e8e8;
	display: inline-block;
	height: 60px;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: 80px;
}
.product-details-content .pro-details-quality .cart-plus-minus .qtybutton {
	color: #8f8f8f;
	cursor: pointer;
	float: inherit;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
	position: absolute;
	text-align: center;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	width: 24px;
}
.product-details-content .pro-details-quality .cart-plus-minus .dec.qtybutton {
	height: 60px;
	left: 0;
	padding-top: 20px;
	top: 0;
}
.product-details-content .pro-details-quality .cart-plus-minus .inc.qtybutton {
	height: 60px;
	padding-top: 20px;
	right: 0;
	top: 0;
}
.product-details-content
	.pro-details-quality
	.cart-plus-minus
	input.cart-plus-minus-box {
	background: transparent none repeat scroll 0 0;
	border: medium none;
	color: #8f8f8f;
	float: left;
	font-size: 14px;
	height: 60px;
	margin: 0;
	padding: 0;
	text-align: center;
	width: 80px;
}
.product-details-content .pro-details-quality .pro-details-cart {
	margin: 0 25px 0 10px;
}
@media only screen and (max-width: 767px) {
	.product-details-content .pro-details-quality .pro-details-cart {
		margin: 0 10px 0 10px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-content .pro-details-quality .pro-details-cart {
		margin: 0 17px 0 10px;
	}
}
.product-details-content .pro-details-quality .pro-details-cart a {
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	background-color: #343538;
	display: inline-block;
	line-height: 1;
	padding: 23px 38px 23px;
	z-index: 99;
}
@media only screen and (max-width: 767px) {
	.product-details-content .pro-details-quality .pro-details-cart a {
		padding: 23px 12px 23px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-content .pro-details-quality .pro-details-cart a {
		padding: 22px 22px 22px;
	}
}
.product-details-content .pro-details-quality .pro-details-cart a:hover {
	border: none;
}
.product-details-content .pro-details-quality .pro-details-wishlist a {
	font-size: 18px;
	color: #000;
}
.product-details-content .pro-details-quality .pro-details-wishlist a:hover {
	color: #a749ff;
}
.product-details-content .pro-details-quality .pro-details-compare a {
	font-size: 18px;
	color: #000;
	margin-left: 25px;
}
@media only screen and (max-width: 767px) {
	.product-details-content .pro-details-quality .pro-details-compare a {
		margin-left: 10px;
	}
}
.product-details-content .pro-details-quality .pro-details-compare a:hover {
	color: #a749ff;
}

.product-details-content .pro-details-meta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0 0 10px;
}
.product-details-content .pro-details-meta span {
	font-size: 15px;
	color: #676767;
	margin-right: 5px;
}
.product-details-content .pro-details-meta ul li {
	display: inline-block;
	margin: 0 3px 0 0;
}
.product-details-content .pro-details-meta ul li a {
	color: #676767;
	font-size: 15px;
}
@media only screen and (max-width: 767px) {
	.product-details-content .pro-details-meta ul li a {
		font-size: 14px;
	}
}
.product-details-content .pro-details-meta ul li a:hover {
	color: #a749ff;
}

.product-details-content .pro-details-social {
	margin: 24px 0 0;
}
.product-details-content .pro-details-social ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.product-details-content .pro-details-social ul li {
	margin-right: 40px;
}
.product-details-content .pro-details-social ul li:last-child {
	margin-right: 0px;
}
.product-details-content .pro-details-social ul li a {
	font-size: 16px;
	color: #343538;
}
.product-details-content .pro-details-social ul li a:hover {
	color: #a749ff;
}

@media only screen and (max-width: 767px) {
	.product-details-content.pro-details-slider-content.mt-50 {
		margin-top: 30px;
	}
}

.product-details-content.pro-details-slider-content .product-details-price {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.product-details-content.pro-details-slider-content p {
	width: 59%;
	margin: 0 auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-details-content.pro-details-slider-content p {
		width: 80%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-content.pro-details-slider-content p {
		width: 100%;
	}
}
@media only screen and (max-width: 767px) {
	.product-details-content.pro-details-slider-content p {
		width: 100%;
	}
}

.product-details-content.pro-details-slider-content .pro-details-rating-wrap {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.product-details-content.pro-details-slider-content .pro-details-size-color {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: left;
}
@media only screen and (max-width: 767px) {
	.product-details-content.pro-details-slider-content
		.pro-details-size-color {
		text-align: center;
	}
	.product-details-content.pro-details-slider-content
		.pro-details-size-color
		.pro-details-color-content {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}

.product-details-content.pro-details-slider-content .pro-details-quality {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.product-details-content.pro-details-slider-content .pro-details-meta {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.product-details-content.pro-details-slider-content .pro-details-social ul {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.quickview-wrap .nav-style-1.owl-carousel > .owl-nav div {
	font-size: 20px;
	color: #333;
	left: 0px;
}
.quickview-wrap .nav-style-1.owl-carousel > .owl-nav div:hover {
	color: #a749ff;
}
.quickview-wrap .nav-style-1.owl-carousel > .owl-nav div.owl-next {
	right: 0px;
	left: auto;
}

.quickview-wrap:hover .nav-style-1.owl-carousel > .owl-nav div {
	opacity: 1;
	visibility: visible;
}

/*  prodduct 2     */
.tab-filter-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media only screen and (max-width: 767px) {
	.tab-filter-wrap {
		display: block;
	}
	.tab-filter-wrap.mb-60 {
		margin-bottom: 30px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.tab-filter-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.tab-filter-wrap.mb-60 {
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.product-tab-list-2 {
		margin: 0 0 10px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.product-tab-list-2 {
		margin: 0 0 0px;
	}
}

.product-tab-list-2 a {
	display: inline-block;
	margin-right: 23px;
}
@media only screen and (max-width: 767px) {
	.product-tab-list-2 a {
		margin: 0 23px 10px 0;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.product-tab-list-2 a {
		margin: 0 23px 0px 0;
	}
}
.product-tab-list-2 a:last-child {
	margin-right: 0;
}
.product-tab-list-2 a h4 {
	color: #555;
	font-size: 18px;
	font-weight: 500;
	margin: 0;
	border-bottom: 2px solid transparent;
	padding-bottom: 5px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.product-tab-list-2 a h4:hover {
	color: #000000;
}
.product-tab-list-2 a.active h4 {
	color: #000000;
	border-bottom: 2px solid #000000;
}

.filter-active {
	position: relative;
}
.filter-active a {
	font-weight: 500;
	font-size: 18px;
	color: #010101;
}
.filter-active a:hover {
	color: #a749ff;
}
.filter-active a i {
	font-size: 14px;
}

.product-filter-wrapper {
	background-color: #fff;
	-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	margin-bottom: 60px;
	padding: 45px 45px 7px;
	display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-filter-wrapper {
		padding: 35px 25px 7px;
	}
}
@media only screen and (max-width: 767px) {
	.product-filter-wrapper {
		padding: 35px 25px 7px;
	}
}
.product-filter-wrapper .product-filter h5 {
	color: #000;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 25px;
	padding-bottom: 7px;
	position: relative;
	text-transform: capitalize;
}
.product-filter-wrapper .product-filter h5::before {
	background-color: #000;
	bottom: 0;
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	width: 20px;
}
.product-filter-wrapper .product-filter ul.sort-by li {
	margin-bottom: 3px;
}
.product-filter-wrapper .product-filter ul.sort-by li:last-child {
	margin-bottom: 0;
}
.product-filter-wrapper .product-filter ul.color-filter li {
	margin-bottom: 6px;
}
.product-filter-wrapper .product-filter ul.color-filter li:last-child {
	margin-bottom: 0px;
}
.product-filter-wrapper .product-filter ul.color-filter li input {
	width: 20px;
	height: 20px;
	display: inline-block;
	float: left;
	margin: 3px 8px 0 0;
}
.product-filter-wrapper .product-filter ul.color-filter li a {
	text-transform: capitalize;
}
.product-filter-wrapper .product-filter .product-tags ul li {
	margin-bottom: 4px;
}
.product-filter-wrapper .product-filter .product-tags ul li a {
	text-transform: capitalize;
}

.price-filter .price-slider-amount input {
	background: transparent;
	padding: 0;
	border: none;
	font-size: 16px;
	font-weight: 500;
}

.price-filter #slider-range {
	background: #dbdbdb none repeat scroll 0 0;
	border: medium none;
	border-radius: 50px;
	height: 5px;
	margin-bottom: 12px;
	margin-left: auto;
}
.price-filter #slider-range span {
	-webkit-transition: all 0s ease 0s;
	-o-transition: all 0s ease 0s;
	transition: all 0s ease 0s;
}
.price-filter #slider-range .ui-slider-range {
	position: absolute;
	display: block;
	border: 0;
	background: #fa6bff none repeat scroll 0 0;
	border: none;
	-webkit-transition: all 0s ease 0s;
	-o-transition: all 0s ease 0s;
	transition: all 0s ease 0s;
}
.price-filter #slider-range .ui-slider-handle {
	background: #fff none repeat scroll 0 0;
	border: medium none;
	border-radius: 50%;
	height: 15px;
	margin-left: 0;
	width: 15px;
	border: 4px solid #fa6bff;
}

.tab-content.jump .tab-pane {
	display: block;
	height: 0;
	opacity: 0;
	overflow: hidden;
}
.tab-content.jump .tab-pane.active {
	display: block;
	height: auto;
	opacity: 1;
	overflow: visible;
}

.product-wrap-2 {
	position: relative;
	overflow: hidden;
}
.product-wrap-2 .product-img {
	position: relative;
}
.product-wrap-2 .product-img img {
	width: 100%;
}
.product-wrap-2 .product-img span {
	font-size: 14px;
	font-weight: 500;
	position: absolute;
	top: 20px;
	right: 20px;
	/* right: unset;  */
}
.product-wrap-2 .product-img span.quick_view {
	right: unset;
}
.product-wrap-2 .product-img span.pink {
	color: #fa6bff;
}
.product-wrap-2 .product-img span.red {
	color: #ff3d2a;
}
.product-wrap-2 .product-img span.purple {
	color: #a749ff;
}
.product-wrap-2 .product-img .default-img {
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.product-wrap-2 .product-img .hover-img {
	position: absolute;
	top: 0px;
	visibility: hidden;
	left: 0px;
	width: 100%;
	opacity: 0;
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
}
.product-wrap-2 .product-img .product-action-2 {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
}
.product-wrap-2 .product-img .product-action-2 a,
.product-wrap-2 .product-img .product-action-2 span {
	display: inline-block;
	line-height: 1;
	width: 42px;
	height: 42px;
	line-height: 42px;
	font-size: 16px;
	background-color: #a749ff;
	color: #fff;
	text-align: center;
	border-radius: 50px;
	margin: 0 3px;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
}
.product-wrap-2 .product-img .product-action-2 a:hover,
.product-wrap-2 .product-img .product-action-2 span:hover {
	background-color: #fa6bff;
}
.product-wrap-2 .product-img .product-action-2.product-action-2-red a,
.product-wrap-2 .product-img .product-action-2.product-action-2-red span {
	background-color: #df262b;
}
.product-wrap-2 .product-img .product-action-2.product-action-2-red a:hover,
.product-wrap-2 .product-img .product-action-2.product-action-2-red span:hover {
	background-color: #a749ff;
}
.product-wrap-2 .product-content-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 20px 0 0;
}
.product-wrap-2 .product-content-2 .title-price-wrap-2 h3 {
	margin: 0 0 6px;
	line-height: 1;
}
.product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a,
.product-wrap-2 .product-content-2 .title-price-wrap-2 h3 span {
	cursor: pointer;
	font-size: 16px;
	color: #000000;
}
.product-wrap-2 .product-content-2 .title-price-wrap-2 h3 a:hover,
.product-wrap-2 .product-content-2 .title-price-wrap-2 h3 span:hover {
	color: #a749ff;
}
.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span {
	font-size: 15px;
	color: #000000;
	margin-right: 17px;
	position: relative;
}
.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span.old {
	color: #fa6bff;
	text-decoration: line-through;
}
.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span.old.red {
	color: #ff3d2a;
}
.product-wrap-2 .product-content-2 .title-price-wrap-2 .price-2 span::before {
	position: absolute;
	content: "";
	right: -13px;
	top: 10px;
	width: 6px;
	height: 2px;
	background-color: #a5a5a5;
}
.product-wrap-2
	.product-content-2
	.title-price-wrap-2
	.price-2
	span:last-child:before {
	display: none;
}
.product-wrap-2 .product-content-2 .pro-wishlist-2 a {
	font-size: 16px;
	color: #929292;
	margin: 4px 0 0 10px;
	display: inline-block;
}
.product-wrap-2 .product-content-2 .pro-wishlist-2 a:hover {
	color: #a749ff;
}
.product-wrap-2:hover .hover-img {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}
.product-wrap-2:hover .default-img {
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
	visibility: hidden;
}
.product-wrap-2:hover .product-action-2 a,
.product-wrap-2:hover .product-action-2 span {
	cursor: pointer;
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}
.product-wrap-2.pro-glaucous-color .product-img span.glaucous {
	color: #3d6882;
}
.product-wrap-2.pro-glaucous-color .product-img .product-action-2 a {
	background-color: #3d6882;
}
.product-wrap-2.pro-glaucous-color .product-img .product-action-2 a:hover {
	background-color: #fff;
	color: #000;
}
.product-wrap-2.pro-glaucous-color .product-content-2 .pro-wishlist-2 a:hover {
	color: #3d6882;
}
.product-wrap-2.pro-glaucous-color
	.product-content-2
	.title-price-wrap-2
	h3
	a:hover {
	color: #3d6882;
}
.product-wrap-2.pro-glaucous-color
	.product-content-2
	.title-price-wrap-2
	.price-2
	span.old {
	color: #3d6882;
}

/* product hm3 */
.section-padding-1 .container-fluid {
	padding: 0 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.section-padding-1 .container-fluid {
		padding: 0 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.section-padding-1 .container-fluid {
		padding: 0 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-padding-1 .container-fluid {
		padding: 0 40px;
	}
}
@media only screen and (max-width: 767px) {
	.section-padding-1 .container-fluid {
		padding: 0 15px;
	}
}

/* product hm4 */
.hm4-section-padding .container-fluid {
	padding: 0 70px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.hm4-section-padding .container-fluid {
		padding: 0 60px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.hm4-section-padding .container-fluid {
		padding: 0 30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.hm4-section-padding .container-fluid {
		padding: 0 15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.hm4-section-padding .container-fluid {
		padding: 0 30px;
	}
}
@media only screen and (max-width: 767px) {
	.hm4-section-padding .container-fluid {
		padding: 0 15px;
	}
}

.toggle-item-active,
.toggle-item-active2,
.toggle-item-active3,
.toggle-item-active4,
.toggle-item-active5,
.toggle-item-active6 {
	display: none;
}

.view-more {
	display: block;
	text-align: center;
	width: 100%;
}
.view-more a {
	color: #555;
	font-weight: 500;
	display: inline-block;
	border-bottom: 1px solid #cccccc;
	line-height: 1;
	padding-bottom: 2px;
	text-align: center;
}
.view-more a:hover {
	color: #a749ff;
	border-bottom: 1px solid #a749ff;
}
@media only screen and (max-width: 767px) {
	.view-more.mt-20 {
		margin-top: 0;
	}
}

/* product hm5 */
.hm5-section-padding .container-fluid {
	padding-right: 0px;
	padding-left: 0px;
}
@media only screen and (max-width: 767px) {
	.hm5-section-padding .container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.hm5-section-padding .row {
	margin-right: -10px;
	margin-left: -10px;
}
@media only screen and (max-width: 767px) {
	.hm5-section-padding .row {
		margin-right: -15px;
		margin-left: -15px;
	}
}

.hm5-section-padding div[class^="col-"] {
	padding-left: 10px;
	padding-right: 10px;
}
@media only screen and (max-width: 767px) {
	.hm5-section-padding div[class^="col-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.product-wrap-3 {
	position: relative;
}
.product-wrap-3 .product-img {
	position: relative;
}
.product-wrap-3 .product-img a img {
	width: 100%;
}
.product-wrap-3 .product-img > span {
	position: absolute;
	top: 12px;
	right: 12px;
	font-size: 13px;
	color: #fff;
	display: inline-block;
	line-height: 1;
	padding: 3px 11px;
	border-radius: 3px;
	font-weight: 500;
	z-index: 99;
}
.product-wrap-3 .product-img > span.pink {
	background-color: #fa6bff;
}
.product-wrap-3 .product-img > span.purple {
	background-color: #a749ff;
}
.product-wrap-3 .product-content-3-wrap {
	background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	width: 90%;
	height: 90%;
	left: 5%;
	right: 5%;
	bottom: 5%;
	top: 5%;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	z-index: 8;
	-webkit-transform: scale(0.85);
	-ms-transform: scale(0.85);
	transform: scale(0.85);
}
.product-wrap-3 .product-content-3-wrap .product-content-3 {
	left: 50%;
	position: absolute;
	text-align: center;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100%;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.product-wrap-3 .product-content-3-wrap .product-content-3 .product-title h3 {
	font-size: 18px;
	margin: 0;
	line-height: 1;
}
.product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 {
	margin: 10px 0 20px;
}
.product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 span {
	font-size: 16px;
	color: #000;
	font-weight: 500;
	margin: 0 9px;
	position: relative;
}
.product-wrap-3
	.product-content-3-wrap
	.product-content-3
	.price-3
	span::before {
	position: absolute;
	content: "";
	right: -13px;
	top: 10px;
	width: 7px;
	height: 2px;
	background-color: #000;
}
.product-wrap-3
	.product-content-3-wrap
	.product-content-3
	.price-3
	span:last-child::before {
	display: none;
}
.product-wrap-3 .product-content-3-wrap .product-content-3 .price-3 span.old {
	color: #8e8e8e;
	text-decoration: line-through;
}
.product-wrap-3 .product-content-3-wrap .product-content-3 .product-action-3 a {
	display: inline-block;
	line-height: 1;
	width: 42px;
	height: 42px;
	line-height: 42px;
	font-size: 16px;
	background-color: #a749ff;
	color: #fff;
	text-align: center;
	border-radius: 50px;
	margin: 0 3px;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.product-wrap-3
	.product-content-3-wrap
	.product-content-3
	.product-action-3
	a:hover {
	background-color: #fa6bff;
}
.product-wrap-3:hover .product-content-3-wrap {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

/* product hm6 */
.hm6-section-padding .container-fluid {
	padding-right: 70px;
	padding-left: 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.hm6-section-padding .container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.hm6-section-padding .container-fluid {
		padding-right: 30px;
		padding-left: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.hm6-section-padding .container-fluid {
		padding-right: 40px;
		padding-left: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.hm6-section-padding .container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}
}

.hm6-section-padding .row {
	margin-right: -10px;
	margin-left: -10px;
}
@media only screen and (max-width: 767px) {
	.hm6-section-padding .row {
		margin-right: -15px;
		margin-left: -15px;
	}
}

.hm6-section-padding div[class^="col-"] {
	padding-left: 10px;
	padding-right: 10px;
}
@media only screen and (max-width: 767px) {
	.hm6-section-padding div[class^="col-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.product-wrap-4 {
	position: relative;
	overflow: hidden;
}
.product-wrap-4 a img {
	width: 100%;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.product-wrap-4 .position-1 {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	bottom: 75px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.product-wrap-4 .position-1 {
		bottom: 40px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.product-wrap-4 .position-1 {
		bottom: 40px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-wrap-4 .position-1 {
		bottom: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-wrap-4 .position-1 {
		bottom: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.product-wrap-4 .position-1 {
		bottom: 30px;
	}
}
.product-wrap-4 .position-2 {
	position: absolute;
	left: 60px;
	bottom: 55px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.product-wrap-4 .position-2 {
		bottom: 40px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.product-wrap-4 .position-2 {
		bottom: 40px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-wrap-4 .position-2 {
		bottom: 30px;
		left: 40px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-wrap-4 .position-2 {
		bottom: 30px;
		left: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.product-wrap-4 .position-2 {
		bottom: 30px;
		left: 20px;
	}
}
.product-wrap-4 .position-3 {
	position: absolute;
	top: 51px;
	left: 0;
	right: 0;
	text-align: center;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.product-wrap-4 .position-3 {
		top: 32px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.product-wrap-4 .position-3 {
		top: 32px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-wrap-4 .position-3 {
		top: 22px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-wrap-4 .position-3 {
		top: 22px;
	}
}
@media only screen and (max-width: 767px) {
	.product-wrap-4 .position-3 {
		top: 22px;
	}
}
.product-wrap-4 .product-content-4 h4 {
	font-size: 30px;
	margin: 0 0 12px;
	line-height: 38px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.product-wrap-4 .product-content-4 h4 {
		font-size: 25px;
		line-height: 30px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.product-wrap-4 .product-content-4 h4 {
		font-size: 25px;
		line-height: 30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-wrap-4 .product-content-4 h4 {
		font-size: 22px;
		line-height: 27px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-wrap-4 .product-content-4 h4 {
		font-size: 20px;
		line-height: 23px;
	}
}
@media only screen and (max-width: 767px) {
	.product-wrap-4 .product-content-4 h4 {
		font-size: 20px;
		line-height: 23px;
	}
}
.product-wrap-4 .product-content-4 .price-4 span {
	font-size: 18px;
	color: #000000;
	position: relative;
	margin: 0 24px 0 0;
}
.product-wrap-4 .product-content-4 .price-4 span:before {
	position: absolute;
	content: "";
	right: -17px;
	top: 12px;
	width: 7px;
	height: 2px;
	background-color: #6b6264;
}
.product-wrap-4 .product-content-4 .price-4 span:last-child:before {
	display: none;
}
.product-wrap-4 .product-content-4 .price-4 span.old {
	color: #fa6bff;
	text-decoration: line-through;
}
.product-wrap-4 .product-content-4 .price-4.price-4-center span {
	margin: 0 12px;
}
.product-wrap-4:hover a img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.width-50-percent {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.width-30-percent {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	max-width: 30%;
}

/* product home 8*/
.collection-product .collection-img {
	overflow: hidden;
}
.collection-product .collection-img a img {
	width: 100%;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.collection-product .collection-content {
	margin: 23px 0 0;
}
.collection-product .collection-content span {
	font-weight: 500;
}
.collection-product .collection-content h4 {
	margin: 4px 0 0;
	line-height: 1;
}
.collection-product .collection-content h4 a {
	font-size: 16px;
	color: #000000;
	font-weight: 500;
}
.collection-product .collection-content h4 a:hover {
	color: #a749ff;
}

.collection-product:hover a img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.product-wrap-5 {
	position: relative;
}
.product-wrap-5 .product-img {
	position: relative;
}
.product-wrap-5 .product-img a img {
	width: 100%;
}
.product-wrap-5 .product-img span {
	position: absolute;
	top: 12px;
	right: 12px;
	font-size: 13px;
	color: #fff;
	display: inline-block;
	line-height: 1;
	padding: 3px 11px;
	border-radius: 3px;
	font-weight: 500;
}
.product-wrap-5 .product-img span.purple {
	background-color: #a749ff;
}
.product-wrap-5 .product-img span.pink {
	background-color: #fa6bff;
}
.product-wrap-5 .product-action-4 {
	background: none repeat scroll 0 0 #a749ff;
	bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	left: 0;
	opacity: 1;
	padding: 10px;
	position: absolute;
	text-align: center;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	width: 100%;
	z-index: 999;
}
.product-wrap-5 .product-action-4 .pro-same-action {
	margin: 0 2px;
}
.product-wrap-5 .product-action-4 .pro-same-action a {
	display: inline-block;
	line-height: 1;
	width: 35px;
	height: 35px;
	line-height: 36px;
	font-size: 16px;
	background-color: #fff;
	color: #000;
	text-align: center;
	border-radius: 50px;
	margin: 0 3px;
}
.product-wrap-5 .product-action-4 .pro-same-action a:hover {
	background-color: #fa6bff;
	color: #fff;
}
.product-wrap-5 .product-content-5 {
	margin-top: 28px;
}
.product-wrap-5 .product-content-5 h3 {
	font-size: 16px;
	margin: 0 0 5px;
	line-height: 17px;
}
.product-wrap-5 .product-content-5 h3 a {
	color: #000;
}
.product-wrap-5 .product-content-5 h3 a:hover {
	color: #a749ff;
}
.product-wrap-5 .product-content-5 .price-5 span {
	font-size: 15px;
	color: #000000;
	margin: 0 8px;
	position: relative;
}
.product-wrap-5 .product-content-5 .price-5 span:before {
	position: absolute;
	content: "";
	right: -13px;
	top: 10px;
	width: 6px;
	height: 2px;
	background-color: #a5a5a5;
}
.product-wrap-5 .product-content-5 .price-5 span:last-child:before {
	display: none;
}
.product-wrap-5 .product-content-5 .price-5 span.old {
	color: #fa6bff;
	text-decoration: line-through;
}
.product-wrap-5:hover .product-action-4 {
	opacity: 1;
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
}

/* product home 9*/
.collection-wrap-2 {
	margin-top: -175px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.collection-wrap-2 {
		margin-top: -97px;
	}
}
@media only screen and (max-width: 767px) {
	.collection-wrap-2 {
		margin-top: -80px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.collection-wrap-2 {
		margin-top: -70px;
	}
}

.collection-product-2 {
	position: relative;
	overflow: hidden;
}
.collection-product-2 a img {
	width: 100%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}
.collection-product-2 .collection-content-2 {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	bottom: 30px;
}
.collection-product-2 .collection-content-2 span {
	font-size: 14px;
	font-weight: 500;
}
.collection-product-2 .collection-content-2 h4 {
	font-size: 16px;
	font-weight: 500;
	margin: 5px 0 0;
	line-height: 21px;
}
.collection-product-2:hover a img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

/* product hm9 */
.hm9-section-padding .container-fluid {
	padding-right: 70px;
	padding-left: 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.hm9-section-padding .container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.hm9-section-padding .container-fluid {
		padding-right: 30px;
		padding-left: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.hm9-section-padding .container-fluid {
		padding-right: 40px;
		padding-left: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.hm9-section-padding .container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.hm9-section-padding .container-fluid {
		padding-right: 30px;
		padding-left: 30px;
	}
}

.hm9-section-padding .custom-row-4 {
	margin-right: -10px;
	margin-left: -10px;
}
@media only screen and (max-width: 767px) {
	.hm9-section-padding .custom-row-4 {
		margin-right: -15px;
		margin-left: -15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.hm9-section-padding .custom-row-4 {
		margin-right: -10px;
		margin-left: -10px;
	}
}

.hm9-section-padding div[class^="custom2-"] {
	padding-left: 10px;
	padding-right: 10px;
}
@media only screen and (max-width: 767px) {
	.hm9-section-padding div[class^="custom2-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.hm9-section-padding div[class^="custom2-"] {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.custom-row-4 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.custom2-col-5 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 20%;
	max-width: 20%;
	position: relative;
	width: 100%;
	min-height: 1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.custom2-col-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.custom2-col-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333%;
		flex: 0 0 33.333%;
		max-width: 33.333%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.custom2-col-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}
@media only screen and (max-width: 767px) {
	.custom2-col-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.custom2-col-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}

/*------- home 14 ----------*/
.product-top-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@media only screen and (max-width: 767px) {
	.product-top-bar {
		display: block;
		-webkit-box-pack: inherit;
		-ms-flex-pack: inherit;
		justify-content: inherit;
		text-align: center;
	}
}

.product-tab-list-3 {
	margin: 4px 0 0;
	position: relative;
	z-index: 99;
}
@media only screen and (max-width: 767px) {
	.product-tab-list-3 {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 15px 0 0;
	}
}
.product-tab-list-3 a {
	margin-left: 24px;
}
.product-tab-list-3 a.active h4 {
	color: #242424;
}
.product-tab-list-3 a:first-child {
	margin-left: 45px;
}
@media only screen and (max-width: 767px) {
	.product-tab-list-3 a:first-child {
		margin-left: 0px;
	}
}
.product-tab-list-3 a h4 {
	font-size: 14px;
	color: #878787;
	margin: 0;
	line-height: 1;
}

.product-slider-active .owl-nav {
	position: absolute;
	right: 0;
	top: -85px;
}
@media only screen and (max-width: 767px) {
	.product-slider-active .owl-nav {
		right: 0;
		left: 0;
		top: -45px;
		text-align: center;
	}
}
.product-slider-active .owl-nav div {
	display: inline-block;
	width: 35px;
	height: 35px;
	font-size: 18px;
	line-height: 31px;
	text-align: center;
	border-radius: 5px;
	color: #c6c6c6;
	border: 1px solid #d2d2d2;
	background-color: #f9f9f9;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 767px) {
	.product-slider-active .owl-nav div {
		width: 25px;
		height: 25px;
		font-size: 15px;
		line-height: 23px;
	}
}
.product-slider-active .owl-nav div:hover {
	color: #fff;
	border: 1px solid #ff3d2a;
	background-color: #ff3d2a;
}
.product-slider-active .owl-nav div.owl-next {
	margin-left: 3px;
}

.pro-view-more {
	margin-top: 30px;
}
@media only screen and (max-width: 767px) {
	.pro-view-more {
		margin-top: 0px;
	}
}
.pro-view-more a {
	display: inline-block;
	font-size: 16px;
	color: #828282;
	text-transform: uppercase;
	line-height: 1;
	padding: 20px 35px 19px;
	border: 1px solid #d2d2d2;
}
.pro-view-more a i {
	margin-right: 10px;
}
.pro-view-more a:hover {
	background-color: #df262b;
	color: #fff;
	border: 1px solid #df262b;
}

.bg-png-1 {
	position: absolute;
	top: 0;
	left: 78px;
}
@media only screen and (max-width: 767px) {
	.bg-png-1 {
		display: none;
	}
}

.funfact-area {
	position: relative;
}

.bg-png-2 {
	position: absolute;
	top: 0;
	right: 550px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.bg-png-2 {
		right: 300px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.bg-png-2 {
		right: 250px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.bg-png-2 {
		right: 150px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.bg-png-2 {
		right: 120px;
	}
}
@media only screen and (max-width: 767px) {
	.bg-png-2 {
		display: none;
	}
}

.blog-wrap .blog-img {
	position: relative;
	overflow: hidden;
}
.blog-wrap .blog-img img {
  cursor: pointer;
	width: 100%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}
.blog-wrap .blog-img span {
	position: absolute;
	top: 10px;
	left: 10px;
	display: block;
	line-height: 1;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	padding: 2px 10px 5px;
}
.blog-wrap .blog-img span.pink {
	background-color: #fa6bff;
}
.blog-wrap .blog-img span.purple {
	background-color: #a749ff;
}

.blog-wrap .blog-content-wrap {
	text-align: center;
}
.blog-wrap .blog-content-wrap .blog-content {
	display: inline-block;
	background-color: #fff;
	padding: 14px 44px;
	margin: -64px 0 0;
	z-index: 99;
	position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.blog-wrap .blog-content-wrap .blog-content {
		padding: 14px 30px;
		margin: -50px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.blog-wrap .blog-content-wrap .blog-content {
		padding: 14px 30px;
		margin: -50px 0 0;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.blog-wrap .blog-content-wrap .blog-content {
		padding: 14px 15px;
	}
}
.blog-wrap .blog-content-wrap .blog-content h3 {
	cursor: pointer;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	margin: 0 0 5px;
	transition: all 0.3s ease-in-out;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.blog-wrap .blog-content-wrap .blog-content h3 {
		cursor: pointer;
		font-size: 18px;
		line-height: 27px;
		transition: all 0.3s ease-in-out;
	}
}

.blog-wrap .blog-content-wrap .blog-content h3:hover {
	color: #a749ff;
}
.blog-wrap .blog-content-wrap .blog-content span {
	color: #666;
	font-style: italic;
}
.blog-wrap .blog-content-wrap .blog-content span a,
.blog-wrap .blog-content-wrap .blog-content span span {
	cursor: pointer;
	color: #666;
}
.blog-wrap .blog-content-wrap .blog-content span a:hover,
.blog-wrap .blog-content-wrap .blog-content span span:hover {
	color: #a749ff;
}

.blog-wrap:hover .blog-img img {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

/* blog page */
.blog-wrap-2 {
	-webkit-box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
	box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
}
.blog-wrap-2 .blog-img-2 {
	overflow: hidden;
	position: relative;
}
.blog-wrap-2 .blog-img-2 a img,
.blog-wrap-2 .blog-img-2 img {
	cursor: pointer;
	width: 100%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}
.blog-wrap-2 .blog-img-2 .video-icon {
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.blog-wrap-2 .blog-img-2 .video-icon a {
	background-color: #ff0000;
	border-radius: 50px;
	color: #fff;
	display: inline-block;
	font-size: 25px;
	height: 60px;
	line-height: 61px;
	width: 60px;
}
.blog-wrap-2 .blog-img-2 .video-icon a:hover {
	background-color: #a749ff;
}
.blog-wrap-2 .blog-content-2 {
	padding: 25px 30px 34px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.blog-wrap-2 .blog-content-2 {
		padding: 25px 20px 34px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.blog-wrap-2 .blog-content-2 {
		padding: 25px 20px 34px;
	}
}
@media only screen and (max-width: 767px) {
	.blog-wrap-2 .blog-content-2 {
		padding: 20px 15px 29px;
	}
}
.blog-wrap-2 .blog-content-2 .blog-meta-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li {
	color: #333;
	display: inline-block;
	font-size: 14px;
	letter-spacing: 1px;
	list-style: outside none none;
	margin: 0 17px 0 0;
	position: relative;
	text-transform: uppercase;
	display: inline-block;
	line-height: 1;
}
.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li::before {
	background-color: #555;
	content: "";
	height: 2px;
	position: absolute;
	right: -13px;
	top: 6px;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 5px;
}
.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li:last-child::before {
	display: none;
}
.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li a,
.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li span {
	cursor: pointer;
	color: #555;
}
.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li a:hover,
.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li span:hover {
	color: #a749ff;
}
.blog-wrap-2 .blog-content-2 h4 {
	font-size: 22px;
	font-weight: 500;
	margin: 10px 0 20px;
	color: #333;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.blog-wrap-2 .blog-content-2 h4 {
		font-size: 19px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.blog-wrap-2 .blog-content-2 h4 {
		font-size: 19px;
	}
}
@media only screen and (max-width: 767px) {
	.blog-wrap-2 .blog-content-2 h4 {
		font-size: 17px;
		margin: 10px 0 12px;
	}
}
.blog-wrap-2 .blog-content-2 h4 a,
.blog-wrap-2 .blog-content-2 h4 {
	cursor: pointer;
	text-transform: capitalize;
	color: #333;
}
.blog-wrap-2 .blog-content-2 h4 a:hover,
.blog-wrap-2 .blog-content-2 h4:hover {
	color: #a749ff;
}
.blog-wrap-2 .blog-content-2 p {
	margin: 0;
	color: #666;
	text-align: justify;
}
.blog-wrap-2 .blog-content-2 .blog-share-comment {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 22px 0 0;
}
@media only screen and (max-width: 767px) {
	.blog-wrap-2 .blog-content-2 .blog-share-comment {
		margin: 14px 0 0;
	}
}
.blog-wrap-2 .blog-content-2 .blog-share-comment .blog-btn-2 a,
.blog-wrap-2 .blog-content-2 .blog-share-comment .blog-btn-2 {
	cursor: pointer;
	color: #333;
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 0.2px;
	margin-top: 4px;
	text-transform: capitalize;
}
.blog-wrap-2 .blog-content-2 .blog-share-comment .blog-btn-2 a:hover,
.blog-wrap-2 .blog-content-2 .blog-share-comment .blog-btn-2:hover {
	color: #a749ff;
}
.blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share span {
	color: #333;
	display: inline-block;
	float: left;
	font-size: 15px;
	margin-right: 12px;
	margin-top: 3px;
	text-transform: capitalize;
}
.blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social {
	display: inline-block;
	float: left;
}
@media only screen and (max-width: 767px) {
	.blog-wrap-2 .blog-content-2 .blog-share-comment .blog-share .share-social {
		margin-top: 4px;
	}
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li {
	display: inline-block;
	margin-right: 5px;
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li:last-child {
	margin-right: 0;
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li
	a {
	border-radius: 50px;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	height: 30px;
	line-height: 28px;
	text-align: center;
	width: 30px;
}
@media only screen and (max-width: 767px) {
	.blog-wrap-2
		.blog-content-2
		.blog-share-comment
		.blog-share
		.share-social
		ul
		li
		a {
		font-size: 12px;
		height: 25px;
		line-height: 23px;
		width: 25px;
	}
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li
	a.facebook {
	background-color: #3b5998;
	border: 1px solid #3b5998;
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li
	a.facebook:hover {
	background-color: transparent;
	color: #3b5998;
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li
	a.twitter {
	background-color: #55acee;
	border: 1px solid #55acee;
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li
	a.twitter:hover {
	background-color: transparent;
	color: #55acee;
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li
	a.instagram {
	background-color: #c32aa3;
	border: 1px solid #c32aa3;
}
.blog-wrap-2
	.blog-content-2
	.blog-share-comment
	.blog-share
	.share-social
	ul
	li
	a.instagram:hover {
	background-color: transparent;
	color: #c32aa3;
}
.blog-wrap-2:hover .blog-img-2 img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.nav-style-4.owl-carousel .owl-nav div {
	color: #fff;
	display: inline-block;
	font-size: 20px;
	left: 0px;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	text-align: center;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	height: 35px;
	width: 35px;
	line-height: 35px;
	background-color: #a749ff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.nav-style-4.owl-carousel .owl-nav div {
		font-size: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.nav-style-4.owl-carousel .owl-nav div {
		font-size: 30px;
	}
}
.nav-style-4.owl-carousel .owl-nav div:hover {
	color: #fff;
	background-color: #333;
}
.nav-style-4.owl-carousel .owl-nav div.owl-next {
	left: auto;
	right: 0px;
}

.blog-wrap-2:hover .nav-style-4.owl-carousel .owl-nav div {
	opacity: 1;
	visibility: visible;
}

/*-------- 7. Blog style  ----------*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.Blog-area .ml-20 {
		margin-left: 0;
	}
	.Blog-area .mr-20 {
		margin-right: 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.Blog-area .ml-20 {
		margin-left: 0;
	}
	.Blog-area .mr-20 {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.Blog-area .ml-20 {
		margin-left: 0;
	}
	.Blog-area .mr-20 {
		margin-right: 0;
	}
	.Blog-area .pro-pagination-style.mt-20 {
		margin-top: 0;
	}
}

@media only screen and (max-width: 767px) {
	.Blog-area.pb-100 {
		padding-bottom: 45px;
	}
}

.Blog-area.blog-no-sidebar .blog-content-2 h4 {
	font-size: 20px;
}
@media only screen and (max-width: 767px) {
	.Blog-area.blog-no-sidebar .blog-content-2 h4 {
		font-size: 17px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.Blog-area.blog-no-sidebar .blog-content-2 h4 {
		font-size: 20px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.Blog-area.blog-no-sidebar
		.blog-content-2
		.blog-share-comment
		.blog-share
		.share-social {
		margin-top: 4px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.Blog-area.blog-no-sidebar
		.blog-content-2
		.blog-share-comment
		.blog-share
		.share-social
		ul
		li {
		margin-right: 3px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.Blog-area.blog-no-sidebar
		.blog-content-2
		.blog-share-comment
		.blog-share
		.share-social
		ul
		li
		a {
		font-size: 12px;
		height: 25px;
		line-height: 23px;
		width: 25px;
	}
}

/*-------- 8. Footer style  -----*/
.copyright .footer-logo {
	margin-bottom: 12px;
	margin-top: 41px;
}
@media only screen and (max-width: 767px) {
	.copyright .footer-logo {
		margin-top: 0px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.copyright .footer-logo {
		margin-top: 0px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.copyright .footer-logo {
		margin-top: 28px;
	}
}

.copyright p {
	color: #313131;
	letter-spacing: 0.9px;
}
.copyright p a {
	color: #313131;
}
.copyright p a:hover {
	color: #a749ff;
}

.footer-widget .footer-title h3 {
	font-size: 16px;
	font-weight: 500;
	color: #313131;
	margin: 0 0 16px;
	line-height: 1;
}

.footer-widget .footer-list ul li {
	margin: 0 0 11px;
}
.footer-widget .footer-list ul li:last-child {
	margin: 0 0 0px;
}
.footer-widget .footer-list ul li a {
	color: #5d5d5d;
	letter-spacing: 0.3px;
}
.footer-widget .footer-list ul li a:hover {
	color: #a749ff;
}

.footer-widget .subscribe-style p {
	color: #7a7a7a;
	margin: 0 0 20px;
}

.footer-widget .subscribe-style .subscribe-form form input {
	background: transparent;
	font-size: 13px;
	color: #333;
	border: none;
	border-bottom: 2px solid #ebebeb;
	padding: 2px 10px 2px 0;
}

.footer-widget .subscribe-style .subscribe-form form .mc-news {
	display: none;
}

.footer-widget .subscribe-style .subscribe-form form .clear input {
	width: auto;
	height: auto;
	color: #313131;
	font-size: 14px;
	padding: 0 0 1px;
	line-height: 1;
	border-bottom: 2px solid #9b9b9c;
	text-transform: uppercase;
	margin: 13px 0 0;
}
.footer-widget .subscribe-style .subscribe-form form .clear input:hover {
	color: #a749ff;
	border-bottom: 2px solid #a749ff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.footer-widget.ml-95 {
		margin-left: 20px;
	}
	.footer-widget.ml-145 {
		margin-left: 50px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.footer-widget.ml-95 {
		margin-left: 0px;
	}
	.footer-widget.ml-145 {
		margin-left: 20px;
	}
	.footer-widget.ml-30 {
		margin-left: 0px;
	}
	.footer-widget.ml-ntv5 {
		margin-left: -50px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.footer-widget.ml-95 {
		margin-left: 0px;
	}
	.footer-widget.ml-145 {
		margin-left: 0px;
	}
	.footer-widget.ml-30 {
		margin-left: 0px;
	}
	.footer-widget.ml-ntv5 {
		margin-left: 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.footer-widget.ml-95 {
		margin-left: 0px;
	}
	.footer-widget.ml-145 {
		margin-left: 0px;
	}
	.footer-widget.ml-30 {
		margin-left: 0px;
	}
	.footer-widget.ml-ntv5 {
		margin-left: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.footer-widget.ml-95 {
		margin-left: 0px;
	}
	.footer-widget.ml-145 {
		margin-left: 0px;
	}
	.footer-widget.ml-30 {
		margin-left: 0px;
	}
	.footer-widget.ml-ntv5 {
		margin-left: 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.footer-widget.ml-30,
	.footer-widget.ml-50,
	.footer-widget.ml-75 {
		margin-left: 0;
	}
}

@media only screen and (max-width: 767px) {
	.footer-widget.ml-30,
	.footer-widget.ml-50,
	.footer-widget.ml-75 {
		margin-left: 0;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.footer-widget.ml-30,
	.footer-widget.ml-50,
	.footer-widget.ml-75 {
		margin-left: 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.footer-widget.ml-70 {
		margin-left: -130px;
	}
}

@media only screen and (max-width: 767px) {
	.footer-widget.ml-70 {
		margin-left: 0px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.footer-widget.ml-70 {
		margin-left: -90px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.footer-widget.ml-70 {
		margin-left: 0px;
	}
}

/* home 4 */
.hm4-footer-padding .container-fluid {
	padding: 0 60px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.hm4-footer-padding .container-fluid {
		padding: 0 60px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.hm4-footer-padding .container-fluid {
		padding: 0 30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.hm4-footer-padding .container-fluid {
		padding: 0 15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.hm4-footer-padding .container-fluid {
		padding: 0 30px;
	}
}
@media only screen and (max-width: 767px) {
	.hm4-footer-padding .container-fluid {
		padding: 0 15px;
	}
}

.footer-area {
	position: relative;
}
.footer-area.ml-10 {
	margin-left: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.footer-area.ml-70 {
		margin-left: 15px;
	}
	.footer-area.mr-70 {
		margin-right: 15px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.footer-area.ml-70 {
		margin-left: 30px;
	}
	.footer-area.mr-70 {
		margin-right: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.footer-area.ml-70 {
		margin-left: 40px;
	}
	.footer-area.mr-70 {
		margin-right: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.footer-area.ml-70 {
		margin-left: 15px;
	}
	.footer-area.mr-70 {
		margin-right: 15px;
	}
}

/* home 5 */
.footer-top {
	position: relative;
	z-index: 9;
}
.footer-top p {
	color: #fffefe;
	line-height: 28px;
	margin: 31px auto 33px;
	width: 55%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.footer-top p {
		width: 85%;
	}
}
@media only screen and (max-width: 767px) {
	.footer-top p {
		width: 100%;
	}
}
.footer-top .footer-social ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.footer-top .footer-social ul li {
	margin: 0 23px;
	position: relative;
	line-height: 1;
}
.footer-top .footer-social ul li:before {
	position: absolute;
	content: "";
	right: -30px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #7c7b7b;
	width: 16px;
	height: 2px;
}
.footer-top .footer-social ul li:last-child:before {
	display: none;
}
.footer-top .footer-social ul li a {
	color: #e5e5e5;
	font-size: 16px;
}
.footer-top .footer-social ul li a:hover {
	color: #a749ff;
}
.footer-top .footer-social.footer-social-white ul li:before {
	background-color: #fff;
}
@media only screen and (max-width: 767px) {
	.footer-top.pb-60 {
		padding-bottom: 60px;
	}
}

.footer-bottom {
	position: relative;
	z-index: 9;
}

.copyright-2 {
	padding-top: 20px;
	margin-top: 60px;
	border-top: 1px solid #2b2b2b;
}
.copyright-2 p {
	color: #fff;
	line-height: 1;
}
.copyright-2 p a {
	color: #fff;
}
.copyright-2 p a:hover {
	color: #a749ff;
}
.copyright-2.copyright-white-border {
	border-top: 1px solid #cccccc;
}
.copyright-2.copyright-gray p {
	color: #848484;
}
.copyright-2.copyright-gray p a {
	color: #848484;
	letter-spacing: 0.9px;
}
.copyright-2.copyright-gray p a:hover {
	color: #a749ff;
}

.footer-white .footer-top p {
	color: #000;
}

.footer-white .footer-top .footer-social ul li a {
	color: #000;
}
.footer-white .footer-top .footer-social ul li a:hover {
	color: #a749ff;
}

.footer-white .copyright-2 {
	padding-top: 20px;
	padding-bottom: 23px;
	margin-top: 0px;
	border-top: none;
}

.footer-border {
	border-top: 1px solid #e2e2e2;
}

/*----- 9. Banner style  -------*/
.single-banner {
	position: relative;
	overflow: hidden;
}
.single-banner a img {
	width: 100%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}
.single-banner:hover img {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}
.single-banner .banner-content {
	position: absolute;
	content: "";
	left: 36px;
	top: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content {
		left: 20px;
		top: 20px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content {
		left: 15px;
		top: 10px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content {
		left: 15px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
.single-banner .banner-content h3 {
	font-size: 36px;
	color: #915342;
	font-weight: 600;
	margin: 0;
	font-family: "Cormorant Garamond";
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content h3 {
		font-size: 27px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content h3 {
		font-size: 30px;
	}
}
.single-banner .banner-content h4 {
	font-size: 18px;
	color: #010101;
	margin: 3px 0 58px;
	font-weight: 600;
	font-family: "Cormorant Garamond";
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content h4 {
		margin: 3px 0 25px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content h4 {
		margin: 3px 0 15px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content h4 {
		margin: 3px 0 8px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content h4 {
		margin: 3px 0 20px;
	}
}
.single-banner .banner-content h4 span {
	color: #935644;
	font-size: 20px;
	margin: 0 0px 0 2px;
	display: inline-block;
	line-height: 1;
	position: relative;
	top: 2px;
}
.single-banner .banner-content a {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	border: 2px solid #97584a;
	color: #97584a;
	font-size: 14px;
	display: inline-block;
	text-align: center;
}
.single-banner .banner-content a i {
	line-height: 25px;
}
.single-banner .banner-content a:hover {
	color: #a749ff;
	border: 2px solid #a749ff;
}
.single-banner .banner-content.banner-pink a {
	border: 2px solid #ed59a0;
	color: #ed59a0;
}
.single-banner .banner-content.banner-pink a:hover {
	color: #a749ff;
	border: 2px solid #a749ff;
}
.single-banner.banner-green-color .banner-content h3 {
	color: #617d05;
}
.single-banner.banner-green-color .banner-content h4 {
	color: #010101;
}
.single-banner.banner-green-color .banner-content h4 span {
	color: #617d05;
}
.single-banner.banner-green-color .banner-content a {
	border: 2px solid #617d05;
	color: #617d05;
}
.single-banner.banner-green-color .banner-content a:hover {
	color: #aabb72;
	border: 2px solid #aabb72;
}
.single-banner.banner-red-color .banner-content h3 {
	color: #df262b;
}
.single-banner.banner-red-color .banner-content h4 {
	color: #010101;
}
.single-banner.banner-red-color .banner-content h4 span {
	color: #df262b;
}
.single-banner.banner-red-color .banner-content a {
	border: 2px solid #df262b;
	color: #df262b;
}
.single-banner.banner-red-color .banner-content a:hover {
	color: #dc6266;
	border: 2px solid #dc6266;
}
.single-banner.banner-shape {
	border: 4px solid #fff;
}
.single-banner .banner-position-hm15-1 {
	position: absolute;
	right: 40px;
	top: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-banner .banner-position-hm15-1 {
		top: 25px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-position-hm15-1 {
		top: 20px;
		right: 20px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-position-hm15-1 {
		top: 20px;
		right: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-position-hm15-1 {
		top: 15px;
		right: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-position-hm15-1 {
		top: 30px;
		right: 30px;
	}
}
.single-banner .banner-position-hm15-2 {
	position: absolute;
	left: 40px;
	top: 45px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-banner .banner-position-hm15-2 {
		top: 25px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-position-hm15-2 {
		top: 20px;
		left: 20px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-position-hm15-2 {
		top: 20px;
		left: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-position-hm15-2 {
		top: 15px;
		left: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-position-hm15-2 {
		top: 30px;
		left: 30px;
	}
}
.single-banner .banner-position-hm17-1 {
	position: absolute;
	right: 105px;
	bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-position-hm17-1 {
		right: 20px;
		bottom: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-position-hm17-1 {
		right: 20px;
		bottom: 20px;
	}
}
.single-banner .banner-content-3 h3 {
	font-weight: bold;
	font-size: 30px;
	margin: 0;
	line-height: 1;
	color: #111111;
	text-transform: uppercase;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-banner .banner-content-3 h3 {
		font-size: 25px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-3 h3 {
		font-size: 25px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-3 h3 {
		font-size: 22px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-3 h3 {
		font-size: 20px;
	}
}
.single-banner .banner-content-3 p {
	font-size: 18px;
	margin: 11px 0 20px;
	color: #010101;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-banner .banner-content-3 p {
		margin: 5px 0 12px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-3 p {
		margin: 5px 0 12px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-3 p {
		margin: 5px 0 10px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-3 p {
		margin: 5px 0 10px;
	}
}
.single-banner .banner-content-3 p span {
	color: #6eab49;
}
.single-banner .banner-content-3 > a {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	border: 2px solid #6eab49;
	color: #6eab49;
	font-size: 14px;
	display: inline-block;
	text-align: center;
}
.single-banner .banner-content-3 > a:hover {
	border: 2px solid #a749ff;
	color: #a749ff;
}
.single-banner .banner-content-3.pink-banner p {
	color: #010101;
}
.single-banner .banner-content-3.pink-banner p span {
	color: #ed59a0;
}
.single-banner .banner-content-3.pink-banner > a {
	border: 2px solid #ed59a0;
	color: #ed59a0;
}
.single-banner .banner-content-3.pink-banner > a:hover {
	border: 2px solid #a749ff;
	color: #a749ff;
}
.single-banner .banner-content-4 span {
	font-size: 18px;
	color: #6eab49;
}
.single-banner .banner-content-4 h2 {
	font-size: 55px;
	color: #f3993f;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1;
	margin: 15px 0 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.single-banner .banner-content-4 h2 {
		font-size: 40px;
		margin: 7px 0 10px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-4 h2 {
		font-size: 35px;
		margin: 7px 0 10px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-4 h2 {
		font-size: 45px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-4 h2 {
		font-size: 30px;
		margin: 7px 0 10px;
	}
}
.single-banner .banner-content-4 h5 {
	font-size: 24px;
	color: #000000;
	line-height: 1;
	margin: 0 0 36px;
	letter-spacing: 4.3px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-4 h5 {
		font-size: 20px;
		margin: 0 0 20px;
		letter-spacing: 2.3px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-4 h5 {
		font-size: 22px;
		letter-spacing: 3.3px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-4 h5 {
		font-size: 20px;
		margin: 0 0 20px;
		letter-spacing: 2.3px;
	}
}
.single-banner .banner-content-4 > a {
	font-size: 14px;
	color: #fff;
	display: inline-block;
	text-transform: uppercase;
	line-height: 1;
	padding: 13px 33px 13px;
	background-color: #6eab49;
}
.single-banner .banner-content-4 > a:hover {
	background-color: #a749ff;
}
.single-banner .banner-content-4.pink-banner span {
	color: #ed59a0;
}
.single-banner .banner-content-4.pink-banner h2 {
	color: #ed59a0;
}
.single-banner .banner-content-4.pink-banner > a {
	background-color: #ed59a0;
}
.single-banner .banner-content-4.pink-banner > a:hover {
	background-color: #a749ff;
}
.single-banner .banner-content-5 {
	position: absolute;
	left: 36px;
	top: 32px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-5 {
		left: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-5 {
		left: 15px;
		top: 15px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-5 {
		left: 15px;
		top: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-content-5 {
		left: 50px;
		top: 50px;
	}
}
.single-banner .banner-content-5 span {
	font-size: 24px;
	color: #e90042;
	display: block;
	line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-5 span {
		font-size: 20px;
	}
}
.single-banner .banner-content-5 h3 {
	font-family: "Cormorant Garamond";
	font-size: 36px;
	margin: 9px 0 62px;
	font-weight: 600;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-5 h3 {
		margin: 9px 0 25px;
		font-size: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-5 h3 {
		margin: 2px 0 12px;
		font-size: 25px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-5 h3 {
		margin: 9px 0 25px;
		font-size: 30px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-content-5 h3 {
		font-size: 36px;
		margin: 9px 0 40px;
	}
}
.single-banner .banner-content-5 a {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	border: 2px solid #e90042;
	color: #e90042;
	font-size: 14px;
	display: inline-block;
	text-align: center;
}
.single-banner .banner-content-5 a:hover {
	border: 2px solid #a749ff;
	color: #a749ff;
}
.single-banner .banner-content-6 {
	position: absolute;
	left: 30px;
	bottom: 30px;
}
.single-banner .banner-content-6 a {
	font-size: 14px;
	color: #fff;
	display: inline-block;
	text-transform: uppercase;
	line-height: 1;
	padding: 13px 33px 13px;
	background-color: #e90042;
}
.single-banner .banner-content-6 a:hover {
	background-color: #a749ff;
}
.single-banner .banner-content-7 {
	position: absolute;
	left: 45px;
	bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-7 {
		left: 20px;
		bottom: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-7 {
		left: 20px;
		bottom: 20px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-content-7 {
		left: 40px;
		bottom: 40px;
	}
}
.single-banner .banner-content-7 span {
	font-size: 24px;
	font-weight: 600;
	color: #e90042;
	display: block;
	line-height: 1;
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-7 span {
		font-size: 20px;
	}
}
.single-banner .banner-content-7 h2 {
	font-size: 72px;
	font-weight: 700;
	color: #c3c1c0;
	margin: 9px 0 33px;
	line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-7 h2 {
		font-size: 55px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-7 h2 {
		font-size: 40px;
		margin: 9px 0 20px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-7 h2 {
		font-size: 35px;
		margin: 9px 0 20px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-content-7 h2 {
		font-size: 42px;
		margin: 9px 0 35px;
	}
}
.single-banner .banner-content-7 > a {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	border: 2px solid #e90042;
	color: #e90042;
	font-size: 14px;
	display: inline-block;
	text-align: center;
}
.single-banner .banner-content-7 > a:hover {
	border: 2px solid #a749ff;
	color: #a749ff;
}
.single-banner .banner-content-8 {
	position: absolute;
	left: 45px;
	bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-8 {
		left: 20px;
		bottom: 20px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-8 {
		left: 20px;
		bottom: 20px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-content-8 {
		left: 30px;
		bottom: 30px;
	}
}
.single-banner .banner-content-8 span {
	font-size: 24px;
	color: #e90042;
	display: block;
	line-height: 1;
	text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-8 span {
		font-size: 17px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-8 span {
		font-size: 17px;
	}
}
.single-banner .banner-content-8 h2 {
	font-size: 64px;
	color: #000000;
	margin: 10px 0 4px;
	line-height: 50px;
	text-transform: uppercase;
	font-family: "Abril Fatface", cursive;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner .banner-content-8 h2 {
		font-size: 55px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-8 h2 {
		font-size: 38px;
		line-height: 30px;
		margin: 5px 0 4px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-8 h2 {
		font-size: 32px;
		line-height: 30px;
		margin: 5px 0 4px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-content-8 h2 {
		margin: 15px 0 5px;
	}
}
.single-banner .banner-content-8 p {
	font-size: 20px;
	color: #000000;
	margin: 0px 0 48px;
	line-height: 1;
	text-transform: uppercase;
	font-family: "Abril Fatface", cursive;
	letter-spacing: 6px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner .banner-content-8 p {
		letter-spacing: 5px;
		font-size: 20px;
		margin: 0px 0 27px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner .banner-content-8 p {
		letter-spacing: 5px;
		font-size: 17px;
		margin: 0px 0 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-banner .banner-content-8 p {
		margin: 0px 0 35px;
	}
}
.single-banner .banner-content-8 > a {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	border: 2px solid #e90042;
	color: #e90042;
	font-size: 14px;
	display: inline-block;
	text-align: center;
}
.single-banner .banner-content-8 > a:hover {
	border: 2px solid #a749ff;
	color: #a749ff;
}

/* banner hm4 */
.banner-area-2 .container-fluid {
	padding-right: 10px;
	padding-left: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-area-2 .container-fluid {
		padding-right: 30px;
		padding-left: 30px;
	}
}
@media only screen and (max-width: 767px) {
	.banner-area-2 .container-fluid {
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area-2.pb-85 {
		padding-bottom: 42px;
	}
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
	.banner-area-2.banner-area-2 .single-banner .banner-content {
		top: 15px;
		left: 15px;
	}
	.banner-area-2.banner-area-2 .single-banner .banner-content h3 {
		font-size: 25px;
	}
	.banner-area-2.banner-area-2 .single-banner .banner-content h4 {
		margin: 3px 0 10px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.banner-area-2.banner-area-2 .single-banner .banner-content {
		top: 15px;
		left: 15px;
	}
	.banner-area-2.banner-area-2 .single-banner .banner-content h3 {
		font-size: 25px;
	}
	.banner-area-2.banner-area-2 .single-banner .banner-content h4 {
		margin: 3px 0 10px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-area-2.banner-area-2 .single-banner .banner-content {
		top: 25px;
	}
	.banner-area-2.banner-area-2 .single-banner .banner-content h3 {
		font-size: 30px;
	}
	.banner-area-2.banner-area-2 .single-banner .banner-content h4 {
		margin: 3px 0 40px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-area-2.banner-area-2.pt-10 {
		padding-top: 30px;
	}
}

/* banner hm9 */
.single-banner-2 {
	position: relative;
	overflow: hidden;
}
.single-banner-2 a img {
	width: 100%;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}
.single-banner-2 .banner-content-2 {
	position: absolute;
	left: 40px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
	.single-banner-2 .banner-content-2 {
		left: 20px;
	}
}
.single-banner-2 .banner-content-2 h3 {
	font-size: 48px;
	color: #915342;
	margin: 0;
	font-family: "Cormorant Garamond";
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner-2 .banner-content-2 h3 {
		font-size: 35px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner-2 .banner-content-2 h3 {
		font-size: 33px;
	}
}
.single-banner-2 .banner-content-2 h4 {
	font-size: 24px;
	color: #010101;
	font-weight: 500;
	font-family: "Cormorant Garamond";
	margin: 22px 0 94px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-banner-2 .banner-content-2 h4 {
		margin: 22px 0 34px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-banner-2 .banner-content-2 h4 {
		margin: 15px 0 25px;
	}
}
@media only screen and (max-width: 767px) {
	.single-banner-2 .banner-content-2 h4 {
		margin: 10px 0 20px;
	}
}
.single-banner-2 .banner-content-2 h4 span {
	color: #935644;
}
.single-banner-2 .banner-content-2 a {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	border: 2px solid #97584a;
	color: #97584a;
	font-size: 14px;
	display: inline-block;
	text-align: center;
}
.single-banner-2 .banner-content-2 a:hover {
	color: #000;
	border: 2px solid #000;
}
.single-banner-2 .banner-content-2.jewellery-banner h3 {
	font-weight: 600;
}
.single-banner-2:hover a img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
	.banner-area.pt-80 {
		padding-top: 60px;
	}
	.save-money-area .pt-100 {
		padding-top: 50px;
	}
	.save-money-area .pb-100 {
		padding-bottom: 50px;
	}
}

.save-money-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 0 0 50px;
}
@media only screen and (max-width: 767px) {
	.save-money-content {
		display: block;
		margin: 0 20px 0 20px;
		text-align: center;
	}
}
.save-money-content h2 {
	font-size: 36px;
	color: #010101;
	font-weight: bold;
	margin: 0 50px 0 0;
	line-height: 48px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.save-money-content h2 {
		font-size: 30px;
		margin: 0 30px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.save-money-content h2 {
		font-size: 20px;
		margin: 0 0px 20px 0;
		line-height: 30px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.save-money-content h2 {
		font-size: 25px;
		margin: 0 0px 20px 0;
		line-height: 30px;
	}
}
.save-money-content .save-money-btn a {
	display: inline-block;
	font-size: 16px;
	color: #fff;
	background-color: #ed59a0;
	line-height: 1;
	padding: 13px 40px 15px;
}
.save-money-content .save-money-btn a:hover {
	background-color: #a749ff;
}

/*------ 10. Suscribe style  ------*/
.subscribe-style-2 h2 {
	font-size: 48px;
	color: #010101;
	margin: 0 0 23px;
}
@media only screen and (max-width: 767px) {
	.subscribe-style-2 h2 {
		font-size: 30px;
		margin: 0 0 12px;
	}
}

.subscribe-style-2 p {
	font-size: 16px;
	color: #696969;
	margin: 0 0 23px;
}

.subscribe-style-2 .subscribe-form-2 form input {
	background: transparent;
	border: none;
	height: 45px;
	padding: 2px 50px 2px 0;
	font-size: 14px;
	color: #333;
	border-bottom: 2px solid #e2e2e2;
}

.subscribe-style-2 .subscribe-form-2 form .mc-news {
	display: none;
}

.subscribe-style-2 .subscribe-form-2 form .mc-form {
	position: relative;
}

.subscribe-style-2 .subscribe-form-2 form .clear-2 {
	background: rgba(0, 0, 0, 0) url("../../assets/img/icon-img/suscribe.png")
		no-repeat scroll right 0 center;
	bottom: 0px;
	display: inline-block;
	position: absolute;
	right: 0px;
}
.subscribe-style-2 .subscribe-form-2 form .clear-2 input {
	border: medium none;
	padding: 0;
	text-indent: -99999px;
	width: 37px;
}

@media only screen and (max-width: 767px) {
	.subscribe-area-3.pb-100 {
		padding-bottom: 60px;
	}
}

.subscribe-style-3 h2 {
	font-size: 48px;
	color: #010101;
	margin: 0 0 23px;
}
@media only screen and (max-width: 767px) {
	.subscribe-style-3 h2 {
		font-size: 35px;
		margin: 0 0 12px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.subscribe-style-3 h2 {
		font-size: 40px;
	}
}

.subscribe-style-3 p {
	font-size: 16px;
	color: #696969;
	margin: 0;
	line-height: 1;
}

.subscribe-style-3 .subscribe-form-3 input {
	background: transparent;
	border: none;
	border-bottom: 2px solid #e2e2e2;
	text-align: center;
	color: #333;
	height: 46px;
}

.subscribe-style-3 .subscribe-form-3 .mc-news {
	display: none;
}

.subscribe-style-3 .subscribe-form-3 .clear-3 {
	margin-top: 40px;
}
.subscribe-style-3 .subscribe-form-3 .clear-3 input {
	width: auto;
	height: auto;
	color: #fff;
	background-color: #070508;
	line-height: 1;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 17px 55px;
	border-radius: 5px;
}
.subscribe-style-3 .subscribe-form-3 .clear-3 input:hover {
	background-color: #a749ff;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.red-subscribe input {
	background-color: #df262b;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.red-subscribe input:hover {
	background-color: #a749ff;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.green-subscribe input {
	background-color: #6eab49;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.green-subscribe input:hover {
	background-color: #a749ff;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.red-2-subscribe input {
	background-color: #e90042;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.red-2-subscribe input:hover {
	background-color: #a749ff;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.pink-subscribe input {
	background-color: #ed59a0;
}
.subscribe-style-3 .subscribe-form-3 .clear-3.pink-subscribe input:hover {
	background-color: #a749ff;
}

/*------- 11. About style ------ */
.welcome-content h5 {
	font-weight: 500;
	font-size: 16px;
	color: #666;
	margin: 0;
	letter-spacing: 0.3px;
}

.welcome-content h1 {
	font-size: 30px;
	font-weight: 500;
	letter-spacing: 0.3px;
	margin: 8px 0 30px;
	color: #433f3f;
	position: relative;
	display: inline-block;
	padding: 0 0 23px;
	line-height: 1;
}
.welcome-content h1:before {
	position: absolute;
	background-color: #070508;
	height: 3px;
	width: 70px;
	content: "";
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0 auto;
}

.welcome-content p {
	font-size: 16px;
	color: #4b4b4b;
	line-height: 30px;
	width: 66%;
	margin: 0 auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.welcome-content p {
		width: 80%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.welcome-content p {
		width: 95%;
	}
}
@media only screen and (max-width: 767px) {
	.welcome-content p {
		width: 100%;
		font-size: 15px;
	}
}

/* about page */
.single-count .count-icon i {
	font-size: 50px;
	line-height: 1;
	display: inline-block;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-count .count-icon i {
		font-size: 45px;
	}
}
@media only screen and (max-width: 767px) {
	.single-count .count-icon i {
		font-size: 45px;
	}
}

.single-count h2 {
	color: #a749ff;
	font-size: 40px;
	font-weight: bold;
	margin: 25px 0 12px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.single-count h2 {
		margin: 10px 0 8px;
		font-size: 35px;
	}
}
@media only screen and (max-width: 767px) {
	.single-count h2 {
		margin: 6px 0 8px;
		font-size: 35px;
	}
}

.single-count span {
	color: #333;
	font-size: 20px;
	text-transform: capitalize;
	font-weight: 500;
}

.team-wrapper {
	background-color: #f7f7f7;
}
.team-wrapper .team-img {
	position: relative;
}
.team-wrapper .team-img a img {
	width: 100%;
}
.team-wrapper .team-img .team-action {
	background: #fff none repeat scroll 0 0;
	left: 0;
	opacity: 0;
	padding: 10px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 70%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.6s ease 0s;
	-o-transition: all 0.6s ease 0s;
	transition: all 0.6s ease 0s;
	width: 100%;
	z-index: 5;
}
.team-wrapper .team-img .team-action a {
	border-radius: 50px;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	height: 30px;
	line-height: 28px;
	text-align: center;
	width: 30px;
	margin: 0 3px;
}
.team-wrapper .team-img .team-action a.facebook {
	border: 1px solid #3b5998;
	background-color: #3b5998;
}
.team-wrapper .team-img .team-action a.facebook:hover {
	background-color: transparent;
	color: #3b5998;
}
.team-wrapper .team-img .team-action a.twitter {
	border: 1px solid #55acee;
	background-color: #55acee;
}
.team-wrapper .team-img .team-action a.twitter:hover {
	background-color: transparent;
	color: #55acee;
}
.team-wrapper .team-img .team-action a.instagram {
	border: 1px solid #c32aa3;
	background-color: #c32aa3;
}
.team-wrapper .team-img .team-action a.instagram:hover {
	background-color: transparent;
	color: #c32aa3;
}
.team-wrapper .team-content {
	padding: 20px 10px 23px;
}
.team-wrapper .team-content h4 {
	font-size: 18px;
	font-weight: 500;
	margin: 0 0 3px;
	text-transform: capitalize;
}
.team-wrapper .team-content span {
	font-size: 15px;
	font-style: italic;
}
.team-wrapper:hover .team-action {
	top: 50%;
	opacity: 1;
}

.single-mission h3 {
	font-size: 25px;
	font-weight: 500;
	margin: 0 0 20px;
	text-transform: capitalize;
	line-height: 22px;
	color: #333;
}
@media only screen and (max-width: 767px) {
	.single-mission h3 {
		margin: 0 0 10px;
	}
}

.single-mission p {
	margin: 0;
}

@media only screen and (max-width: 767px) {
	.about-brand-logo.pb-100 {
		padding-bottom: 60px;
	}
}

/*----- 12. instagram style  -----*/
.single-instagram {
	overflow: hidden;
}
.single-instagram a img {
	width: 100%;
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.single-instagram:hover a img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

/*----- 13. Testimonial style  ------*/
.single-testimonial p {
	line-height: 32px;
	color: #575757;
	font-size: 16px;
	font-weight: 500;
	margin: 32px 0 0px;
	font-style: italic;
}
@media only screen and (max-width: 767px) {
	.single-testimonial p {
		font-size: 15px;
	}
}

.single-testimonial .client-info {
	margin: 20px 0 0;
}
.single-testimonial .client-info i {
	font-size: 26px;
	color: #666666;
}
.single-testimonial .client-info h5 {
	font-size: 14px;
	color: #010101;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	margin: 22px 0 3px;
}
.single-testimonial .client-info span {
	font-size: 14px;
	color: #010101;
	letter-spacing: 1.5px;
}

.testimonial-active.owl-carousel .owl-item img {
	display: inline-block;
	width: auto;
}

.testimonial-area:hover .nav-style-1.owl-carousel > .owl-nav div {
	opacity: 1;
	visibility: visible;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.testimonial-area.ml-70 {
		margin-left: 15px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.testimonial-area.ml-70 {
		margin-left: 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.testimonial-area.ml-70 {
		margin-left: 40px;
	}
}

@media only screen and (max-width: 767px) {
	.testimonial-area.ml-70 {
		margin-left: 15px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.testimonial-area.ml-70 {
		margin-left: 30px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.testimonial-area.mr-70 {
		margin-right: 15px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.testimonial-area.mr-70 {
		margin-right: 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.testimonial-area.mr-70 {
		margin-right: 40px;
	}
}

@media only screen and (max-width: 767px) {
	.testimonial-area.mr-70 {
		margin-right: 15px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.testimonial-area.mr-70 {
		margin-right: 30px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.testimonial-area.mt-195 {
		margin-top: 125px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.testimonial-area.mt-195 {
		margin-top: 0px;
		padding-top: 35px;
	}
}

@media only screen and (max-width: 767px) {
	.testimonial-area.mt-195 {
		margin-top: 0px;
		padding-top: 40px;
	}
}

.testimonial-img-2 {
	margin-top: -197px;
	overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.testimonial-img-2 {
		margin-top: -130px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.testimonial-img-2 {
		margin-top: 0px;
	}
}
@media only screen and (max-width: 767px) {
	.testimonial-img-2 {
		margin-top: 30px;
	}
}
.testimonial-img-2 img {
	width: 100%;
}

.single-testimonial-2 p {
	color: #fff;
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 32px;
	font-style: italic;
}

.single-testimonial-2 .client-info {
	margin: 18px 0 0;
}
.single-testimonial-2 .client-info i {
	color: #fff;
	font-size: 26px;
}
.single-testimonial-2 .client-info h5 {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	margin: 20px 0 6px;
	color: #fff;
	line-height: 1;
}
.single-testimonial-2 .client-info span {
	font-size: 14px;
	text-transform: capitalize;
	letter-spacing: 1.5px;
	margin: 0;
	color: #fff;
	line-height: 1;
}

.single-testimonial-2.testi-paragraph-mrg p {
	margin: 32px 0 0;
}

.testimonial-active-2 .owl-dots {
	text-align: center;
	margin: 15px 0 0;
}
.testimonial-active-2 .owl-dots .owl-dot {
	display: inline-block;
	margin: 0 5px;
}
.testimonial-active-2 .owl-dots .owl-dot span {
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background-color: #fff;
	border: 2px solid transparent;
	display: inline-block;
}
.testimonial-active-2 .owl-dots .owl-dot.active span {
	background-color: transparent;
	border: 2px solid #fff;
}

/*------- 14. Brand logo style  -------*/
.brand-logo-active.owl-carousel .owl-item img,
.brand-logo-active-2.owl-carousel .owl-item img {
	display: inline-block;
	width: auto;
}

.brand-logo-active .single-brand-logo,
.brand-logo-active .single-brand-logo2,
.brand-logo-active-2 .single-brand-logo,
.brand-logo-active-2 .single-brand-logo2 {
	text-align: center;
}
.brand-logo-active .single-brand-logo img,
.brand-logo-active .single-brand-logo2 img,
.brand-logo-active-2 .single-brand-logo img,
.brand-logo-active-2 .single-brand-logo2 img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	transition: all 0.5s ease 0s;
}
.brand-logo-active .single-brand-logo:hover img,
.brand-logo-active .single-brand-logo2:hover img,
.brand-logo-active-2 .single-brand-logo:hover img,
.brand-logo-active-2 .single-brand-logo2:hover img {
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
}

.brand-logo-wrap {
	padding: 100px 90px;
}
@media only screen and (max-width: 767px) {
	.brand-logo-wrap {
		padding: 50px 20px;
	}
}

/*------ 15. Timer style  -----*/
.funfact-area.bg-img {
	position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.funfact-area.bg-img {
		background-position: 15%;
	}
	.funfact-area.bg-img:before {
		content: "";
		background-color: #fff;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: absolute;
		opacity: 0.7;
	}
}
@media only screen and (max-width: 767px) {
	.funfact-area.bg-img {
		background-position: 15%;
	}
	.funfact-area.bg-img:before {
		content: "";
		background-color: #fff;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: absolute;
		opacity: 0.7;
	}
}

.funfact-content {
	position: relative;
	z-index: 9;
}
.funfact-content h2 {
	font-size: 48px;
	color: #010101;
	margin: 0;
}
@media only screen and (max-width: 767px) {
	.funfact-content h2 {
		font-size: 32px;
	}
}
.funfact-content .timer {
	text-align: center;
	margin: 65px 0 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.funfact-content .timer {
		margin: 44px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.funfact-content .timer {
		margin: 24px 0 0;
	}
}
.funfact-content .timer span {
	display: inline-block;
	font-size: 48px;
	color: #555;
	font-weight: 300;
	margin: 0 32px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.funfact-content .timer span {
		margin: 0 15px;
		font-size: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.funfact-content .timer span {
		margin: 0 5px;
		font-size: 22px;
	}
}
.funfact-content .timer span p {
	font-size: 18px;
	font-weight: 500;
	color: #555;
	text-transform: uppercase;
	margin: 34px 0 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.funfact-content .timer span p {
		margin: 24px 0 0;
	}
}
@media only screen and (max-width: 767px) {
	.funfact-content .timer span p {
		margin: 12px 0 0;
		font-size: 14px;
	}
}
.funfact-content .funfact-btn {
	margin-top: 43px;
}
@media only screen and (max-width: 767px) {
	.funfact-content .funfact-btn {
		margin-top: 23px;
	}
}
.funfact-content .funfact-btn a {
	display: inline-block;
	background-color: #000;
	color: #fff;
	line-height: 1;
	padding: 16px 56px;
	border: 1px solid transparent;
	z-index: 1;
}
.funfact-content .funfact-btn a:hover {
	border: 1px solid #a749ff;
}
.funfact-content .funfact-btn.funfact-btn-red a {
	background-color: #df262b;
}
.funfact-content .funfact-btn.funfact-btn-red a:hover {
	border: 1px solid #a749ff;
}
.funfact-content .funfact-btn.funfact-btn-green a {
	background-color: #6eab49;
}
.funfact-content .funfact-btn.funfact-btn-green a:hover {
	border: 1px solid #a749ff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.funfact-content.mt-90 {
		margin-top: 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.funfact-content.funfact-res h2 {
		font-size: 35px;
	}
	.funfact-content.funfact-res .timer {
		margin: 30px 0 0;
	}
	.funfact-content.funfact-res .timer span {
		font-size: 30px;
		margin: 0 9px;
	}
	.funfact-content.funfact-res .timer span p {
		font-size: 15px;
		margin: 14px 0 0;
	}
	.funfact-content.mt-90 {
		margin-top: 0;
	}
}
@media only screen and (max-width: 767px) {
	.funfact-content.mt-90 {
		margin-top: 30px;
	}
}

.gift-img {
	margin: 0 10px;
}
.gift-img a {
	display: block;
}
.gift-img a img {
	width: 100%;
}

.fruits-deal-img {
	margin: 0 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.fruits-deal-img {
		margin: 0px 50px 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.fruits-deal-img {
		margin: 0px 0px 0;
	}
}
@media only screen and (max-width: 767px) {
	.fruits-deal-img {
		margin: 30px 0px 0;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.fruits-deal-img {
		margin: 30px 30px 0;
	}
}
.fruits-deal-img a {
	display: block;
}
.fruits-deal-img a img {
	width: 100%;
}

/*------ 16. Breadcrumb style  --------*/
.breadcrumb-content ul li {
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	color: #5b5858;
	margin-right: 18px;
	text-transform: uppercase;
	position: relative;
}
.breadcrumb-content ul li::before {
	position: absolute;
	width: 18px;
	height: 1px;
	background-color: #5b5858;
	content: "";
	right: -21px;
	top: 12px;
	z-index: 99;
	-webkit-transform: rotate(115deg);
	-ms-transform: rotate(115deg);
	transform: rotate(115deg);
}
.breadcrumb-content ul li:last-child::before {
	display: none;
}
.breadcrumb-content ul li a {
	color: #5b5858;
}
.breadcrumb-content ul li a:hover {
	color: #a749ff;
}

/*---- 17. Sidebar style  ------*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-style.mr-30 {
		margin-right: 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.sidebar-style {
		margin-top: 30px;
	}
	.sidebar-style.mr-30 {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.sidebar-style {
		margin-top: 30px;
	}
	.sidebar-style.mr-30 {
		margin-right: 0;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-style.ml-30 {
		margin-left: 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.sidebar-style {
		margin-top: 30px;
	}
	.sidebar-style.ml-30 {
		margin-left: 0;
	}
}

@media only screen and (max-width: 767px) {
	.sidebar-style {
		margin-top: 30px;
	}
	.sidebar-style.ml-30 {
		margin-left: 0;
	}
}

.sidebar-widget h4.pro-sidebar-title {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	margin: 0;
}

.sidebar-widget .sidebar-widget-list ul li {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	padding: 0px 0 15px;
}
.sidebar-widget .sidebar-widget-list ul li:last-child {
	padding: 0px 0 0px;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left {
	position: relative;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	z-index: 999;
	width: auto;
}
.sidebar-widget
	.sidebar-widget-list
	ul
	li
	.sidebar-widget-list-left
	input:checked
	~ .checkmark {
	background-color: #fa6bff;
	border: 2px solid #fa6bff;
}
.sidebar-widget
	.sidebar-widget-list
	ul
	li
	.sidebar-widget-list-left
	input:checked
	~ .checkmark::after {
	display: block;
}
.sidebar-widget
	.sidebar-widget-list
	ul
	li
	.sidebar-widget-list-left
	.checkmark {
	position: absolute;
	top: 5px;
	left: 0;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border: 2px solid #888888;
	border-radius: 3px;
}
.sidebar-widget
	.sidebar-widget-list
	ul
	li
	.sidebar-widget-list-left
	.checkmark::after {
	content: "";
	position: absolute;
	display: none;
}
.sidebar-widget
	.sidebar-widget-list
	ul
	li
	.sidebar-widget-list-left
	.checkmark::after {
	left: 3px;
	top: 0px;
	width: 5px;
	height: 8px;
	border: solid white;
	border-top-width: medium;
	border-right-width: medium;
	border-bottom-width: medium;
	border-left-width: medium;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.sidebar-widget .sidebar-widget-list ul li a {
	margin-left: 30px;
	display: block;
}
.sidebar-widget .sidebar-widget-list ul li a span {
	color: #9a9a9a;
	font-weight: 500;
	background-color: #eeeeee;
	width: 30px;
	height: 20px;
	display: inline-block;
	text-align: center;
	line-height: 20px;
	border-radius: 50px;
	float: right;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.sidebar-widget .sidebar-widget-list ul li a:hover span {
	color: #fff;
	background-color: #a749ff;
}

.sidebar-widget .sidebar-widget-tag ul li {
	display: inline-block;
	margin: 0 10px 15px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-widget .sidebar-widget-tag ul li {
		margin: 0 5px 15px 0;
	}
}
.sidebar-widget .sidebar-widget-tag ul li a {
	color: #424242;
	display: inline-block;
	background-color: #f6f6f6;
	padding: 9px 17px 12px;
	line-height: 1;
	border-radius: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.sidebar-widget .sidebar-widget-tag ul li a {
		padding: 9px 13px 12px;
	}
}
.sidebar-widget .sidebar-widget-tag ul li a:hover {
	background-color: #a749ff;
	color: #fff;
}

.pro-sidebar-search .pro-sidebar-search-form {
	position: relative;
}
.pro-sidebar-search .pro-sidebar-search-form input {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #e6e6e6;
	color: #000;
	font-size: 14px;
	height: 43px;
	padding: 2px 55px 2px 18px;
}
.pro-sidebar-search .pro-sidebar-search-form button {
	background: transparent none repeat scroll 0 0;
	border-color: #a1a5aa;
	-webkit-border-image: none;
	-o-border-image: none;
	border-image: none;
	border-style: none none none solid;
	border-width: medium medium medium 1px;
	padding: 0 15px;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	cursor: pointer;
	color: #000;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	font-size: 20px;
}
.pro-sidebar-search .pro-sidebar-search-form button:hover {
	color: #a749ff;
}

.single-sidebar-blog {
	border-bottom: 1px solid #dee0e4;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: 0 0 20px;
	padding: 0 0 20px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.single-sidebar-blog:last-child {
	border-bottom: none;
}
.single-sidebar-blog .sidebar-blog-img {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 70px;
	flex: 0 0 70px;
	margin: 0 20px 0 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.single-sidebar-blog .sidebar-blog-img {
		margin: 0 8px 0 0;
	}
}
.single-sidebar-blog .sidebar-blog-img img {
	width: 100%;
}
.single-sidebar-blog .sidebar-blog-content span {
	color: #333;
	display: block;
	font-size: 12px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 400;
	line-height: 1;
}
.single-sidebar-blog .sidebar-blog-content h4 {
	font-size: 14px;
	margin: 5px 0 0;
}

/*---- 18. Shop page  -------*/
.shop-top-bar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media only screen and (max-width: 767px) {
	.shop-top-bar {
		display: block;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.shop-top-bar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
.shop-top-bar .select-shoing-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media only screen and (max-width: 767px) {
	.shop-top-bar .select-shoing-wrap {
		display: block;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.shop-top-bar .select-shoing-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
.shop-top-bar .select-shoing-wrap .shop-select {
	margin-right: 50px;
}
.shop-top-bar .select-shoing-wrap .shop-select select {
	font-size: 14px;
	color: #606060;
	-moz-appearance: none;
	-webkit-appearance: none;
	min-width: 143px;
	background: rgba(0, 0, 0, 0) url("../../assets/img/icon-img/select.png")
		no-repeat scroll right 0px center;
}
.shop-top-bar .select-shoing-wrap p {
	color: #606060;
	margin: 0;
}
@media only screen and (max-width: 767px) {
	.shop-top-bar .select-shoing-wrap p {
		margin: 5px 0 10px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.shop-top-bar .select-shoing-wrap p {
		margin: 0px;
	}
}
.shop-top-bar .shop-tab a {
	color: #606060;
	font-size: 18px;
	margin-left: 20px;
}
.shop-top-bar .shop-tab a:first-child {
	margin-left: 0;
}
.shop-top-bar .shop-tab a.active {
	color: #fa6bff;
}

.shop-list-wrap .product-wrap:hover .hover-img {
	opacity: inherit;
	visibility: inherit;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

@media only screen and (max-width: 767px) {
	.shop-list-wrap .shop-list-content {
		margin-top: 20px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.shop-list-wrap .shop-list-content {
		margin-top: 0px;
	}
}

.shop-list-wrap .shop-list-content h3 {
	font-size: 24px;
	color: #010101;
	margin: 0;
}
@media only screen and (max-width: 767px) {
	.shop-list-wrap .shop-list-content h3 {
		font-size: 20px;
		line-height: 20px;
	}
}
.shop-list-wrap .shop-list-content h3 a {
	color: #010101;
}
.shop-list-wrap .shop-list-content h3 a:hover {
	color: #a749ff;
}

.shop-list-wrap .shop-list-content .product-list-price {
	margin: 13px 0 22px;
}
@media only screen and (max-width: 767px) {
	.shop-list-wrap .shop-list-content .product-list-price {
		margin: 13px 0 13px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.shop-list-wrap .shop-list-content .product-list-price {
		margin: 13px 0 19px;
	}
}
.shop-list-wrap .shop-list-content .product-list-price span {
	color: #fe5252;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
}
.shop-list-wrap .shop-list-content .product-list-price span.old {
	text-decoration: line-through;
	font-size: 18px;
	color: #cfcfcf;
	margin-left: 15px;
}

.shop-list-wrap .shop-list-content .rating-review {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.shop-list-wrap .shop-list-content .rating-review .product-list-rating i {
	font-size: 17px;
	color: #5f5d5d;
	margin: 0 3px 0;
}
.shop-list-wrap
	.shop-list-content
	.rating-review
	.product-list-rating
	i.yellow {
	color: #ffa900;
}
.shop-list-wrap .shop-list-content .rating-review a {
	margin-left: 40px;
	position: relative;
	font-size: 15px;
	color: #ffcf75;
	line-height: 1;
}
.shop-list-wrap .shop-list-content .rating-review a:before {
	position: absolute;
	content: "";
	left: -21px;
	top: 0;
	background-color: #d1d1d1;
	width: 1px;
	height: 15px;
}

.shop-list-wrap .shop-list-content p {
	margin: 15px 0 40px;
	line-height: 28px;
	font-size: 15px;
	color: #8f8f8f;
}
@media only screen and (max-width: 767px) {
	.shop-list-wrap .shop-list-content p {
		margin: 12px 0 20px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.shop-list-wrap .shop-list-content p {
		margin: 12px 0 20px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.shop-list-wrap .shop-list-content p {
		margin: 15px 0 20px;
	}
}

.shop-list-wrap .shop-list-content .shop-list-btn a {
	font-size: 14px;
	color: #343538;
	font-weight: 500;
	text-transform: uppercase;
	display: inline-block;
	line-height: 1;
	background-color: #343538;
	color: #fff;
	padding: 14px 32px 15px;
	border: 1px solid transparent;
	z-index: 1;
}
@media only screen and (max-width: 767px) {
	.shop-list-wrap .shop-list-content .shop-list-btn a {
		padding: 10px 20px 11px;
		font-size: 13px;
	}
}
.shop-list-wrap .shop-list-content .shop-list-btn a:hover {
	border: 1px solid #a749ff;
}

.shop-list-wrap:hover .product-wrap .hover-img {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

@media only screen and (max-width: 767px) {
	.pro-pagination-style.mt-30 {
		margin-top: 10px;
	}
}

.pro-pagination-style ul li {
	display: inline-block;
	margin: 0 4px;
}
.pro-pagination-style ul li a {
	display: inline-block;
	width: 43px;
	height: 43px;
	text-align: center;
	line-height: 43px;
	font-size: 16px;
	border-radius: 100%;
	color: #a749ff;
	-webkit-box-shadow: 0 0px 12px 0.8px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0px 12px 0.8px rgba(0, 0, 0, 0.1);
}
.pro-pagination-style ul li a:hover {
	background-color: #a749ff;
	color: #fff;
}
.pro-pagination-style ul li a.active {
	background-color: #a749ff;
	color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.pro-pagination-style ul li a.active:hover {
	background-color: #333;
}
.pro-pagination-style ul li a.prev,
.pro-pagination-style ul li a.next {
	background-color: #f6f6f6;
	color: #a749ff;
	font-size: 17px;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.pro-pagination-style ul li a.prev:hover,
.pro-pagination-style ul li a.next:hover {
	background-color: #a749ff;
	color: #fff;
}

@media only screen and (max-width: 767px) {
	.shop-area.pb-100 {
		padding-bottom: 45px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
	.list2-col-style .shop-list-wrap .shop-list-content h3 {
		font-size: 20px;
		line-height: 20px;
	}
	.list2-col-style .shop-list-wrap .shop-list-content .product-list-price {
		margin: 13px 0 10px;
	}
	.list2-col-style .shop-list-wrap .shop-list-content p {
		margin: 6px 0 20px;
	}
	.list2-col-style .shop-list-wrap .shop-list-content .shop-list-btn a {
		padding: 10px 24px 11px;
		font-size: 13px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.list2-col-style .shop-list-wrap .shop-list-content h3 {
		font-size: 20px;
		line-height: 20px;
	}
	.list2-col-style .shop-list-wrap .shop-list-content .product-list-price {
		margin: 13px 0 10px;
	}
	.list2-col-style .shop-list-wrap .shop-list-content p {
		margin: 6px 0 20px;
	}
	.list2-col-style .shop-list-wrap .shop-list-content .shop-list-btn a {
		padding: 10px 20px 11px;
		font-size: 13px;
	}
}

.grid-sizer {
	width: 25%;
}

/*----- 19. Product details page  ---*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-details-img.mr-20 {
		margin-right: 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-img.mr-20 {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.product-details-img.mr-20 {
		margin-right: 0;
	}
}

.product-details-img .large-img-style {
	position: relative;
}
.product-details-img .large-img-style img {
	width: 100%;
}
.product-details-img .large-img-style span {
	position: absolute;
	top: 30px;
	left: 30px;
	font-size: 13px;
	color: #fff;
	display: inline-block;
	line-height: 1;
	padding: 3px 11px;
	border-radius: 3px;
	font-weight: 500;
	background-color: #fa6bff;
}
.product-details-img .large-img-style .img-popup-wrap {
	position: absolute;
	right: 30px;
	top: 30px;
	font-size: 30px;
	color: #000000;
}
.product-details-img .large-img-style .img-popup-wrap:hover {
	color: #a749ff;
}

.product-details-img .shop-details-tab {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: -72px;
	z-index: 9;
	position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-img .shop-details-tab {
		margin-top: -50px;
	}
}
@media only screen and (max-width: 767px) {
	.product-details-img .shop-details-tab {
		margin-top: -45px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.product-details-img .shop-details-tab {
		margin-top: -60px;
	}
}
.product-details-img .shop-details-tab a.shop-details-overly {
	position: relative;
}
.product-details-img .shop-details-tab a.shop-details-overly img {
	width: 144px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-img .shop-details-tab a.shop-details-overly img {
		width: 90px;
	}
}
@media only screen and (max-width: 767px) {
	.product-details-img .shop-details-tab a.shop-details-overly img {
		width: 85px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.product-details-img .shop-details-tab a.shop-details-overly img {
		width: 110px;
	}
}
.product-details-img .shop-details-tab a.shop-details-overly.active:before {
	opacity: 0.6;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	content: "";
}

.description-review-topbar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-bottom: 1px solid #d7d7d7;
}
.description-review-topbar a {
	background-color: transparent;
	border-bottom: 3px solid transparent;
	font-size: 24px;
	font-weight: 500;
	line-height: 1;
	margin: 0 15px 0;
	padding: 0px 0px 13px;
	color: #666;
}
.description-review-topbar a.active {
	color: #000000;
}
@media only screen and (max-width: 767px) {
	.description-review-topbar a {
		margin: 0 4px 15px;
		font-size: 15px;
		padding: 0px 0px 8px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.description-review-topbar a {
		margin: 0 8px 0px 0;
		font-size: 18px;
		padding: 0px 5px 0px;
	}
}
.description-review-topbar a.active {
	border-bottom: 2px solid #000000;
	background-color: transparent;
}

.description-review-bottom {
	padding: 33px 0 0;
}
.description-review-bottom .product-description-wrapper p {
	margin: 0 0 8px;
	font-size: 15px;
	line-height: 28px;
	color: #333;
	width: 84%;
}
@media only screen and (max-width: 767px) {
	.description-review-bottom .product-description-wrapper p {
		width: 100%;
	}
}
.description-review-bottom .product-description-wrapper p:last-child {
	margin: 0 0 0px;
}
.description-review-bottom .product-anotherinfo-wrapper ul li {
	color: #333;
	font-size: 14px;
	list-style: outside none none;
	margin: 0 0 13px;
}
.description-review-bottom .product-anotherinfo-wrapper ul li span {
	color: #000;
	display: inline-block;
	font-weight: 500;
	margin: 0 26px 0 0;
	min-width: 85px;
}

.single-review {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0 0 50px;
}
@media only screen and (max-width: 767px) {
	.single-review {
		display: block;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-review {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
.single-review .review-img {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 90px;
	flex: 0 0 90px;
	margin: 0 15px 0 0;
}
@media only screen and (max-width: 767px) {
	.single-review .review-img {
		margin: 0 0px 20px 0;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.single-review .review-img {
		margin: 0 15px 0px 0;
	}
}
.single-review .review-top-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 0 10px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.single-review .review-top-wrap .review-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.single-review .review-top-wrap .review-left .review-name {
	margin-right: 25px;
}
.single-review .review-top-wrap .review-left .review-name h4 {
	font-size: 16px;
	margin: 0;
	line-height: 1;
	font-weight: 500;
}
.single-review .review-top-wrap .review-left .review-rating {
	line-height: 1;
}
.single-review .review-top-wrap .review-left .review-rating i {
	font-size: 12px;
	margin-right: 1px;
	color: #ffa900;
}
.single-review .review-top-wrap .review-btn a {
	font-weight: 500;
	line-height: 1;
}
.single-review .review-bottom p {
	margin: 0;
	width: 93%;
}

.single-review.child-review {
	margin-left: 70px;
	margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
	.single-review.child-review {
		margin-left: 0px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.ratting-form-wrapper.pl-50 {
		padding-left: 0;
		margin-top: 50px;
	}
}

@media only screen and (max-width: 767px) {
	.ratting-form-wrapper.pl-50 {
		padding-left: 0;
		margin-top: 50px;
	}
}

.ratting-form-wrapper h3 {
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
}

.ratting-form-wrapper .ratting-form form .star-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 6px 0 20px;
}
.ratting-form-wrapper .ratting-form form .star-box span {
	margin: 0 15px 0 0;
}
.ratting-form-wrapper .ratting-form form .star-box .ratting-star {
	font-size: 12px;
	color: #ffa900;
	margin: 2px 0 0;
}

.ratting-form-wrapper .ratting-form form .rating-form-style input,
.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
	padding: 2px 10px 2px 20px;
	background: transparent;
	border: 1px solid #e6e6e6;
	color: #333;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
	height: 180px;
	padding: 20px 10px 2px 20px;
	margin-bottom: 20px;
}

.ratting-form-wrapper .ratting-form form .form-submit input {
	width: auto;
	padding: 12px 50px;
	font-weight: 500;
	text-transform: uppercase;
	height: auto;
	background-color: #a749ff;
	color: #fff;
	border: 1px solid #a749ff;
}
.ratting-form-wrapper .ratting-form form .form-submit input:hover {
	background-color: #333;
	border: 1px solid #333;
}

/* product details 2 */
.product-dec-slider-2 {
	display: inline-block;
	float: left;
	padding: 0;
	width: 20%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.product-dec-slider-2 {
		width: 19%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-dec-slider-2 {
		margin-bottom: 40px;
	}
}
@media only screen and (max-width: 767px) {
	.product-dec-slider-2 {
		margin-bottom: 30px;
		width: 30%;
	}
}
.product-dec-slider-2 .product-dec-icon {
	color: #666;
	cursor: pointer;
	display: inline-block;
	font-size: 25px;
	left: 50%;
	line-height: 1;
	padding: 0 10px;
	position: absolute;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 99;
	opacity: 0;
	visibility: hidden;
}
.product-dec-slider-2 .product-dec-icon.product-dec-prev {
	top: -25px;
}
.product-dec-slider-2 .product-dec-icon.product-dec-next {
	bottom: -14px;
}
.product-dec-slider-2.slick-vertical .slick-slide {
	display: block;
	height: auto;
	margin: 2px 0 10px;
	text-align: center;
}
.product-dec-slider-2.slick-vertical .slick-slide img {
	display: inline-block;
	width: 100%;
}
.product-dec-slider-2:hover .product-dec-icon {
	opacity: 1;
	visibility: visible;
}

.zoompro-wrap.zoompro-2 {
	display: inline-block;
	position: relative;
	width: 80%;
	float: left;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.zoompro-wrap.zoompro-2 {
		width: 79%;
	}
}
@media only screen and (max-width: 767px) {
	.zoompro-wrap.zoompro-2 {
		margin-bottom: 30px;
		width: 70%;
	}
}

.zoompro-wrap .zoompro-span {
	position: relative;
}
.zoompro-wrap .zoompro-span span {
	position: absolute;
	top: 30px;
	left: 30px;
	font-size: 13px;
	color: #fff;
	display: inline-block;
	line-height: 1;
	padding: 3px 11px;
	border-radius: 3px;
	font-weight: 500;
	background-color: #fa6bff;
	z-index: 99;
}
.zoompro-wrap .zoompro-span .product-video {
	text-align: center;
	position: absolute;
	bottom: 30px;
	left: 30px;
	z-index: 99;
}
.zoompro-wrap .zoompro-span .product-video a {
	background-color: #fa6bff;
	border-radius: 3px;
	color: #fff;
	display: inline-block;
	padding: 5px 15px 5px;
}
@media only screen and (max-width: 767px) {
	.zoompro-wrap .zoompro-span .product-video a {
		padding: 3px 10px 3px;
	}
}
.zoompro-wrap .zoompro-span img {
	width: 100%;
}

.dec-img-wrap {
	position: relative;
}
.dec-img-wrap img {
	width: 100%;
}
.dec-img-wrap span {
	position: absolute;
	top: 30px;
	left: 30px;
	font-size: 13px;
	color: #fff;
	display: inline-block;
	line-height: 1;
	padding: 3px 11px;
	border-radius: 3px;
	font-weight: 500;
	background-color: #fa6bff;
	z-index: 99;
}

@media only screen and (max-width: 767px) {
	.sidebar-active .product-details-content {
		margin-top: 0;
	}
}

.sidebar-active.col-lg-6 {
	padding-left: 15px;
	padding-right: 15px;
}
.sidebar-active.col-lg-6.is-affixed {
	padding-left: 0px;
	padding-right: 0px;
}
.sidebar-active.col-lg-6.is-affixed .inner-wrapper-sticky {
	padding-left: 15px;
	padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.product-details-gallery.mr-20 {
		margin-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.product-details-gallery.mr-20 {
		margin-right: 0;
	}
}

/* affiliate */
.pro-details-affiliate {
	margin: 30px 0;
}
.pro-details-affiliate a {
	color: #fff;
	display: inline-block;
	font-weight: 500;
	background-color: #000;
	line-height: 1;
	padding: 13px 30px 16px;
	z-index: 9;
	border: 1px solid transparent;
}

/* product-details-slider */
.product-details-slider-active.nav-style-1.owl-carousel .owl-nav div {
	color: #333;
	font-size: 20px;
	left: 20px;
}
.product-details-slider-active.nav-style-1.owl-carousel .owl-nav div:hover {
	color: #a749ff;
}
.product-details-slider-active.nav-style-1.owl-carousel .owl-nav div.owl-next {
	left: auto;
	right: 20px;
}

.product-details-slider-active:hover.nav-style-1.owl-carousel > .owl-nav div {
	opacity: 1;
	visibility: visible;
}

/*----- 20. Cart page  -----*/
@media only screen and (max-width: 767px) {
	.cart-main-area.pb-100 {
		padding-bottom: 60px;
	}
}

h3.cart-page-title {
	font-size: 20px;
	font-weight: 500;
	margin: 0 0 15px;
}

.cart-table-content table {
	border: 1px solid #ebebeb;
}
.cart-table-content table thead > tr {
	background-color: #f9f9f9;
	border: 1px solid #ebebeb;
}
.cart-table-content table thead > tr th {
	border-top: medium none;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	padding: 21px 45px 22px;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
	white-space: nowrap;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.cart-table-content table thead > tr th {
		padding: 21px 35px 22px;
	}
}
@media only screen and (max-width: 767px) {
	.cart-table-content table thead > tr th {
		padding: 21px 20px 22px;
	}
}
.cart-table-content table tbody > tr {
	border-bottom: 1px solid #ebebeb;
}
.cart-table-content table tbody > tr td.product-thumbnail {
	width: 150px;
}
.cart-table-content table tbody > tr td.product-name {
	width: 435px;
}
.cart-table-content table tbody > tr td.product-name a {
	color: #333;
	font-size: 15px;
	font-weight: 500;
}
.cart-table-content table tbody > tr td.product-name a:hover {
	color: #a749ff;
}
.cart-table-content table tbody > tr td.product-price-cart {
	width: 435px;
}
.cart-table-content table tbody > tr td.product-price-cart span {
	font-weight: 500;
	color: #333;
}
.cart-table-content table tbody > tr td.product-subtotal {
	font-weight: 500;
	color: #333;
}
.cart-table-content table tbody > tr td.product-quantity {
	width: 435px;
}
.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus {
	display: inline-block;
	height: 40px;
	padding: 0;
	position: relative;
	width: 110px;
}
.cart-table-content
	table
	tbody
	> tr
	td.product-quantity
	.cart-plus-minus
	.qtybutton {
	color: #333;
	cursor: pointer;
	float: inherit;
	font-size: 16px;
	margin: 0;
	position: absolute;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	width: 20px;
	text-align: center;
}
.cart-table-content
	table
	tbody
	> tr
	td.product-quantity
	.cart-plus-minus
	.dec.qtybutton {
	border-right: 1px solid #e5e5e5;
	height: 40px;
	left: 0;
	padding-top: 8px;
	top: 0;
}
.cart-table-content
	table
	tbody
	> tr
	td.product-quantity
	.cart-plus-minus
	.inc.qtybutton {
	border-left: 1px solid #e5e5e5;
	height: 40px;
	padding-top: 9px;
	right: 0;
	top: 0;
}
.cart-table-content
	table
	tbody
	> tr
	td.product-quantity
	.cart-plus-minus
	input.cart-plus-minus-box {
	color: #333;
	float: left;
	font-size: 14px;
	height: 40px;
	margin: 0;
	width: 110px;
	background: transparent none repeat scroll 0 0;
	border: 1px solid #e1e1e1;
	padding: 0;
	text-align: center;
}
.cart-table-content table tbody > tr td.product-remove {
	width: 100px;
}
.cart-table-content table tbody > tr td.product-remove a {
	color: #666;
	font-size: 17px;
	margin: 0 13px;
}
.cart-table-content table tbody > tr td.product-remove a:hover {
	color: #a749ff;
}
.cart-table-content table tbody > tr td.product-wishlist-cart > a {
	background-color: #a749ff;
	border-radius: 50px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 1;
	padding: 7px 12px;
	text-transform: uppercase;
}
.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
	background-color: #333;
}
.cart-table-content table tbody > tr td {
	color: #333;
	font-size: 15px;
	padding: 30px 0;
	text-align: center;
}

.cart-shiping-update-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 30px 0px 55px;
}
@media only screen and (max-width: 767px) {
	.cart-shiping-update-wrapper {
		display: block;
		padding: 30px 0px 15px;
	}
}
.cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
	background-color: #f2f2f2;
	border-radius: 50px;
	color: #363f4d;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	padding: 18px 63px 17px;
	text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.cart-shiping-update-wrapper .cart-shiping-update > a,
	.cart-shiping-update-wrapper .cart-clear > button,
	.cart-shiping-update-wrapper .cart-clear > a {
		padding: 18px 25px 17px;
		font-size: 13px;
	}
}
@media only screen and (max-width: 767px) {
	.cart-shiping-update-wrapper .cart-shiping-update > a,
	.cart-shiping-update-wrapper .cart-clear > button,
	.cart-shiping-update-wrapper .cart-clear > a {
		padding: 18px 40px 17px;
		margin: 0 0 15px;
	}
}
.cart-shiping-update-wrapper .cart-shiping-update > a:hover,
.cart-shiping-update-wrapper .cart-clear > button:hover,
.cart-shiping-update-wrapper .cart-clear > a:hover {
	background-color: #a749ff;
	color: #fff;
}
.cart-shiping-update-wrapper .cart-clear > button {
	border: medium none;
	cursor: pointer;
	margin-right: 27px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.cart-shiping-update-wrapper .cart-clear > button {
		margin-right: 15px;
	}
}

.cart-tax,
.discount-code-wrapper {
	background-color: #f9f9f9;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	padding: 45px 30px 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.cart-tax,
	.discount-code-wrapper {
		padding: 45px 18px 50px;
	}
}
@media only screen and (max-width: 767px) {
	.cart-tax,
	.discount-code-wrapper {
		padding: 45px 18px 50px;
	}
}
.cart-tax .title-wrap,
.discount-code-wrapper .title-wrap {
	position: relative;
}
.cart-tax .title-wrap::before,
.discount-code-wrapper .title-wrap::before {
	background-color: #e3e1e1;
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	top: 10px;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 100%;
	z-index: 1;
}
.cart-tax .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap h4.cart-bottom-title {
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	margin: 0;
	padding-right: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.cart-tax .title-wrap h4.cart-bottom-title,
	.discount-code-wrapper .title-wrap h4.cart-bottom-title {
		font-size: 16px;
	}
}
.cart-tax .title-wrap .section-bg-gray,
.discount-code-wrapper .title-wrap .section-bg-gray {
	background-color: #f8f9f9;
	position: relative;
	z-index: 99;
}
.cart-tax .tax-wrapper,
.discount-code-wrapper .tax-wrapper {
	margin-top: 22px;
}
.cart-tax .tax-wrapper p,
.discount-code-wrapper .tax-wrapper p {
	margin: 0;
}
.cart-tax .tax-wrapper .tax-select-wrapper,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper {
	margin: 5px 0 0;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select {
	margin: 0 0 26px;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select label,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select label {
	color: #242424;
	font-size: 14px;
	margin: 0 0 5px;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select select {
	-moz-appearance: none;
	-webkit-appearance: none;
	background: #fff url("../../assets/img/icon-img/cart.png") no-repeat scroll
		right 18px center;
	border: 1px solid #ebebeb;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #242424;
	font-size: 12px;
	height: 40px;
	padding: 0 50px 0 15px;
	width: 100%;
	cursor: pointer;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select input,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select input {
	background: #fff none repeat scroll 0 0;
	border: 1px solid #ebebeb;
	height: 40px;
}
.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2 {
	background-color: #a749ff;
	border: medium none;
	border-radius: 50px;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	padding: 13px 42px 12px;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover,
.discount-code-wrapper
	.tax-wrapper
	.tax-select-wrapper
	button.cart-btn-2:hover {
	background-color: #333;
}
.cart-tax .discount-code,
.discount-code-wrapper .discount-code {
	margin: 21px 0 0;
}
.cart-tax .discount-code p,
.discount-code-wrapper .discount-code p {
	margin: 0 0 15px;
}
.cart-tax .discount-code form input,
.discount-code-wrapper .discount-code form input {
	background: #fff;
	border: 1px solid #ebebeb;
	height: 40px;
	margin-bottom: 30px;
	padding-left: 10px;
}
.cart-tax .discount-code form button.cart-btn-2,
.discount-code-wrapper .discount-code form button.cart-btn-2 {
	background-color: #a749ff;
	border: medium none;
	border-radius: 50px;
	color: #fff;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	padding: 13px 42px 12px;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.cart-tax .discount-code form button.cart-btn-2:hover,
.discount-code-wrapper .discount-code form button.cart-btn-2:hover {
	background-color: #333;
}

.grand-totall {
	background-color: #f9f9f9;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	padding: 45px 30px 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.grand-totall {
		padding: 45px 18px 50px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.grand-totall {
		margin-top: 30px;
	}
}
.grand-totall .title-wrap {
	position: relative;
}
.grand-totall .title-wrap::before {
	background-color: #e3e1e1;
	content: "";
	height: 1px;
	left: 0;
	position: absolute;
	top: 10px;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 100%;
	z-index: 1;
}
.grand-totall .title-wrap h4.cart-bottom-title {
	display: inline-block;
	font-size: 18px;
	font-weight: 500;
	margin: 0;
	padding-right: 18px;
}
.grand-totall .title-wrap .section-bg-gary-cart {
	background-color: #f9f9f9;
	position: relative;
	z-index: 9;
}
.grand-totall h5 {
	font-size: 14px;
	margin: 36px 0 27px;
}
.grand-totall h5 span {
	float: right;
	font-size: 18px;
	font-weight: 500;
}
.grand-totall .total-shipping {
	border-bottom: 1px solid #ebebeb;
	border-top: 1px solid #ebebeb;
	margin: 0 0 27px;
	padding: 28px 0;
}
.grand-totall .total-shipping h5 {
	font-size: 14px;
	margin: 0;
}
.grand-totall .total-shipping ul {
	padding: 19px 0 0 0px;
}
.grand-totall .total-shipping ul li {
	color: #242424;
	list-style: outside none none;
	margin: 0 0 6px;
}
.grand-totall .total-shipping ul li:last-child {
	margin: 0 0 0px;
}
.grand-totall .total-shipping ul li input {
	background: #e9e9e9 none repeat scroll 0 0;
	border: 1px solid #d7d7d7;
	border-radius: 5px !important;
	color: #626262;
	cursor: pointer;
	height: 13px;
	margin-right: 10px;
	padding: 0;
	position: relative;
	top: 2px;
	width: 13px;
}
.grand-totall .total-shipping ul li span {
	float: right;
}
.grand-totall h4.grand-totall-title {
	color: #a749ff;
	font-size: 20px;
	font-weight: 500;
	margin: 0 0 25px;
}
.grand-totall h4.grand-totall-title span {
	float: right;
}
.grand-totall a {
	background-color: #a749ff;
	border-radius: 50px;
	color: #fff;
	display: block;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	padding: 18px 10px 17px;
	text-align: center;
	text-transform: uppercase;
}
.grand-totall a:hover {
	background-color: #333;
}

@media only screen and (max-width: 767px) {
	.discount-code-wrapper {
		margin: 30px 0;
	}
}

/*------- 21. Checkout page  -----------*/
.billing-info-wrap h3 {
	font-size: 20px;
	color: #000;
	margin: 0 0 20px;
	font-weight: 500;
}

.billing-info-wrap .billing-info label,
.billing-info-wrap .billing-select label {
	color: #000;
	margin: 0 0 7px;
}

.billing-info-wrap .billing-info input,
.billing-info-wrap .billing-select input {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #e6e6e6;
	color: #333;
	font-size: 14px;
	padding-left: 20px;
	padding-right: 10px;
}

.billing-info-wrap .billing-info input.billing-address,
.billing-info-wrap .billing-select input.billing-address {
	margin-bottom: 10px;
}

.billing-info-wrap .billing-select select {
	-moz-appearance: none;
	-webkit-appearance: none;
	border: 1px solid #e6e6e6;
	color: #333;
	font-size: 14px;
	height: 45px;
	padding: 2px 20px;
	background: rgba(0, 0, 0, 0) url("../../assets/img/icon-img/select.png")
		no-repeat scroll right 18px center;
	cursor: pointer;
}

.billing-info-wrap .checkout-account {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.billing-info-wrap .checkout-account input {
	border: 1px solid #9fa0a2;
	display: inline-block;
	float: left;
	height: 10px;
	width: 10px;
}
.billing-info-wrap .checkout-account span {
	color: #333;
	font-weight: 400;
	margin: 0 0 0 12px;
}

.billing-info-wrap .checkout-account-toggle input {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #e6e6e6;
	color: #333;
	font-size: 14px;
	padding-left: 20px;
	padding-right: 10px;
	margin: 0 0 20px;
}

.billing-info-wrap .checkout-account-toggle button.checkout-btn {
	background-color: #a749ff;
	border: medium none;
	color: #fff;
	cursor: pointer;
	font-weight: 500;
	padding: 10px 30px;
	text-transform: uppercase;
	border-radius: 50px;
	z-index: 9;
}
.billing-info-wrap .checkout-account-toggle button.checkout-btn:hover {
	background-color: #333;
}

.billing-info-wrap .additional-info-wrap h4 {
	font-size: 16px;
	font-weight: 500;
}

.billing-info-wrap .additional-info-wrap .additional-info label {
	color: #333;
	font-size: 14px;
	margin: 0 0 7px;
}

.billing-info-wrap .additional-info-wrap .additional-info textarea {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #e6e6e6;
	color: #333;
	font-size: 14px;
	height: 138px;
	padding: 17px 20px;
}

.billing-info-wrap .different-address {
	display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.your-order-area {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.your-order-area {
		margin-top: 30px;
	}
}

.your-order-area h3 {
	font-size: 20px;
	font-weight: 500;
	margin: 0 0 20px;
	color: #000;
}

.your-order-area .your-order-wrap {
	padding: 38px 45px 44px;
	background: #f6f6f6;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.your-order-area .your-order-wrap {
		padding: 30px 20px 36px;
	}
}
@media only screen and (max-width: 767px) {
	.your-order-area .your-order-wrap {
		padding: 30px 20px 36px;
	}
}
.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-top
	ul
	li {
	font-size: 16px;
	font-weight: 500;
	list-style: outside none none;
}
.your-order-area .your-order-wrap .your-order-product-info .your-order-middle {
	border-bottom: 1px solid #dee0e4;
	border-top: 1px solid #dee0e4;
	margin: 29px 0;
	padding: 19px 0 18px;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-middle
	ul
	li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 0 10px;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-bottom
	ul {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-bottom
	ul
	li {
	font-size: 14px;
	font-weight: 400;
	list-style: none;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-bottom
	ul
	li.your-order-shipping {
	font-size: 16px;
	color: #212121;
	font-weight: 400;
}
.your-order-area .your-order-wrap .your-order-product-info .your-order-total {
	border-bottom: 1px solid #dee0e4;
	border-top: 1px solid #dee0e4;
	margin: 18px 0 33px;
	padding: 17px 0 19px;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-total
	ul {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-total
	ul
	li.order-total {
	font-weight: 500;
	color: #212121;
	font-size: 18px;
}
.your-order-area
	.your-order-wrap
	.your-order-product-info
	.your-order-total
	ul
	li {
	font-weight: 500;
	color: #a749ff;
	font-size: 16px;
	list-style: outside none none;
}

.your-order-area .payment-accordion {
	margin: 0 0 16px;
}
.your-order-area .payment-accordion:last-child {
	margin: 0 0 0px;
}
.your-order-area .payment-accordion h4 {
	color: #212121;
	font-size: 16px;
	margin: 0;
}
.your-order-area .payment-accordion h4 a {
	color: #212121;
	position: relative;
	display: block;
}
.your-order-area .payment-accordion h4 a:hover {
	color: #a749ff;
}
.your-order-area .payment-accordion .panel-body {
	padding: 5px 0 0 0px;
}
.your-order-area .payment-accordion .panel-body p {
	padding: 0 0 0 10px;
	font-size: 14px;
	color: #333;
}

.your-order-area .Place-order > a {
	background-color: #a749ff;
	color: #fff;
	display: block;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 1;
	padding: 18px 20px;
	text-align: center;
	text-transform: uppercase;
	border-radius: 50px;
	z-index: 9;
}
.your-order-area .Place-order > a:hover {
	background-color: #333;
}

@media only screen and (max-width: 767px) {
	.checkout-area.pb-100 {
		padding-bottom: 60px;
	}
}

/*------ 22. contact Page  ------*/
.contact-map #map {
	height: 560px;
}
@media only screen and (max-width: 767px) {
	.contact-map #map {
		height: 400px;
	}
}

.contact-info-wrap {
	background-color: #f3f3f3;
	padding: 120px 70px 116px 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.contact-info-wrap {
		padding: 120px 20px 116px 40px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.contact-info-wrap {
		padding: 120px 20px 116px 30px;
	}
}
@media only screen and (max-width: 767px) {
	.contact-info-wrap {
		padding: 50px 20px 45px 30px;
		margin-bottom: 30px;
		margin-top: 20px;
	}
}
.contact-info-wrap .single-contact-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 40px;
}
.contact-info-wrap .single-contact-info .contact-icon {
	margin-right: 20px;
}
.contact-info-wrap .single-contact-info .contact-icon i {
	font-size: 20px;
	color: #252525;
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border: 1px solid #252525;
	text-align: center;
	border-radius: 100%;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.contact-info-wrap .single-contact-info .contact-info-dec p {
	line-height: 1;
	color: #404040;
	margin: 0 0 9px;
}
.contact-info-wrap .single-contact-info .contact-info-dec p a {
	color: #404040;
}
.contact-info-wrap .single-contact-info .contact-info-dec p a:hover {
	color: #a749ff;
}
.contact-info-wrap .single-contact-info .contact-info-dec p:last-child {
	margin: 0;
}
.contact-info-wrap .single-contact-info:hover .contact-icon i {
	background-color: #252525;
	color: #fff;
}

.contact-social {
	margin-top: 58px;
}
.contact-social h3 {
	font-weight: 500;
	color: #4d4d4d;
	font-size: 24px;
	margin: 0 0 17px;
	line-height: 1;
}
.contact-social ul li {
	margin: 0 10px;
	display: inline-block;
}
.contact-social ul li a {
	font-size: 16px;
	color: #4d4d4d;
}
.contact-social ul li a:hover {
	color: #a749ff;
}

.contact-form {
	background-color: #f3f3f3;
	padding: 50px 110px 50px 110px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.contact-form {
		padding: 50px 50px 50px 50px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.contact-form {
		padding: 50px 30px 50px 30px;
	}
}
@media only screen and (max-width: 767px) {
	.contact-form {
		padding: 50px 30px 50px 30px;
	}
}
.contact-form .contact-title h2 {
	font-size: 24px;
	font-weight: 500;
	color: #464646;
	line-height: 1;
	margin-bottom: 36px;
}
.contact-form .contact-form-style input,
.contact-form .contact-form-style textarea {
	background: transparent;
	border: 1px solid #c1c1c1;
	height: 40px;
	padding: 2px 14px;
	margin-bottom: 30px;
	color: #000;
}
.contact-form .contact-form-style textarea {
	padding: 20px 14px;
	margin-bottom: 0px;
	height: 175px;
}
.contact-form .contact-form-style button {
	border: none;
	background-color: #404040;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 1;
	padding: 15px 52px;
	margin-top: 38px;
}
.contact-form .contact-form-style button:hover {
	background-color: #a749ff;
}
.contact-form p {
	color: #333;
}
.contact-form p.success {
	margin-top: 10px;
}

.contact-form-style .row div[class^="col-"] {
	padding-left: 15px;
	padding-right: 15px;
}

@media only screen and (max-width: 767px) {
	.contact-area.pb-100 {
		padding-bottom: 60px;
	}
}

/*------ 23. Blog Details Page  -------*/
.blog-details-top .blog-details-img {
	position: relative;
}
.blog-details-top .blog-details-img img {
	width: 100%;
}
.blog-details-top .blog-details-img .video-icon {
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.blog-details-top .blog-details-img .video-icon a {
	background-color: #ff0000;
	border-radius: 50px;
	color: #fff;
	display: inline-block;
	font-size: 25px;
	height: 60px;
	line-height: 61px;
	width: 60px;
}
.blog-details-top .blog-details-img .video-icon a:hover {
	background-color: #a749ff;
}

.blog-details-top .blog-details-content {
	margin-top: 30px;
}
.blog-details-top .blog-details-content .blog-meta-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.blog-details-top .blog-details-content .blog-meta-2 ul li {
	color: #333;
	display: inline-block;
	font-size: 14px;
	letter-spacing: 1px;
	list-style: outside none none;
	margin: 0 17px 0 0;
	position: relative;
	text-transform: uppercase;
	display: inline-block;
	line-height: 1;
}
.blog-details-top .blog-details-content .blog-meta-2 ul li::before {
	background-color: #555;
	content: "";
	height: 2px;
	position: absolute;
	right: -13px;
	top: 6px;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 5px;
}
.blog-details-top .blog-details-content .blog-meta-2 ul li:last-child::before {
	display: none;
}
.blog-details-top .blog-details-content .blog-meta-2 ul li a {
	color: #333;
}
.blog-details-top .blog-details-content .blog-meta-2 ul li a:hover {
	color: #a749ff;
}
.blog-details-top .blog-details-content h3 {
	font-size: 24px;
	font-weight: 500;
	margin: 8px 0 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.blog-details-top .blog-details-content h3 {
		font-size: 22px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.blog-details-top .blog-details-content h3 {
		font-size: 22px;
	}
}
@media only screen and (max-width: 767px) {
	.blog-details-top .blog-details-content h3 {
		font-size: 18px;
	}
}
.blog-details-top .blog-details-content p {
	margin: 0;
	line-height: 26px;
}
.blog-details-top .blog-details-content blockquote {
	color: #333;
	font-size: 18px;
	margin: 25px 0 26px;
	line-height: 34px;
	font-weight: 500;
	font-style: italic;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.blog-details-top .blog-details-content blockquote {
		font-size: 17px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.blog-details-top .blog-details-content blockquote {
		font-size: 17px;
	}
}
@media only screen and (max-width: 767px) {
	.blog-details-top .blog-details-content blockquote {
		font-size: 16px;
		line-height: 30px;
	}
}

.dec-img-wrapper {
	margin-top: 50px;
}
@media only screen and (max-width: 767px) {
	.dec-img-wrapper {
		margin-top: 30px;
	}
}
.dec-img-wrapper .dec-img img {
	width: 100%;
}
@media only screen and (max-width: 767px) {
	.dec-img-wrapper .dec-img.mb-50 {
		margin-bottom: 30px;
	}
}

.tag-share {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 30px 0 30px;
}
@media only screen and (max-width: 767px) {
	.tag-share {
		display: block;
		margin: 20px 0 25px;
	}
}
@media only screen and (max-width: 767px) {
	.tag-share .dec-tag {
		margin-bottom: 10px;
	}
}
.tag-share .dec-tag ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.tag-share .dec-tag ul li {
	margin-right: 10px;
	position: relative;
}
.tag-share .dec-tag ul li a {
	text-transform: capitalize;
	font-size: 15px;
}
.tag-share .blog-share {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.tag-share .blog-share span {
	font-size: 15px;
	text-transform: capitalize;
}
.tag-share .blog-share ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.tag-share .blog-share ul li {
	margin-left: 10px;
}
.tag-share .blog-share ul li a {
	font-size: 16px;
}

.next-previous-post {
	border-bottom: 1px solid #f1f1f1;
	border-top: 1px solid #f1f1f1;
	padding: 18px 0 17px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.next-previous-post a {
	font-size: 15px;
	text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
	.blog-comment-wrapper.mt-55 {
		margin-top: 25px;
	}
}

.blog-comment-wrapper .blog-dec-title {
	font-size: 20px;
	font-weight: 600;
	margin: 0;
	text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
	.blog-comment-wrapper .blog-dec-title {
		font-size: 18px;
	}
}

.blog-comment-wrapper .single-comment-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media only screen and (max-width: 767px) {
	.blog-comment-wrapper .single-comment-wrapper {
		display: block;
	}
	.blog-comment-wrapper .single-comment-wrapper.mt-35 {
		margin-top: 20px;
	}
	.blog-comment-wrapper .single-comment-wrapper.mt-50 {
		margin-top: 30px;
	}
}
.blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 120px;
	flex: 0 0 120px;
	margin-right: 28px;
}
@media only screen and (max-width: 767px) {
	.blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
		margin-right: 28px;
		margin-bottom: 20px;
	}
}
.blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
	width: 100%;
}
@media only screen and (max-width: 767px) {
	.blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
		width: auto;
	}
}
.blog-comment-wrapper .single-comment-wrapper .blog-comment-content h4 {
	font-size: 16px;
	font-weight: 500;
	margin: 0;
	line-height: 1;
}
.blog-comment-wrapper .single-comment-wrapper .blog-comment-content span {
	display: block;
	font-size: 15px;
	margin: 6px 0 8px;
}
.blog-comment-wrapper .single-comment-wrapper .blog-comment-content p {
	font-size: 14px;
	margin-bottom: 10px;
}
.blog-comment-wrapper
	.single-comment-wrapper
	.blog-comment-content
	.blog-details-btn
	a {
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
}
@media only screen and (max-width: 767px) {
	.blog-comment-wrapper .single-comment-wrapper.ml-120 {
		margin-left: 0;
	}
}

@media only screen and (max-width: 767px) {
	.blog-reply-wrapper.mt-50 {
		margin-top: 30px;
	}
}

.blog-reply-wrapper .blog-dec-title {
	font-size: 20px;
	font-weight: 600;
	margin: 0;
	text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
	.blog-reply-wrapper .blog-dec-title {
		font-size: 18px;
	}
}

.blog-reply-wrapper .blog-form {
	margin-top: 35px;
}
@media only screen and (max-width: 767px) {
	.blog-reply-wrapper .blog-form {
		margin-top: 20px;
	}
}
.blog-reply-wrapper .blog-form .leave-form input {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #eaeaea;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #333;
	height: 45px;
	margin-bottom: 30px;
	padding-left: 20px;
}
.blog-reply-wrapper .blog-form .text-leave textarea {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #eaeaea;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #333;
	height: 250px;
	padding-top: 20px;
	margin-bottom: 20px;
	padding-left: 20px;
}
.blog-reply-wrapper .blog-form .text-leave input {
	background-color: #a749ff;
	border: medium none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-weight: 500;
	height: inherit;
	letter-spacing: 1px;
	margin-top: 10px;
	padding: 14px 30px 13px;
	width: inherit;
}
.blog-reply-wrapper .blog-form .text-leave input:hover {
	background-color: #333;
}

.nav-style-4.owl-carousel:hover .owl-nav div {
	opacity: 1;
	visibility: visible;
}

/*------ 24. login register Page  --------*/
.login-register-wrapper .login-register-tab-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 40px;
}
.login-register-wrapper .login-register-tab-list a {
	position: relative;
}
.login-register-wrapper .login-register-tab-list a::before {
	background-color: #454545;
	bottom: 5px;
	content: "";
	height: 18px;
	margin: 0 auto;
	position: absolute;
	right: -2px;
	-webkit-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	width: 1px;
}
.login-register-wrapper .login-register-tab-list a h4 {
	font-size: 25px;
	font-weight: 700;
	margin: 0 20px;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.login-register-wrapper .login-register-tab-list a:last-child::before {
	display: none;
}
.login-register-wrapper .login-register-tab-list a.active h4 {
	color: #a749ff;
}

.login-register-wrapper .login-form-container {
	background: transparent none repeat scroll 0 0;
	-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	padding: 80px;
	text-align: left;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.login-register-wrapper .login-form-container {
		padding: 80px 50px;
	}
}
@media only screen and (max-width: 767px) {
	.login-register-wrapper .login-form-container {
		padding: 40px 15px;
	}
}
.login-register-wrapper .login-form-container .login-register-form form input {
	background-color: transparent;
	border: 1px solid #ebebeb;
	color: #333;
	font-size: 14px;
	height: 45px;
	margin-bottom: 30px;
	padding: 0 15px;
}
.login-register-wrapper
	.login-form-container
	.login-register-form
	form
	input[type="checkbox"] {
	height: 15px;
	margin: 0;
	position: relative;
	top: 1px;
	width: 17px;
}
.login-register-wrapper
	.login-form-container
	.login-register-form
	form
	.button-box
	.login-toggle-btn {
	padding: 10px 0 19px;
}
.login-register-wrapper
	.login-form-container
	.login-register-form
	form
	.button-box
	.login-toggle-btn
	label {
	color: #242424;
	font-size: 15px;
	font-weight: 400;
}
@media only screen and (max-width: 767px) {
	.login-register-wrapper
		.login-form-container
		.login-register-form
		form
		.button-box
		.login-toggle-btn
		label {
		font-size: 14px;
	}
}
.login-register-wrapper
	.login-form-container
	.login-register-form
	form
	.button-box
	.login-toggle-btn
	a {
	color: #242424;
	float: right;
	font-size: 15px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 767px) {
	.login-register-wrapper
		.login-form-container
		.login-register-form
		form
		.button-box
		.login-toggle-btn
		a {
		font-size: 14px;
	}
}
.login-register-wrapper
	.login-form-container
	.login-register-form
	form
	.button-box
	.login-toggle-btn
	a:hover {
	color: #a749ff;
}
.login-register-wrapper
	.login-form-container
	.login-register-form
	form
	.button-box
	button {
	background-color: #f2f2f2;
	border: medium none;
	color: #333;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	padding: 11px 30px;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.login-register-wrapper
	.login-form-container
	.login-register-form
	form
	.button-box
	button:hover {
	background-color: #a749ff;
	color: #fff;
}

@media only screen and (max-width: 767px) {
	.login-register-area.pb-100 {
		padding-bottom: 60px;
	}
}

/*------ 25. My account Page  ------*/
.single-my-account {
	margin-bottom: 20px;
	border: 1px solid #ebebeb;
}
.single-my-account h3.panel-title {
	background-color: #f9f9f9;
	border-bottom: 1px solid #ebebeb;
	color: #000;
	font-size: 15px;
	font-weight: 500;
	margin: 0;
	position: relative;
	text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
	.single-my-account h3.panel-title {
		line-height: 22px;
		font-size: 14px;
	}
}
.single-my-account h3.panel-title span {
	color: #242424;
	font-size: 15px;
	left: 20px;
	position: absolute;
	top: 16px;
}
.single-my-account h3.panel-title a {
	color: #242424;
	display: block;
	padding: 16px 55px;
	position: relative;
}
@media only screen and (max-width: 767px) {
	.single-my-account h3.panel-title a {
		padding: 16px 30px 16px 40px;
	}
}
.single-my-account h3.panel-title a:hover {
	color: #a749ff;
}
.single-my-account h3.panel-title a::before {
	color: #000;
	content: "\f107";
	display: inline-block;
	font-family: "FontAwesome";
	font-size: 16px;
	position: absolute;
	right: 10px;
	top: 19px;
}
.single-my-account h3.panel-title a:hover::before {
	color: #a749ff;
}
.single-my-account .myaccount-info-wrapper {
	padding: 30px 20px;
	background-color: #fff;
}
.single-my-account .myaccount-info-wrapper .account-info-wrapper {
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 28px;
	padding-bottom: 30px;
}
.single-my-account .myaccount-info-wrapper .account-info-wrapper h4 {
	font-size: 15px;
	margin: 0;
	text-transform: uppercase;
}
.single-my-account .myaccount-info-wrapper .account-info-wrapper h5 {
	font-size: 16px;
	letter-spacing: 0.2px;
	margin-top: 7px;
}
.single-my-account .myaccount-info-wrapper .billing-info {
	margin-bottom: 20px;
}
.single-my-account .myaccount-info-wrapper .billing-info label {
	color: #000;
	font-size: 14px;
	text-transform: capitalize;
}
.single-my-account .myaccount-info-wrapper .billing-info input {
	background: transparent none repeat scroll 0 0;
	border: 1px solid #ebebeb;
	color: #000;
	height: 40px;
	padding: 0 15px;
}
.single-my-account .myaccount-info-wrapper .billing-back-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 26px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a {
	color: #000;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
}
.single-my-account
	.myaccount-info-wrapper
	.billing-back-btn
	.billing-back
	a:hover {
	color: #a749ff;
}
.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a i {
	font-size: 16px;
	color: #a749ff;
}
.single-my-account
	.myaccount-info-wrapper
	.billing-back-btn
	.billing-btn
	button {
	background-color: #f2f2f2;
	border: medium none;
	color: #000;
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	padding: 16px 35px 17px;
	text-transform: uppercase;
	cursor: pointer;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.single-my-account
	.myaccount-info-wrapper
	.billing-back-btn
	.billing-btn
	button:hover {
	background: #a749ff;
	color: #fff;
}
.single-my-account .myaccount-info-wrapper .entries-wrapper {
	border: 1px solid #eaeaea;
	position: relative;
}
@media only screen and (max-width: 767px) {
	.single-my-account .myaccount-info-wrapper .entries-wrapper {
		padding: 30px 10px;
	}
	.single-my-account .myaccount-info-wrapper .entries-wrapper::before {
		display: none;
	}
}
.single-my-account .myaccount-info-wrapper .entries-wrapper::before {
	position: absolute;
	content: "";
	height: 100%;
	width: 1px;
	left: 50%;
	top: 0;
	background-color: #eaeaea;
}
.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info {
	padding: 30px 20px;
}
@media only screen and (max-width: 767px) {
	.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info {
		padding: 0 10px 30px;
	}
}
.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info p {
	color: #000;
	font-size: 15px;
	margin: 0;
	text-transform: capitalize;
}
.single-my-account
	.myaccount-info-wrapper
	.entries-wrapper
	.entries-edit-delete
	a {
	background-color: #000;
	color: #fff;
	display: inline-block;
	line-height: 1;
	margin: 0 2px;
	padding: 12px 15px;
	text-transform: uppercase;
	font-weight: 500;
}
.single-my-account
	.myaccount-info-wrapper
	.entries-wrapper
	.entries-edit-delete
	a:hover {
	background-color: #a749ff;
}
.single-my-account
	.myaccount-info-wrapper
	.entries-wrapper
	.entries-edit-delete
	a.edit {
	background-color: #df5c39;
}
.single-my-account
	.myaccount-info-wrapper
	.entries-wrapper
	.entries-edit-delete
	a.edit:hover {
	background-color: #a749ff;
}

.error h1 {
	font-size: 17rem;
	font-weight: bold;
	letter-spacing: 1rem;
	line-height: 1.1;
	margin-bottom: 30px;
	color: #a749ff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.error h1 {
		font-size: 15rem;
		margin-bottom: 4px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.error h1 {
		font-size: 12rem;
	}
}
@media only screen and (max-width: 767px) {
	.error h1 {
		font-size: 7rem;
		line-height: 88px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.error h1 {
		font-size: 9rem;
		line-height: 100px;
	}
}

.error h2 {
	font-size: 25px;
	font-weight: 600;
}

.error-btn {
	position: relative;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	color: #fff;
	font-size: 16px;
	min-width: 184px;
	min-height: 55px;
	line-height: 55px;
	margin-bottom: 6px;
	padding: 0 10px;
	border: none;
	border-radius: 0;
	background-color: #a749ff;
	display: inline-block;
}
.error-btn:after {
	position: absolute;
	content: "";
	left: 0;
	bottom: -6px;
	height: 3px;
	width: 100%;
	background-color: #a749ff;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.error-btn:hover {
	background-color: #111111;
	color: #fff;
}
.error-btn:hover:after {
	background-color: #111111;
}

.searchform {
	position: relative;
}
.searchform input {
	border: 1px solid #eeeeee;
	height: 45px;
	width: 100%;
	padding-left: 15px;
	padding-right: 80px;
	background-color: transparent;
}
.searchform button {
	border: none;
	background-color: #a749ff;
	width: 70px;
	color: #fff;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
}
.searchform button:hover {
	background-color: #000;
}
@media only screen and (max-width: 767px) {
	.searchform.mb-50 {
		margin-bottom: 20px;
	}
}
